import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Completed from './parts/Completed/Completed';
import Cosigner from './parts/Cosigner/Cosigner';
import Payment from './parts/Payment/Payment';
import Sign from './parts/Sign/Sign';
import StudentFile from './parts/StudentFile/StudentFile';
import Sustainer from './parts/Sustainer/Sustainer';
import TermsAndConditions from './parts/TermsAndConditions/TermsAndConditions';
import { useStepState } from '../../components/step/useStepState';
import { useTuitionProcess } from './TuitionProcessContext';

export const useTuitionProcessSteps = (
  hasGratuity: boolean | null,
  isOnlineTuition: boolean | null,
) => {
  const { t } = useTranslation();
  const { prefix } = useTuitionProcess();
  const { currentStep } = useStepState();

  /**
   * Contiene los pasos del proceso de matrícula ,
   * filtrados por el estado de gratuidad del alumno.
   */
  const stepsTuitionProcess = useMemo(() => {
    if (hasGratuity === null) {
      return [];
    }
    const availableSteps = [
      {
        id: 'student-file',
        name: t(`${prefix}.steps.studentFile`),
        component: StudentFile,
      },
      {
        id: 'sustainer',
        name: t(`${prefix}.steps.tuitionPayer`),
        component: Sustainer,
      },
      {
        id: 'cosigner',
        name: t(`${prefix}.steps.coSigner`),
        hideWithGratuity: true,
        component: Cosigner,
      },
      {
        id: 'terms-and-conditions',
        name: t(`${prefix}.steps.termsAndConditions`),
        hideInOnSiteTuition: true,
        component: TermsAndConditions,
      },
      {
        id: 'payment',
        name: t(`${prefix}.steps.payTuition`),
        hideWithGratuity: true,
        component: Payment,
      },
      {
        id: 'sign-documentation',
        name: t(`${prefix}.steps.sign`),
        component: Sign,
      },
      {
        id: 'completed',
        name: t(`${prefix}.steps.completed`),
        component: Completed,
      },
    ];

    return availableSteps.filter(
      (step) =>
        !(hasGratuity && step.hideWithGratuity) &&
        !(!isOnlineTuition && step.hideInOnSiteTuition),
    );
  }, [t, hasGratuity, isOnlineTuition, prefix]);

  /**
   * Contiene dos arrays con las partes de los pasos
   * (Un array con los nombres de los pasos y
   * un array con los componentes asociados a cada paso)
   */
  const availableParts = useMemo(() => {
    const namesSteps: string[] = [];
    const componentsSteps: (React.FunctionComponent | undefined)[] = [];
    stepsTuitionProcess.forEach((step) => {
      namesSteps.push(step.name);
      componentsSteps.push(step.component);
    });
    return { namesSteps: namesSteps.slice(0, -1), componentsSteps };
  }, [stepsTuitionProcess]);

  const getIndexStep = useCallback(
    (id: string) => {
      return stepsTuitionProcess.findIndex((step) => step.id === id);
    },
    [stepsTuitionProcess],
  );

  /**
   * El paso actual en base a la variable currentStep
   */
  const CurrentStepComponent = useMemo(() => {
    return availableParts.componentsSteps[currentStep];
  }, [currentStep, availableParts]);

  return {
    stepsTuitionProcess,
    ...availableParts,
    CurrentStepComponent,
    getIndexStep,
  };
};
