import { MAINTAINERS } from '../endpoints';
import request from '../request';
import { PaginationRequestType } from '../../types/paginationRequestType';
import { ModalityBody, ModalityListItem } from '../../types/ModalityTypes';

export interface ModalityListResponse {
  data: ModalityListItem;
}

/**
 * Obtiene el listado de modalidades
 */
export const getModalitiesRequest = (items: number = 10, page: number = 0) => {
  const url = `${MAINTAINERS.MODALITY}?items_per_page=${items}&page=${page}`;
  return request<PaginationRequestType<ModalityListItem>>(url);
};

/**
 *  Crear o editar una modalidad
 */
export const saveModalityRequest = (data: ModalityBody) => {
  const isEdit = !!data.id;

  const url = `${MAINTAINERS.MODALITY}${isEdit ? `/${data.id}` : ''}`;
  return request<ModalityListResponse>(url, {
    method: isEdit ? 'put' : 'post',
    data,
  });
};
