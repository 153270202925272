import { POSTULANTS } from '../endpoints';
import { requestAuth } from './tuitionProcess';

interface Postulant {
  Postulant_id: number;
  Postulant_rut?: string;
  Postulant_names: string;
  Postulant_paternalLastName: string;
  Postulant_maternalLastName: string;
  Postulant_paternalPreference: boolean;
  Contact_cellPhone: string;
  Contact_email: string;
  Postulant_passportNumber?: string;
  PostulantPassport_number?: string;
  Postulant_passportCountryId?: string;
  PostulantPassport_countryId?: string;
  PostulationDetail_status: string;
  Postulation_admissionTypeId: string;
  Postulant_birthday: string;
  StudyPlanVersion_name?: string;
  Campus_name?: string;
  Schedule_name?: string;
  Details_id: number;
}

interface SearchPostulant {
  Admission: {
    Details_id: number;
    Details_status: string;
    Postulation_admissionTypeId: string;
    StudyPlanVersion_name: string;
    Campus_name: string;
    Schedule_name: string;
  };
  New_admission: {
    Details_id: number;
    Details_status: string;
    Postulation_admissionTypeId: string;
    StudyPlanVersion_name: string;
    Campus_name: string;
    Schedule_name: string;
  };
  Postulant_id: number;
  Postulant_rut?: string;
  Postulant_names: string;
  Postulant_paternalLastName: string;
  Postulant_maternalLastName: string;
  Postulant_paternalPreference: number;
  Postulant_birthday: string;
  Contact_cellPhone: string;
  Contact_email: string;
  Postulant_passportNumber: string | null;
  Postulant_passportCountryId: string | null;
}

export enum DocumentStatus {
  NOT_REVIEW = 'sin_revision',
  WAITING = 'en_espera',
  APPROVED = 'aprobado',
}

export type statusDocument =
  | DocumentStatus.NOT_REVIEW
  | DocumentStatus.WAITING
  | DocumentStatus.APPROVED;

export type sideType = 'back' | 'front';

export interface IdentityCardsOfPostulant {
  id: number;
  side: sideType;
  extension: string;
  postulationDetailId: number;
  status: statusDocument;
  type: string;
  updatedAt: string;
  createdAt: string;
  inCloud: boolean;
  filename: string;
}

export interface DocumentPostulant {
  id: number;
  extension: string;
  postulationDetailId: number;
  status: statusDocument;
  type: string;
  updatedAt: string;
  createdAt: string;
  inCloud: boolean;
  filename: string;
}

export interface PostulantDocumentation {
  postulant: Postulant;
  identityCards: Array<{
    type: string;
    isUploaded: boolean;
    identityCards: IdentityCardsOfPostulant[];
  }>;
  documentation: Array<{
    type: string;
    isSigned: boolean;
    document: DocumentPostulant[];
  }>;
}

export const searchRUTRequest = (rut: string) => {
  let url = `${POSTULANTS.SEARCHRUT}/${rut}`;
  return requestAuth<SearchPostulant>(url);
};

export const searchByPassportNumberAndCountry = (
  country: string,
  passportNumber: string,
) => {
  let url = `${POSTULANTS.SEARCHBYPASSPORT}/${country}/${passportNumber}`;
  return requestAuth<SearchPostulant>(url);
};

export const putPostulants = (
  id: number,
  data: {
    names: string;
    paternalLastName: string;
    maternalLastName: string;
    email: string;
    phone: string;
    birthday: string;
  },
) => {
  let url = `${POSTULANTS.UPDATE}/${id}`;
  return requestAuth<Postulant>(url, {
    method: 'PUT',
    data,
  });
};

export const createPostulant = (data: CreatePostulantBody) => {
  return requestAuth<{ data: { id: number } }>(POSTULANTS.CREATE, {
    method: 'post',
    data,
  });
};

export const getPostulantDocuments = (detailId: number) => {
  let url = POSTULANTS.GETDOCUMENTS(detailId);
  return requestAuth<{ data: PostulantDocumentation }>(url, {
    method: 'GET',
  });
};

export interface CreatePostulantBody {
  type: 'rut' | 'passport';
  names: string;
  rut?: string;
  passportNumber?: string;
  passportCountry?: string;
  birthday: string;
  paternalLastName: string;
  maternalLastName: string;
  paternalPreference: boolean;
  email: string;
  phone: string;
}
