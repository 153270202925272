import { MAINTAINERS } from '../endpoints';
import request, {
  AxiosErrorWithResponse,
  AxiosResult,
  AxiosResultDefaultError,
} from '../request';
import { ScheduleItem } from '../../maintainers/schedule/ScheduleList';
import { ScheduleValues, ScheduleCalendarValues } from '../../maintainers/schedule/ScheduleForm';

interface SchedulesListResponse {
  data: ScheduleItem[];
  total: number;
  total_pages: number;
}

interface ScheduleResponse {
  id: number;
  code: string;
  name: string;
  description?: string;
}

interface ScheduleCalendarResponse {
  id: number;
  scheduleId: string;
  day: string;
  startHour: string;
  endHour: string;
  createdAt: string;
  updatedAt: string;
}

interface ScheduleCalendarDay {
  day: string;
  startHour: string;
  endHour: string;
}

/**
 * Obtiene el listado de jornadas
 */
export const getScheduleList = (items: number = 10, page: number = 0) => {
  const url = `${MAINTAINERS.SCHEDULES}?items_per_page=${items}&page=${page}`;
  return request<SchedulesListResponse>(url);
};

/**
 * Obtiene una jornada
 */
export const getSchedule = (id: number | string) => {
  const url = `${MAINTAINERS.SCHEDULES}/${id}`;
  return request<{ data: ScheduleResponse }>(url);
};

/**
 * Obtiene un calendario por jornada
 */
export const getScheduleCalendar = (code: string) => {
  const url = `${MAINTAINERS.SCHEDULES_CALENDAR}/${code}`;
  return request<{ data: ScheduleCalendarResponse[] }>(url);
};

const hourToSeconds = (hour:string):number => {
  const parts = hour.split(':');
  const seconds = (+parts[0]) * 60 * 60 + (+parts[1]) * 60;
  return seconds;
}

export const getScheduleWithCalendar = async (id: number | string) => {
  try {
    const scheduleResponse = await getSchedule(id);
    if (scheduleResponse.data) {
      let scheduleCalendarData:ScheduleValues = {
        code: scheduleResponse.data.data.code,
        name: scheduleResponse.data.data.name,
        description: scheduleResponse.data.data.description,
        mondayStartTime: "",
        mondayEndTime: "",
        tuesdayStartTime: "",
        tuesdayEndTime: "",
        wednesdayStartTime: "",
        wednesdayEndTime: "",
        thursdayStartTime: "",
        thursdayEndTime: "",
        fridayStartTime: "",
        fridayEndTime: "",
        saturdayStartTime: "",
        saturdayEndTime: "",
        sundayStartTime: "",
        sundayEndTime: "",
      }
      const calendarResponse = await getScheduleCalendar(scheduleResponse.data.data.code);
      if (calendarResponse.data) {
        calendarResponse.data.data.forEach(element => {
          if (element.day === "Lunes") {
            scheduleCalendarData.mondayStartTime = element.startHour;
            scheduleCalendarData.mondayEndTime = element.endHour;
          }
          if (element.day === "Martes") {
            scheduleCalendarData.tuesdayStartTime = element.startHour;
            scheduleCalendarData.tuesdayEndTime = element.endHour;
          }
          if (element.day === "Miercoles") {
            scheduleCalendarData.wednesdayStartTime = element.startHour;
            scheduleCalendarData.wednesdayEndTime = element.endHour;
          }
          if (element.day === "Jueves") {
            scheduleCalendarData.thursdayStartTime = element.startHour;
            scheduleCalendarData.thursdayEndTime = element.endHour;
          }
          if (element.day === "Viernes") {
            scheduleCalendarData.fridayStartTime = element.startHour;
            scheduleCalendarData.fridayEndTime = element.endHour;
          }
          if (element.day === "Sabado") {
            scheduleCalendarData.saturdayStartTime = element.startHour;
            scheduleCalendarData.saturdayEndTime = element.endHour;
          }
          if (element.day === "Domingo") {
            scheduleCalendarData.sundayStartTime = element.startHour;
            scheduleCalendarData.sundayEndTime = element.endHour;
          }
        });
      }
      return scheduleCalendarData;
    } 
    return new Error("No se encontró la jornada solicitada");
  } catch (error) {
    return error
  }
}

export type ScheduleCodeNotUniqueError = {
  code: 'NOT_UNIQUE_CODE';
  error: AxiosErrorWithResponse;
  status: number;
  data?: any;
};

export type ScheduleHoursNotSetError = {
  code: 'NOT_CALENDAR_HOURS';
  error: AxiosErrorWithResponse;
  status: number;
  data?: any;
};

export type ScheduleHoursNotValidError = {
  code: 'NOT_VALID_CALENDAR_HOURS';
  error: AxiosErrorWithResponse;
  status: number;
  data?: any;
};

export type ScheduleRequestErrors =
  | AxiosResultDefaultError
  | ScheduleCodeNotUniqueError
  | ScheduleHoursNotSetError
  | ScheduleHoursNotValidError;

const formatTimeForApi = (time:string) => {
  return "1900-01-01 " + time + ":00"
}

const createCalendarData = (data: ScheduleValues) => {
  // Calendario
  const scheduleCalendars = [];
  if (data.mondayStartTime !== "") 
    scheduleCalendars.push({
      "day": "Lunes",
      "startHour": formatTimeForApi(data.mondayStartTime),
      "endHour": formatTimeForApi(data.mondayEndTime)
    })
  if (data.tuesdayStartTime !== "") 
    scheduleCalendars.push({
      "day": "Martes",
      "startHour": formatTimeForApi(data.tuesdayStartTime),
      "endHour": formatTimeForApi(data.tuesdayEndTime)
    })
  if (data.wednesdayStartTime !== "") 
    scheduleCalendars.push({
      "day": "Miercoles",
      "startHour": formatTimeForApi(data.wednesdayStartTime),
      "endHour": formatTimeForApi(data.wednesdayEndTime)
    })
  if (data.thursdayStartTime !== "") 
    scheduleCalendars.push({
      "day": "Jueves",
      "startHour": formatTimeForApi(data.thursdayStartTime),
      "endHour": formatTimeForApi(data.thursdayEndTime)
    })
  if (data.fridayStartTime !== "") 
    scheduleCalendars.push({
      "day": "Viernes",
      "startHour": formatTimeForApi(data.fridayStartTime),
      "endHour": formatTimeForApi(data.fridayEndTime)
    })
  if (data.saturdayStartTime !== "") 
    scheduleCalendars.push({
      "day": "Sabado",
      "startHour": formatTimeForApi(data.saturdayStartTime),
      "endHour": formatTimeForApi(data.saturdayEndTime)
    })
  if (data.sundayStartTime !== "") 
    scheduleCalendars.push({
      "day": "Domingo",
      "startHour": formatTimeForApi(data.sundayStartTime),
      "endHour": formatTimeForApi(data.sundayEndTime)
    })
  return scheduleCalendars;
}

const isValidCalendar = (days:ScheduleCalendarDay[]):boolean => {
  let isValid = true;
  days.forEach(item => {
    if (hourToSeconds(item.startHour.split(' ')[1]) >= hourToSeconds(item.endHour.split(' ')[1])) {
      isValid = false
    }
  });
  return isValid;
}

/**
 *  Crear una jornada
 */
export const createScheduleRequest = async (
  data: ScheduleValues,
): Promise<AxiosResult<ScheduleResponse, ScheduleRequestErrors>> => {
  const url = MAINTAINERS.SCHEDULES;

  const scheduleCalendars = createCalendarData(data);
  if (scheduleCalendars.length === 0) {
    return {
      error: {
          code: 'NOT_CALENDAR_HOURS',
        } as ScheduleHoursNotSetError,
    };
  }

  if (!isValidCalendar(scheduleCalendars)) {
    return {
      error: {
          code: 'NOT_VALID_CALENDAR_HOURS',
        } as ScheduleHoursNotValidError,
    };
  }

  const response = await request<{ data: ScheduleResponse }>(url, {
    method: 'post',
    data,
  });
  if (response.error) {
    if (response.error.code === 'HTTP_ERROR' && response.error.status === 400) {
      return {
        error: {
          ...response.error,
          code: 'NOT_UNIQUE_CODE',
        } as ScheduleCodeNotUniqueError,
      };
    } else {
      return response;
    }
  }

  const calendarRequestBody = {
    scheduleId: data.code,
    code: data.code,
    scheduleCalendars
  }
  return createScheduleCalendarRequest(calendarRequestBody);
};

/**
 *  Crear calendario semanal por jornada
 */
export const createScheduleCalendarRequest = async (
  data: ScheduleCalendarValues,
): Promise<AxiosResult<ScheduleResponse, ScheduleRequestErrors>> => {
  const url = MAINTAINERS.SCHEDULES_CALENDAR;
  const response = await request<{ data: ScheduleResponse }>(url, {
    method: 'post',
    data,
  });

  if (response.error) {
    if (response.error.code === 'HTTP_ERROR' && response.error.status === 400) {
      return {
        error: {
          ...response.error,
          code: 'NOT_UNIQUE_CODE',
        } as ScheduleCodeNotUniqueError,
      };
    } else {
      return response;
    }
  }
  return {
    data: response.data.data,
  };
};

/**
 *  Actualizar una jornada
 */
export const updateScheduleRequest = async (
  scheduleId: number | string,
  data: ScheduleValues,
): Promise<AxiosResult<ScheduleResponse, ScheduleRequestErrors>> => {
  const calendarData = createCalendarData(data);
  if (calendarData.length === 0) {
    return {
      error: {
          code: 'NOT_CALENDAR_HOURS',
        } as ScheduleHoursNotSetError,
    };
  }

  if (!isValidCalendar(calendarData)) {
    return {
      error: {
          code: 'NOT_VALID_CALENDAR_HOURS',
        } as ScheduleHoursNotValidError,
    };
  }

  let url = `${MAINTAINERS.SCHEDULES}/${scheduleId}`;
  const response = await request<{ data: ScheduleResponse }>(url, {
    method: 'put',
    data,
  });
  if (response.error) {
    if (response.error.code === 'HTTP_ERROR' && response.error.status === 400) {
      return {
        error: {
          ...response.error,
          code: 'NOT_UNIQUE_CODE',
        } as ScheduleCodeNotUniqueError,
      };
    } else {
      return response;
    }
  }

  const calendarResponse = await updateScheduleCalendarRequest(data.code, calendarData)
  if (!calendarResponse.error) {
    return {
      data: response.data.data,
    };
  } else {
    return calendarResponse;
  }
};

/**
 *  Actualizar calendario de una jornada
 */
export const updateScheduleCalendarRequest = async (
  code: number | string,
  days: ScheduleCalendarDay[],
): Promise<AxiosResult<ScheduleCalendarResponse[], ScheduleRequestErrors>> => {
  let url = `${MAINTAINERS.SCHEDULES_CALENDAR}/${code}`;
  const data = {
    code,
    scheduleCalendars: days
  }
  const response = await request(url, {
    method: 'put',
    data,
  });
  if (response.error) {
    if (response.error.code === 'HTTP_ERROR' && response.error.status === 400) {
      return {
        error: {
          ...response.error,
          code: 'NOT_UNIQUE_CODE',
        } as ScheduleCodeNotUniqueError,
      };
    } else {
      return response;
    }
  }
  return {
    data: response.data.data,
  };
};