import {
  DocumentTypesEnum,
  GeneralTypes,
  IdentityTypesEnum,
  isDocumentType,
  isIdentityType,
} from '../../../../types/signDocumentationTypes';

/**
 * Devuelve una matriz de objetos con el nombre del input y la ruta del idioma para el label
 * @param {GeneralTypes} documentName - Todos los tipos de documentos
 * @returns Una matriz de objetos con las propiedades name y labelPath.
 */
export function getInputs(documentName: GeneralTypes): {
  name: string;
  labelPath: string;
  type: string;
}[] {
  if (isDocumentType(documentName)) {
    if (documentName === DocumentTypesEnum['sustainer-income-one']) {
      return [
        {
          name: 'incomeOne',
          labelPath: 'postulationDocs.attachment.file',
          type: 'file',
        },
      ];
    }
    if (documentName === DocumentTypesEnum['sustainer-income-others']) {
      return [
        {
          name: 'doc',
          labelPath: 'postulationDocs.attachment.file',
          type: 'file',
        },
        {
          name: 'description',
          labelPath: '',
          type: 'textArea',
        },
      ];
    }
  }
  if (isIdentityType(documentName)) {
    const ciTypes = [
      IdentityTypesEnum.student,
      IdentityTypesEnum.cosigner,
      IdentityTypesEnum.sustainer,
    ];

    if (ciTypes.includes(documentName)) {
      return [
        {
          name: 'back',
          labelPath: 'postulationDocs.attachment.back',
          type: 'file',
        },
        {
          name: 'front',
          labelPath: 'postulationDocs.attachment.front',
          type: 'file',
        },
      ];
    }
  }
  //otros documentos
  return [
    { name: 'doc', labelPath: 'postulationDocs.attachment.file', type: 'file' },
  ];
}
