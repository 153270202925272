import { addToast, Button, Modal, Icon } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'reactstrap';
import CardTitle from '../../components/text/CardTitle';
import { useLayoutState } from '../../hooks/useLayoutState';
import { changeToInProcess } from '../../api/requests/studyPlansOffer';

interface MoveToInProcessFormProps {
  show: boolean;
  toggleModal: () => void;
  reloadList: () => void;
  id: number;
  name: string;
}

export const MoveToInProcessModal = ({
  id,
  name,
  show,
  toggleModal,
  reloadList,
}: MoveToInProcessFormProps) => {
  const prefix = 'admission';
  const { t } = useTranslation();
  const { showErrorModal } = useLayoutState();

  const toInProcess = async () => {
    const { data, error } = await changeToInProcess(id);

    if (error && error.code === 'CONNECTION') {
      showErrorModal();
      return;
    } else if (error) {
      addToast({
        icon: 'error',
        color: 'danger',
        text: t('common.errors.save'),
      });
      toggleModal();
    }
    if (data) {
      reloadList();
      toggleModal();
      addToast({
        icon: 'success',
        color: 'success',
        text: t(`${prefix}.modalMoveToInProcess.okMessage`),
      });
    }
  };

  return (
    <Modal isOpen={show} toggle={toggleModal} size="md" unmountOnClose>
      <Row>
        <Col>
          <div className="text-center">
            <Icon color="secondary" name="warning" size="70px" />
          </div>
        </Col>
      </Row>
      <Row className="mt-4 justify-content-center">
        <Col md={10}>
          <div className="text-center">
            <CardTitle children={t(`${prefix}.modalMoveToInProcess.title`)} />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <span>{t('tuitionProcess.studentBackground.student')}</span>
          <div className="student-content">
            <ul className="mb-5 mt-2">
              <li className="text-dark">{name}</li>
            </ul>
          </div>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col xs={{ size: 12, order: 2 }} md={{ size: 6, order: 1 }}>
          <Button
            type="button"
            text={t('common.actions.cancel')}
            outlined
            onClick={() => toggleModal()}
            fullwidth
          />
        </Col>
        <Col xs={{ size: 12, order: 1 }} md={{ size: 6, order: 2 }}>
          <Button
            type="button"
            onClick={toInProcess}
            text={t('common.actions.confirm')}
            fullwidth
            className="mb-3 mb-md-0"
          />
        </Col>
      </Row>
    </Modal>
  );
};
