import request from '../request';
import { MAINTAINERS } from '../endpoints';

export type CourseType = {
  id: number;
  name: string;
  code: string;
  shortening?: string;
  credits: number;
};

export const requestAvailableCourses = (type: number) => {
  return request(`${MAINTAINERS.AVAILABLE_COURSES}/${type}`, {
    method: 'get',
  });
};
