import {
  Button,
  OutlinedSelect,
  SearchAccordion,
  SearchBox,
  SelectOptionType,
  useMobile,
} from '@octano/global-ui';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { getStudyPlanVersions } from '../../../api/requests/studyPlans';
import { useValidations } from '../../../hooks/useValidations';

export type Filters = {
  rutOrPassport: string | null;
  searchText: string | null;
  studyPlanId: number | null;
  campusId: number | null;
};

type FormValues = {
  rutOrPassport: string | null;
  searchText: string | null;
  studyPlanId: SelectOptionType | null;
  campusId: SelectOptionType | null;
};

const DEFAULT_VALUES = {
  rutOrPassport: null,
  searchText: null,
  studyPlanId: null,
  campusId: null,
};

interface Props {
  onFiltersChange: (filterValues: Filters) => void;
}

export default function SearchFilters({ onFiltersChange = () => null }: Props) {
  const { t } = useTranslation();
  const prefix = 'courseRegistrationList.search';

  const isMobile = useMobile();
  const [studyPlans, setStudyPlans] = useState<SelectOptionType[]>([]);
  const [campus, setCampus] = useState<SelectOptionType[]>([]);

  const { handleSubmit, reset, watch, setValue, control, formState } =
    useForm<FormValues>({
      defaultValues: DEFAULT_VALUES,
      mode: 'onChange',
    });
  const currentFiltersValues = watch();
  const lastEmittedFilters = useRef<FormValues>();
  const { validateMinLength } = useValidations();

  const cleanFilters = () => {
    // No se disparara si ya los filtros estaban vacios
    const needToClean = Object.values(currentFiltersValues).some(
      (value) => value !== null,
    );

    if (needToClean) {
      reset();
      onFiltersChange(DEFAULT_VALUES);
    }
  };

  const onSubmit = useCallback((newValues: FormValues) => {
    lastEmittedFilters.current = { ...newValues };

    const studyPlanId = newValues?.studyPlanId?.value
      ? Number(newValues?.studyPlanId.value)
      : null;

    const campusId = newValues?.campusId?.value
      ? Number(newValues?.campusId.value)
      : null;

    onFiltersChange({
      ...newValues,
      studyPlanId,
      campusId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasSomeFilterValue = () => {
    return Object.values(currentFiltersValues).some(
      (filterValue) => filterValue !== null,
    );
  };

  // El valor por defecto de los filtros es null, si se vacian se devuelven a null
  useEffect(() => {
    for (const filter in currentFiltersValues) {
      if (currentFiltersValues[filter as keyof Filters] === '') {
        setValue(filter as keyof Filters, null);
      }
    }
  }, [currentFiltersValues, setValue, onSubmit]);

  // Se solicito que los filtros refrescaran la busqueda cuando se vaciaran despues del primer submit
  useEffect(() => {
    let changesToEmit: Partial<FormValues> = {};

    for (const filter in currentFiltersValues) {
      const currFilterValue = currentFiltersValues[filter as keyof Filters];
      const prevFilterValue =
        lastEmittedFilters.current &&
        lastEmittedFilters.current[filter as keyof Filters];

      if (
        formState.isSubmitted &&
        currFilterValue !== prevFilterValue &&
        currFilterValue === null
      ) {
        onSubmit({ ...currentFiltersValues, ...changesToEmit });
      }
    }
  }, [currentFiltersValues, onSubmit, formState.isSubmitted]);

  // El rut y pasaporte no lleva ni guiones ni puntos, se remueven automaticamente
  useEffect(() => {
    const currVal = currentFiltersValues.rutOrPassport;

    if (currVal) {
      const cleaned = currVal.replace('-', '').replace('.', '');
      setValue('rutOrPassport', cleaned);
    }
  }, [currentFiltersValues.rutOrPassport, setValue]);

  // Pidiendo el listado de planes de estudio (versiones)
  useEffect(() => {
    const fetchStudyPlans = async () => {
      const { data } = await getStudyPlanVersions();

      setStudyPlans(
        data?.studyPlanVersions.map((sp: any) => ({
          label: sp.name,
          value: sp.id,
        })) || [],
      );
      setCampus(
        data?.campuses.map((c: any) => ({
          label: c.name,
          value: c.id,
        })) || [],
      );
    };

    fetchStudyPlans();
  }, []);

  return (
    <div
      className={`course-registration-search-filters ${isMobile ? 'mb-4' : ''}`}
    >
      <SearchAccordion btnText={t(`${prefix}.title`)}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{ gap: isMobile ? 0 : 12 }}
        >
          <Row>
            <Col md={4}>
              <SearchBox
                name="rutOrPassport"
                label={t(`${prefix}.code`)}
                placeholder={t(`${prefix}.codePlaceholder`)}
                control={control}
                rules={{
                  validate: {
                    minLength: validateMinLength(3),
                  },
                }}
              />
            </Col>
            <Col md={4}>
              <SearchBox
                name="searchText"
                label={t(`${prefix}.name`)}
                placeholder={t(`${prefix}.namePlaceholder`)}
                control={control}
                rules={{
                  validate: {
                    minLength: validateMinLength(3),
                  },
                }}
              />
            </Col>
            <Col md={4}>
              <OutlinedSelect
                name="studyPlanId"
                options={studyPlans}
                label={t(`${prefix}.studyPlan`)}
                control={control}
              />
            </Col>
            <Col md={4}>
              <OutlinedSelect
                name="campusId"
                options={campus}
                label={t(`${prefix}.campus`)}
                control={control}
              />
            </Col>
            <Col>
              <div className="float-right">
                <Button
                  type="submit"
                  text={t(`common.actions.search`)}
                  size="sm"
                  style={{
                    width: isMobile ? '100%' : 125,
                    height: 41,
                    marginTop: isMobile ? 0 : '1.5rem',
                  }}
                  disabled={
                    (!formState.isSubmitted && !hasSomeFilterValue()) ||
                    Object.keys(formState.errors).length > 0
                  }
                />
                <Button
                  text={t(`common.actions.clean`)}
                  size="sm"
                  outlined
                  onClick={cleanFilters}
                  style={{
                    width: isMobile ? '100%' : 125,
                    height: 41,
                    marginTop: isMobile ? '1rem' : '1.5rem',
                    marginLeft: 10,
                  }}
                />
              </div>
            </Col>
          </Row>
        </form>
      </SearchAccordion>
    </div>
  );
}
