import { DisplayInfo, Table } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import { prefix } from '..';
import { TableCurriculumDegreeActivity } from '../types';
import { useColumns } from './useColumns';

export interface TableProps {
  data: TableCurriculumDegreeActivity[];
  readOnly: boolean;
  isLoadingResults: boolean;
  totalItems: number;
  totalPages: number;
  currentPage: number;
  onChangePage: (page: number) => void;
  onDeleteCurriculumActivity: (code: string | number) => void;
}

const ITEMS_PER_PAGE = 10;

export default function TableCurriculumActivity({
  data,
  readOnly,
  isLoadingResults,
  currentPage,
  totalItems,
  totalPages,
  onChangePage,
  onDeleteCurriculumActivity,
}: TableProps) {
  const dictPrefix = `${prefix}.tableActivities`;
  const { t } = useTranslation();

  const columns = useColumns({ readOnly, onDeleteCurriculumActivity });

  return (
    <Table
      isLoadingResults={isLoadingResults}
      data={data}
      columns={columns}
      pagination={{
        currentPage: currentPage + 1,
        itemsPerPage: ITEMS_PER_PAGE,
        totalItems: totalItems,
        totalPages: totalPages,
        onChangePage: (page) => onChangePage(page - 1),
      }}
      noResultsText={
        <div className="d-flex justify-content-center align-items-center py-5">
          <DisplayInfo
            iconName="information"
            title={t(`${dictPrefix}.noResults.description`)}
            textBody={t(`${dictPrefix}.noResults.waitingAction`)}
            maxWidth="650px"
          />
        </div>
      }
    />
  );
}
