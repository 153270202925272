import { DateInput, Select, TextInput } from '@octano/global-ui';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, FormGroup, Row } from 'reactstrap';
import { format as formatRut } from 'rut.js';
import SectionTitle from '../../../../components/text/SectionTitle';
import { useValidations } from '../../../../hooks/useValidations';
import { formatDate } from '../../../../utils/dates';
import { getSelectedOption } from '../../../../utils/selectFormat';
import {
  maritalStatusOptions,
  sexCIOptions,
} from '../../../../utils/selectOptions';
import { BackgroundSectionType } from '../../../../types/studentFileSectionTypes';
import { useParameters } from '../../../../hooks/useParameters';
import StudentPhoto, {
  StudentPhotoTexts,
} from '../../../TuitionProcess/parts/StudentFile/StudentPhoto';
import { FieldsStudentNoSuaFile } from '../../../../types/studentFileNoSuaTypes';
import { useEffect } from 'react';

type StudentBackgroundNoSuaProps = {
  data: BackgroundSectionType | undefined;
  postulantId?: string | number;
  isStudentFileUpdate?: boolean;
};

/**
 * Sección de Antecedentes del estudiante SIN SUA
 */
const StudentBackgroundNoSua = ({
  data,
  postulantId,
  isStudentFileUpdate = false,
}: StudentBackgroundNoSuaProps) => {
  const { control, setValue } = useFormContext<FieldsStudentNoSuaFile>();

  const prefix = `tuitionProcess.onSite.studentFile.backgroundSection`;
  const { t } = useTranslation();
  const { msgValidations } = useValidations();
  const { countryOptions, nationalityOptions } = useParameters();

  const studentPhotoTexts = t<any, StudentPhotoTexts>(
    `tuitionProcess.onSite.studentFile.studentPhoto`,
    { returnObjects: true },
  );

  useEffect(() => {
    if (isStudentFileUpdate && data?.student?.nationality) {
      const selected = getSelectedOption(
        data?.student.nationality?.id,
        nationalityOptions,
      );
      !!selected && setValue('nationality', selected);
    }
  }, [
    data?.student.nationality,
    isStudentFileUpdate,
    nationalityOptions,
    setValue,
  ]);

  useEffect(() => {
    if (isStudentFileUpdate && data?.student?.sexId) {
      const selected = getSelectedOption(data?.student?.sexId, sexCIOptions);
      !!selected && setValue('sexID', selected);
    }
  }, [data?.student?.sexId, isStudentFileUpdate, setValue]);

  useEffect(() => {
    if (isStudentFileUpdate && data?.student?.maritalStatusId) {
      const selected = getSelectedOption(
        data?.student?.maritalStatusId,
        maritalStatusOptions,
      );
      !!selected && setValue('maritalStatus', selected);
    }
  }, [data?.student?.maritalStatusId, isStudentFileUpdate, setValue]);

  return (
    <>
      <Row>
        <Col xs={12}>
          <SectionTitle text={t(`${prefix}.title`)} />
        </Col>
      </Row>
      <Row>
        <Col className="pb-3" xs={12} lg="auto">
          <FormGroup>
            <div className="m-auto mw-100" style={{ width: '121px' }}>
              <StudentPhoto
                texts={studentPhotoTexts}
                defaultPhoto={data?.student.photo}
                isStudentFileUpdate={isStudentFileUpdate}
                postulantId={postulantId}
              />
            </div>
          </FormGroup>
        </Col>
        <Col xs={12} lg={true}>
          <Row>
            <Col className="pb-3" xs={12} lg={6}>
              {data?.student.rut ? (
                <TextInput
                  name="rut"
                  label={t(`${prefix}.rut`)}
                  formatter="rut"
                  value={formatRut(data?.student.rut)}
                  defaultValue={formatRut(data.student.rut)}
                  disabled
                  shouldUnregister={true}
                />
              ) : (
                <Row>
                  <Col xs={5} className="pr-1 pr-md-2">
                    <Select
                      name="passportCountry"
                      label={t(`${prefix}.passportCountry`)}
                      options={countryOptions}
                      value={getSelectedOption(
                        data?.student?.passport?.countryId,
                        countryOptions,
                      )}
                      defaultValue={getSelectedOption(
                        data?.student?.passport?.countryId,
                        countryOptions,
                      )}
                      shouldUnregister={true}
                      isClearable={false}
                      disabled
                    />
                  </Col>
                  <Col xs={7} className="pl-1 pl-md-2">
                    <TextInput
                      name="passportNumber"
                      label={t(`${prefix}.passportNumber`)}
                      value={data?.student?.passport?.number}
                      defaultValue={data?.student.passport?.number}
                      shouldUnregister={true}
                      disabled
                    />
                  </Col>
                </Row>
              )}
            </Col>
            <Col className="pb-3" xs={12} lg={6}>
              <TextInput
                name="namesStudent"
                label={t(`${prefix}.names`)}
                value={data?.student.names}
                control={isStudentFileUpdate ? control : undefined}
                disabled={!isStudentFileUpdate}
                rules={
                  isStudentFileUpdate
                    ? {
                        required: msgValidations.required,
                      }
                    : undefined
                }
              />
            </Col>
            <Col className="pb-3" xs={12} lg={6}>
              <TextInput
                name="paternalSurname"
                label={t(`${prefix}.paternalLastName`)}
                value={data?.student.paternalName}
                control={isStudentFileUpdate ? control : undefined}
                disabled={!isStudentFileUpdate}
                rules={
                  isStudentFileUpdate
                    ? {
                        required: msgValidations.required,
                      }
                    : undefined
                }
              />
            </Col>
            <Col className="pb-3" xs={12} lg={6}>
              <TextInput
                name="maternalSurname"
                label={t(`${prefix}.maternalLastName`)}
                value={data?.student.maternalName}
                control={isStudentFileUpdate ? control : undefined}
                disabled={!isStudentFileUpdate}
                rules={
                  isStudentFileUpdate
                    ? {
                        required: msgValidations.required,
                      }
                    : undefined
                }
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        {!isStudentFileUpdate && (
          <>
            <Col className="pb-3" xs={12} lg={4}>
              <TextInput
                name="cv"
                label={t(`${prefix}.cv`)}
                value={data?.studyPlan?.code}
                disabled={!isStudentFileUpdate}
              />
            </Col>
            <Col className="pb-3" xs={12} lg={8}>
              <TextInput
                name="studyPlan"
                label={t(`${prefix}.studyPlan`)}
                value={data?.studyPlan?.name}
                disabled={!isStudentFileUpdate}
              />
            </Col>
            <Col className="pb-3" xs={12} lg={4}>
              <TextInput
                name="campus"
                label={t(`${prefix}.campus`)}
                value={data?.studyPlan?.campus}
                disabled={!isStudentFileUpdate}
              />
            </Col>
            <Col className="pb-3" xs={12} lg={4}>
              <TextInput
                name="schedule"
                label={t(`${prefix}.schedule`)}
                value={data?.studyPlan?.schedule}
                disabled={!isStudentFileUpdate}
              />
            </Col>
            <Col className="pb-3" xs={12} lg={4}>
              <TextInput
                name="entryYear"
                label={t(`${prefix}.entryYear`)}
                value={data?.postulation?.entryYear}
                disabled={!isStudentFileUpdate}
              />
            </Col>
          </>
        )}
        <Col className="pb-3" xs={12} lg={4}>
          <Select
            name="nationality"
            label={t(`${prefix}.nationality`)}
            value={getSelectedOption(
              data?.student.nationality?.id,
              nationalityOptions,
            )}
            control={control}
            options={nationalityOptions}
            isSearchable={true}
            rules={{ required: msgValidations.required }}
          />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          {!isStudentFileUpdate ? (
            <TextInput
              name="birthday"
              label={t(`${prefix}.birthday`)}
              value={
                data?.student.birthday
                  ? formatDate(data?.student.birthday, 'DD-MM-YYYY')
                  : ''
              }
              disabled
            />
          ) : (
            <DateInput
              name="birthday"
              label={t(`${prefix}.birthday`)}
              control={control}
              rules={{
                required: msgValidations.required,
              }}
            />
          )}
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <Select
            name="sexID"
            label={t(`${prefix}.sexID`)}
            options={sexCIOptions}
            isSearchable={false}
            control={control}
            rules={{ required: msgValidations.required }}
          />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <Select
            name="maritalStatus"
            label={t(`${prefix}.maritalStatus`)}
            options={maritalStatusOptions}
            isSearchable={false}
            control={control}
            rules={{ required: msgValidations.required }}
          />
        </Col>
        {/* personalEmail */}
        {!!isStudentFileUpdate && (
          <>
            <Col className="pb-3" xs={12} lg={4}>
              <TextInput
                name="personalEmail"
                label={t(`${prefix}.personalEmail`)}
                value={data?.student?.personalEmail}
                control={isStudentFileUpdate ? control : undefined}
                rules={{
                  required: msgValidations.required,
                }}
              />
            </Col>
            <Col className="pb-3" xs={12} lg={4}>
              <TextInput
                name="officialEmail"
                label={t(`${prefix}.email`)}
                value={data?.student?.officialEmail}
                disabled
              />
            </Col>
          </>
        )}
      </Row>
    </>
  );
};

export default StudentBackgroundNoSua;
