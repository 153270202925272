import { ReactNode, useMemo } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import {
  Tab,
  Tabs,
  TabPanel,
  TabPanels,
  TabsList,
  Button,
  DetailBox,
} from '@octano/global-ui';

import AdmissionsList, {
  AdmissionsListProps,
} from '../../admission/components/AdmissionsList';
import DroppedList from '../../admission/AdmissionsListDropped/AdmissionsListDropped';
import EnrolledList from '../../admission/AdmissionsListEnrolled/AdmissionsListEnrolled';
import InProcessList from '../../admission/inProcess/inProcess';
import { AdmissionsListRetracted as RetractedList } from '../../admission/AdmissionsListRetracted/AdmissionsListRetracted';
import WaitingList from '../../admission/waitingList/WaitingList';

import { PathsLayouts } from '../../config/routes';
import { Card, Col, Row } from 'reactstrap';

type Status = AdmissionsListProps['status'];
type TabList = Array<{ name: string; status: Status; content: ReactNode }>;

export default function AdmissionsListWithTabs() {
  const { t } = useTranslation();
  const tabsPrefix = 'admissions.tabs';

  const location = useLocation();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  const tabs: TabList = useMemo(
    () => [
      { name: 'in-process', status: 'en_proceso', content: <InProcessList /> },
      { name: 'enrolled', status: 'matriculado', content: <EnrolledList /> },
      { name: 'waiting-list', status: 'en_espera', content: <WaitingList /> },
      { name: 'dropped', status: 'desistido', content: <DroppedList /> },
      { name: 'retracted', status: 'retractado', content: <RetractedList /> },
    ],
    [],
  );

  const initialTab: TabList[number] = useMemo(() => {
    const currentTabName = location.pathname.split('/').reverse()[0];
    const matchedTab = tabs.find((tab) => tab.name === currentTabName);
    return matchedTab ?? tabs[0];
  }, [location, tabs]);

  const handleTabSelection = (tab: string | number) => {
    const routeToGo = `${PathsLayouts.admissions}/${id}/${tab}`;
    history.push(routeToGo);
    return null;
  };

  const detailBoxInfo = useMemo(() => {
    const admissionInfo = JSON.parse(
      localStorage.getItem('admission-info') || '{}',
    );
    return {
      studyPlan: admissionInfo.name,
      campus: admissionInfo.campus_name,
      workingDay: admissionInfo.scheduleName,
    };
  }, []);

  const headerColumns = useMemo(() => {
    return [
      {
        title: t('admission.studyPlan'),
        body: detailBoxInfo.studyPlan,
        size: { xs: 12, md: 6 },
      },
      {
        title: t('admission.campus'),
        body: detailBoxInfo.campus,
        size: { xs: 6, md: 3 },
      },
      {
        title: t('admission.workingDay'),
        body: detailBoxInfo.workingDay,
        size: { xs: 6, md: 3 },
      },
    ];
  }, [t, detailBoxInfo]);

  return (
    <AdmissionsList status={initialTab.status}>
      <Card className="mx-3 px-3 pb-5">
        <Row className="pt-4 mb-5">
          <Col>
            <Button
              size="sm"
              icon="back"
              onClick={() => history.push(PathsLayouts.admissions)}
              text={t('admission.back')}
              rounded
              outlined
            />
          </Col>
          <Col md={8} lg={6} className="py-3 py-md-0 px-3">
            <DetailBox columns={headerColumns} />
          </Col>
        </Row>
        <Tabs defaultTab={initialTab.name} onSelection={handleTabSelection}>
          <TabsList>
            {tabs.map((tab) => (
              <Tab
                key={tab.name}
                id={tab.name}
                children={t(`${tabsPrefix}.${tab.name}`)}
              />
            ))}
          </TabsList>
          <TabPanels panelsClassName="p-2">
            {tabs.map((tab) => (
              <TabPanel key={tab.name} id={tab.name} children={tab.content} />
            ))}
          </TabPanels>
        </Tabs>
      </Card>
    </AdmissionsList>
  );
}
