import { Button, Icon, Modal, addToast } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import {
  createCurriculumRequest,
  editCurriculumRequest,
} from '../../api/requests/curriculum';

interface CurriculumPublishModalProps {
  show: boolean;
  onClose: Function;
  curriculumData: any;
}

export const CurriculumPublishModal = ({
  show,
  onClose,
  curriculumData,
}: CurriculumPublishModalProps) => {
  let history = useHistory();
  const { t } = useTranslation();
  const prefix = 'studyPlans.curriculum.publishModal';

  const {
    id: studyPlanId,
    versionId,
    mentionId,
    curriculumId,
    action,
  } = useParams<{
    id?: string;
    versionId?: string;
    mentionId?: string;
    curriculumId?: string;
    action?: string;
  }>();

  const closeModal = () => {
    onClose();
  };

  const publishCurriculum = async () => {
    let response: any = {};
    let isEdit: boolean = false;
    if (
      (curriculumId && !isNaN(parseInt(curriculumId)) && !action) ||
      (mentionId && !isNaN(parseInt(mentionId)) && !action)
    ) {
      response = await editCurriculumRequest(
        curriculumData,
        curriculumId || mentionId,
      );
      isEdit = true;
    } else {
      response = await createCurriculumRequest(curriculumData);
    }

    if (!response.error) {
      addToast({
        icon: 'success',
        color: 'success',
        text: isEdit
          ? t(`${prefix}.editSuccess`)
          : t(`${prefix}.createSuccess`),
        className: 'long-toast',
      });
      closeModal();
      if (mentionId) {
        history.push(
          `/study-plans/record/${studyPlanId}/version/${versionId}/mention/list`,
        );
      } else {
        history.push(`/study-plans/record/${studyPlanId}`);
      }
    } else {
      const errorMessage = response?.error.data.message[0].includes(
        'already contains',
      )
        ? t(`${prefix}.alreadyExists`)
        : t(`${prefix}.unhandledError`);
      addToast({
        icon: 'error',
        color: 'danger',
        text: errorMessage,
        className: 'long-toast',
      });
    }
  };

  return (
    <Modal
      isOpen={show}
      toggle={() => {
        closeModal();
      }}
      size="md"
    >
      <div className="error-modal">
        <Row className="mb-4">
          <Col className="text-center">
            <Icon color="secondary" name="warning" size="50" />
          </Col>
        </Row>
        <Row className="mb-4">
          <Col className="text-center tittle">
            {t(`${prefix}.confirmMessage`)}
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6} className="mt-4">
            <Button
              type="button"
              text={t(`${prefix}.cancel`)}
              onClick={closeModal}
              fullwidth
              outlined
            />
          </Col>
          <Col xs={12} md={6} className="mt-4">
            <Button
              type="button"
              text={t(`${prefix}.confirm`)}
              onClick={publishCurriculum}
              fullwidth
            />
          </Col>
        </Row>
      </div>
    </Modal>
  );
};
