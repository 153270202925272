import { ColumnTable, Icon } from '@octano/global-ui';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { prefix } from '..';
import useUserState from '../../../../hooks/useUserState';
import { PermissionName } from '../../../../types/Auth';
import { TableCurriculumDegreeActivity } from '../types';

interface ColumnsProps {
  readOnly: boolean;
  onDeleteCurriculumActivity: (id: string | number) => void;
}

export function useColumns({
  readOnly,
  onDeleteCurriculumActivity,
}: ColumnsProps) {
  const dictPrefix = `${prefix}.tableActivities`;
  const { isAuthorizedTo } = useUserState();
  const { t } = useTranslation();
  const texts = useMemo(
    () => ({
      headers: t<string, Record<string, string>>(`${dictPrefix}.headers`, {
        returnObjects: true,
      }),
    }),
    [t, dictPrefix],
  );

  const handleDeleteCurriculumActivity = useCallback(
    (id: string | number) => {
      onDeleteCurriculumActivity(id);
    },
    [onDeleteCurriculumActivity],
  );

  const columns = useMemo((): ColumnTable<TableCurriculumDegreeActivity>[] => {
    const columnData: ColumnTable<TableCurriculumDegreeActivity>[] = [
      {
        columnName: 'name',
        width: '40px',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: texts.headers.activities,
        cellFormat: ({ row }) => row.name,
      },
      {
        columnName: 'code',
        width: '15%',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: texts.headers.code,
        cellFormat: ({ row }) => row.code,
      },
      {
        columnName: 'description',
        width: '25%',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: texts.headers.description,
        cellFormat: ({ row }) => row.description,
      },
      {
        columnName: 'allowGrades',
        width: '40px',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: texts.headers.allowGrades,
        cellFormat: ({ row }) => {
          if (!row.allowGrades) {
            return t(`${dictPrefix}.allowGrades.hasMentionsNo`);
          }
          return t(`${dictPrefix}.allowGrades.hasMentionsYes`);
        },
      },
      {
        columnName: 'id',
        width: '40px',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: texts.headers.action,
        cellFormat: ({ row }) => (
          <span
            onClick={() => handleDeleteCurriculumActivity(row.code)}
            className="mr-2"
          >
            <Icon
              className={`cursor-pointer ${
                !isAuthorizedTo([PermissionName.DELETE_ACADEMIC_OFFER]) ||
                readOnly
                  ? 'icon-disabled'
                  : ''
              }`}
              name="trash"
              key="eye"
              size="18px"
            />
          </span>
        ),
      },
    ];
    if (readOnly) {
      columnData.splice(-1);
    }
    return columnData;
  }, [
    t,
    texts,
    dictPrefix,
    readOnly,
    isAuthorizedTo,
    handleDeleteCurriculumActivity,
  ]);

  return columns;
}
