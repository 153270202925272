import { CURRICULUM } from '../../../../api/endpoints';
import request from '../../../../api/request';
import { CreateActivityParams } from '../types';
import {
  ResponseGetCurriculumDegreeActivity,
  ResponseGetDegreeActivityTypes,
  ResponsePostCreateDegreeActivity,
} from '../types/response';

/**
 * Obtiene el listado de los DegreeActivityTypes a mostrar para un curriculum a editar
 * @returns
 */
export const getDegreeActivityTypes = () => {
  const URL = CURRICULUM.GET_DEGREE_ACTIVITY_TYPES_LIST;
  const method = 'GET';
  return request<ResponseGetDegreeActivityTypes>(URL, { method });
};

/**
 * Obtiene el listado de los DegreeActivities a mostrar para un curriculum a editar
 * @param items
 * @param page
 * @returns
 */
export const getDegreeActivityByCurriculum = (
  curriculumId: number | string,
  filter: {
    items: number;
    page: number;
  },
) => {
  const URL = CURRICULUM.GET_DEGREE_ACTIVITY_BY_CURRICULUM_LIST(curriculumId);
  const params = { ...filter, items_per_page: filter.items };
  const method = 'GET';
  return request<ResponseGetCurriculumDegreeActivity>(URL, { method, params });
};

/**
 * Crea un DegreeActivityType para un curriculum a editar
 * @returns
 */
export const postCreateDegreeActivity = (data: CreateActivityParams) => {
  return request<ResponsePostCreateDegreeActivity>(
    CURRICULUM.POST_DEGREE_ACTIVITY_BY_CURRICULUM_CREATE,
    {
      method: 'POST',
      data,
    },
  );
};

/**
 * [No en uso]
 * Borrar una CurriculumDegreeActivity para un curriculum a editar
 * @returns
 */
export const deleteRemoveDegreeActivity = (
  curriculumId: number | string,
  curriculumDegreeActivityId: number | string,
) => {
  return request<ResponsePostCreateDegreeActivity>(
    CURRICULUM.DELETE_DEGREE_ACTIVITY_BY_CURRICULUM_REMOVE(
      curriculumId,
      curriculumDegreeActivityId,
    ),
    {
      method: 'DELETE',
    },
  );
};
