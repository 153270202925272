import { Button, Icon, Table, TablePagination } from '@octano/global-ui';
import { CellFormatOptions } from '@octano/global-ui/dist/components/Table/types/TableTypes';
import { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { getScheduleList } from '../../api/requests/schedules';
import DisplayError from '../../components/info/DisplayError';
import Loading from '../../components/info/Loading';
import { TextEllipsis } from '../../components/text';
import TableEmptyContent from '../../components/text/TableEmptyContent';
import { useLayoutState } from '../../hooks/useLayoutState';
import { useLoadingState } from '../../hooks/useLoadingState';
import useUserState from '../../hooks/useUserState';
import { PermissionName } from '../../types/Auth';
import styles from './ScheduleList.module.scss';

export interface ScheduleItem {
  id: number;
  name: string;
  description: string;
}

const ScheduleList = () => {
  const { isAuthorizedTo } = useUserState();
  const { showTabs } = useLayoutState();
  useEffect(() => {
    showTabs();
  }, [showTabs]);

  const { t } = useTranslation();
  const [schedules, setSchedules] = useState<ScheduleItem[]>([]);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [showPagination, setShowPagination] = useState<boolean>(false);
  const { loading, setLoading, errorLoading, setErrorLoading } =
    useLoadingState();

  const columns = [
    {
      columnName: 'code',
      headerText: t('common.forms.code'),
      bold: true,
      width: '20%',
    },
    {
      columnName: 'name',
      headerText: t('common.forms.name'),
      width: '20%',
    },
    {
      columnName: 'description',
      headerText: t('common.forms.description'),
      cellFormat: (options: CellFormatOptions) => (
        <TextEllipsis key={options.index} textWidth={350}>
          {options.value}
        </TextEllipsis>
      ),
    },
    {
      columnName: 'id',
      headerText: '',
      width: '40px',
      cellFormat: (options: CellFormatOptions) => {
        return (
          <Link to={`/maintainers/schedules/record/${options.value}`}>
            <Icon className="custom-eye" name="eye" key="eye" size="22px" />
          </Link>
        );
      },
    },
  ];

  const getSchedules = useCallback(
    async (items: number = 10, page: number = 0) => {
      const { data, error } = await getScheduleList(items, page);
      if (error) {
        setErrorLoading(error.code);
        return;
      }
      if (data) {
        setErrorLoading(undefined);
        const { data: items = [], total = 0, total_pages = 1 } = data;
        const mappedItems = items.map((item) => Object.assign({}, item));
        setSchedules(mappedItems);
        setTotalItems(total);
        setTotalPages(total_pages);
        setShowPagination(true);
      }
      setLoading(false);
    },
    [setErrorLoading, setLoading],
  );

  useEffect(() => {
    getSchedules();
  }, [getSchedules]);

  const changePage = async (page: number = 1) => {
    await getSchedules(10, page - 1);
    setCurrentPage(page);
  };

  const history = useHistory();
  const goToRecord = () => {
    history.push(`/maintainers/schedules/record`);
  };

  if (errorLoading) {
    return (
      <DisplayError
        insideCard
        textBody={errorLoading}
        retryAction={() => setLoading(true)}
        loadingAction={loading}
      />
    );
  }
  if (loading) {
    return <Loading insideCard />;
  }
  return (
    <div className="g-table-container">
      <Button
        text={t('maintainers.scheduleList.newSchedule')}
        type="button"
        size="sm"
        icon="plus"
        className="g-add-button mb-3 mt-3"
        onClick={goToRecord}
        disabled={!isAuthorizedTo([PermissionName.CREATE_SCHEDULE])}
      />
      <div id={styles.schedulesTable}>
        <Table
          columns={columns}
          data={schedules}
          noResultsText={
            <TableEmptyContent
              title={t('maintainers.scheduleList.scheduleTableEmptyTitle')}
              subtitle={t(
                'maintainers.scheduleList.scheduleTableEmptySubtitle',
              )}
            />
          }
        />
        {showPagination && totalItems > 10 && (
          <TablePagination
            pagination={{
              currentPage: currentPage,
              itemsPerPage: 10,
              onChangePage: (page) => {
                changePage(page);
              },
              totalItems: totalItems,
              totalPages: totalPages,
            }}
          />
        )}
      </div>
    </div>
  );
};

export default ScheduleList;
