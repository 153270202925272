import { Button, TextInput, Select, SelectOptionType } from '@octano/global-ui';
import { useEffect, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'reactstrap';
import {
  CampusListResponse,
  saveCampusRequest,
} from '../../api/requests/campus';
import { CardTitle } from '../../components/text';
import { useValidations } from '../../hooks/useValidations';
import { CampusBody } from '../../types/CampusTypes';

import { useFilteredCommunes } from '../../hooks/useFilteredCommunes';
import { AxiosResult, AxiosResultDefaultError } from '../../api/request';
import { useParameters } from '../../hooks/useParameters';

export interface Campus {
  code: string;
  name: string;
  addressStreet: string;
  addressNumber: string;
  addressExtra?: string;
  commune: SelectOptionType | null;
  region: SelectOptionType | null;
}

export type RecordToEditBody = CampusBody & {
  commune: {
    id: number;
    name: string;
    regionId: number;
    region: {
      id: number;
      name: string;
    };
  };
};

interface CampusFormProps {
  recordToEdit?: RecordToEditBody;
  onSubmit: (
    result: AxiosResult<CampusListResponse, AxiosResultDefaultError>,
  ) => void;
  onCancel: () => void;
}

const getTranslationsTexts = (isEditionMode: boolean) => {
  const translationPreffix = 'maintainers.campusForm';
  return isEditionMode
    ? {
        create: 'common.actions.saveChanges',
        success: 'common.messages.editSuccess',
        formTitle: `${translationPreffix}.editTitle`,
      }
    : {
        create: `${translationPreffix}.create`,
        success: `${translationPreffix}.createSuccess`,
        formTitle: `${translationPreffix}.addTitle`,
      };
};

const getDefaultValues = (recordToEdit?: RecordToEditBody) =>
  recordToEdit
    ? {
        code: recordToEdit.code,
        name: recordToEdit.name,
        addressStreet: recordToEdit.addressStreet,
        addressNumber: recordToEdit.addressNumber,
        addressExtra: recordToEdit.addressExtra ?? '',
        region: {
          label: recordToEdit.commune.region.name,
          value: recordToEdit.commune.regionId,
        },
        commune: {
          label: recordToEdit.commune.name,
          value: recordToEdit.commune.id,
        },
      }
    : {
        code: '',
        name: '',
        addressStreet: '',
        addressNumber: '',
        addressExtra: '',
        region: null,
        commune: null,
      };

const CampusForm = ({
  recordToEdit,
  onSubmit: onSubmitProp,
  onCancel,
}: CampusFormProps) => {
  const { regionOptions } = useParameters();
  const { t } = useTranslation();
  const methods = useForm<Campus>({
    mode: 'onSubmit',
    defaultValues: getDefaultValues(recordToEdit),
  });

  const {
    handleSubmit,
    formState: { isSubmitting, touchedFields, isDirty },
    control,
    setValue,
    watch,
    setError,
  } = methods;

  const onSubmit = useCallback(
    async (values: Campus) => {
      const {
        code,
        name,
        addressStreet,
        addressNumber,
        addressExtra,
        commune,
      } = values;

      const requestBody = {
        code,
        name,
        addressStreet,
        addressNumber,
        communeId: Number(commune?.value),
        addressExtra,
        id: recordToEdit?.id,
      };

      const result = await saveCampusRequest(requestBody);

      if (result.error && result.error.code === 'HTTP_ERROR') {
        setError('code', {
          message: t('common.errors.codeError', { code: values.code }),
        });
        return;
      }
      onSubmitProp(result);
    },
    [onSubmitProp, setError, t, recordToEdit],
  );

  const isEditionMode = !!recordToEdit;
  const translationPreffix = 'maintainers.campusForm';
  const { msgValidations } = useValidations();
  const selectedRegion = watch('region');

  const isRegionTouched = touchedFields.region;
  const texts = getTranslationsTexts(isEditionMode);

  const filteredCommunes = useFilteredCommunes(selectedRegion || undefined);

  useEffect(() => {
    if (isRegionTouched) {
      setValue('commune', null);
    }
  }, [selectedRegion, setValue, isRegionTouched]);

  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col>
            <div className="text-center">
              <CardTitle children={t(texts.formTitle)} />
            </div>
            <Row className="mt-0 mt-md-5">
              <Col xs="12" md="6">
                <TextInput
                  name="code"
                  label={t('common.forms.code')}
                  control={control}
                  rules={{
                    required: msgValidations.required,
                    pattern: {
                      value: /^[0-9a-zA-Z]+$/,
                      message: t('common.validations.invalidAlphanumeric'),
                    },
                  }}
                />
              </Col>
              <Col xs="12" md="6" className="mt-2 mt-md-0">
                <TextInput
                  name="name"
                  label={t('common.forms.name')}
                  control={control}
                  rules={{
                    required: msgValidations.required,
                    minLength: {
                      value: 3,
                      message: t('common.validations.minLength', {
                        length: 3,
                      }),
                    },
                  }}
                />
              </Col>
            </Row>
            <Row className="mt-0 mt-md-3">
              <Col xs="12" className="mt-2 mt-md-0">
                <TextInput
                  name="addressStreet"
                  label={t('common.forms.address')}
                  control={control}
                  rules={{
                    required: msgValidations.required,
                  }}
                />
              </Col>
            </Row>
            <Row className="mt-0 mt-md-3">
              <Col xs="12" md="6" className="mt-2 mt-md-0">
                <TextInput
                  name="addressNumber"
                  label="Nº"
                  control={control}
                  rules={{
                    required: msgValidations.required,
                  }}
                />
              </Col>
              <Col xs="12" md="6" className="mt-2 mt-md-0">
                <TextInput
                  name="addressExtra"
                  label={t(`${translationPreffix}.addressExtra`)}
                  control={control}
                />
              </Col>
            </Row>
            <Row className="mt-0 mt-md-3">
              <Col xs="12" md="6" className="mt-2 mt-md-0">
                <Select
                  name={'region'}
                  label={t('common.forms.region')}
                  options={regionOptions}
                  control={control}
                  rules={{ required: msgValidations.required }}
                />
              </Col>
              <Col xs="12" md="6" className="mt-2 mt-md-0">
                <Select
                  name="commune"
                  label={t('common.forms.commune')}
                  options={filteredCommunes}
                  control={control}
                  rules={{ required: msgValidations.required }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col className="mt-5">
            <Row>
              <Col xs={{ size: 12, order: 2 }} md={{ size: 6, order: 1 }}>
                <Button
                  type="button"
                  text={t('common.actions.cancel')}
                  outlined
                  onClick={onCancel}
                  fullwidth
                />
              </Col>
              <Col xs={{ size: 12, order: 1 }} md={{ size: 6, order: 2 }}>
                <Button
                  type="submit"
                  text={t(texts.create)}
                  loading={isSubmitting}
                  fullwidth
                  className="mb-3 mb-md-0"
                  disabled={isEditionMode && !isDirty}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </FormProvider>
  );
};
export default CampusForm;
