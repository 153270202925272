import { Button } from '@octano/global-ui';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Col, Row } from 'reactstrap';

import { useTuitionProcess } from '../../hooks/useTuitionProcess';
import { POSTULATION_STATUS } from '../../types/tuitionProcessOnSite';
import { PostulationDetailType } from '../../types/tuitionProcessTypes';
import { InProcessDiscountModal } from './../../admission/inProcess/InProcessDiscountModal';
import { AdmissionItem } from './../../admission/inProcess/inProcess';
import { DiscountItem, getAllDiscounts } from './../../api/requests/discounts';
import { getAdmissionStatusPostulant } from './../../api/requests/studyPlansOffer';
import DisplayError from './../../components/info/DisplayError';
import { useLoadingState } from './../../hooks/useLoadingState';
import useUserState from './../../hooks/useUserState';
import { PermissionName } from './../../types/Auth';

interface PropsDiscountPostulation {
  postulantId: number | undefined;
  postulationDetail: PostulationDetailType | null;
  reloadComponent: () => void;
}

export default function DiscountPostulation({
  postulantId,
  postulationDetail,
  reloadComponent,
}: PropsDiscountPostulation) {
  const prefix = 'common.discount';
  const { t } = useTranslation();
  const { loading, setLoading, errorLoading, setErrorLoading } =
    useLoadingState();
  const { isAuthorizedTo } = useUserState();

  const { statusPostulation } = useTuitionProcess();

  const [showAssignDiscount, setShowAssignDiscount] = useState<boolean>(false);
  const [discounts, setDiscounts] = useState<DiscountItem[]>([]);
  const [postulant, setPostulant] = useState<AdmissionItem>({
    Postulant_rut: '',
    PostulantPassport_number: '',
    Postulant_names: '',
    Postulant_paternalLastName: '',
    Postulant_maternalLastName: '',
    Contact_cellPhone: '',
    Contact_email: '',
    Discount_discountValue: 0,
    Details_enrolledDate: '',
    Details_retractedDate: '',
    Details_postulationId: 0,
    Details_id: 0,
    Discount_discountCode: '',
    Discount_discountName: '',
    Discount_id: null,
    PostulantPassport_countryId: null,
  });

  const toSetDiscount = () => {
    setShowAssignDiscount(true);
  };

  const getDiscounts = useCallback(async () => {
    setLoading(true);

    if (postulantId && postulationDetail) {
      const postulantInfo = await getAdmissionStatusPostulant(
        postulationDetail?.admissionId,
        {
          postulantId: postulantId,
          status:
            POSTULATION_STATUS[
              statusPostulation as keyof typeof POSTULATION_STATUS
            ],
        },
      );

      if (postulantInfo && postulantInfo.data) {
        setPostulant(postulantInfo.data as any);
      }
    }

    const discountsResponse = await getAllDiscounts();

    if (discountsResponse.error) {
      setErrorLoading(discountsResponse.error.code);
    } else if (discountsResponse.data) {
      setErrorLoading(undefined);
      setDiscounts(discountsResponse.data);
    }

    setLoading(false);
  }, [
    postulantId,
    postulationDetail,
    setErrorLoading,
    setLoading,
    statusPostulation,
  ]);

  useEffect(() => {
    getDiscounts();
  }, [getDiscounts]);

  if (errorLoading) {
    return (
      <DisplayError
        insideCard
        textBody={errorLoading}
        retryAction={() => getDiscounts()}
        loadingAction={loading}
      />
    );
  }

  return (
    <Card className="DiscountPostulation">
      <Row className="DiscountPostulation_content">
        <Col xs={12} lg={12} className="pb-2 order-2 order-lg-1">
          <Button
            text={t(`${prefix}.giveDiscount`)}
            size="sm"
            onClick={() => toSetDiscount()}
            outlined={!isAuthorizedTo([PermissionName.EDIT_ADMISIONS])}
            disabled={!isAuthorizedTo([PermissionName.EDIT_ADMISIONS])}
            fullwidth
          />
          <InProcessDiscountModal
            show={showAssignDiscount}
            toggleModal={() => {
              setShowAssignDiscount(false);
            }}
            reloadList={() => reloadComponent()}
            discounts={discounts}
            postulant={postulant}
          />
        </Col>
      </Row>
    </Card>
  );
}
