import { addToast, Button, Icon } from '@octano/global-ui';
import {
  Fragment,
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Col, FormText, Row, Tooltip } from 'reactstrap';

import { uploadPostulationDocs } from '../../../../api/requests/tuitionProcess';
import UploadModal, { IInput } from '../../../../components/modals/UploadModal';
import { useTuitionProcess } from '../../../../hooks/useTuitionProcess';
import { GeneralTypes } from '../../../../types/signDocumentationTypes';
import useDocsContent from './hooks/useDocsContent';
import { getInputs } from './utils';

export interface IDocumentCardProps {
  size?: 'sm' | 'md';
  isUploaded: boolean;
  docName: GeneralTypes;
  errorText?: string;
  onDownload?: () => void;
  onUploaded: () => void;
}
export interface IUrlLinkProps {
  href?: string;
}

const UrlLink: FunctionComponent<IUrlLinkProps> = ({ href, children }) => {
  return (
    <a
      className="fw-600 text-white"
      target="_blank"
      rel="noreferrer"
      href={href}
    >
      {children}
    </a>
  );
};

/**
 * Tarjeta que despliega el documento requerido para ser cargado.
 */
const DocumentCard = ({
  size = 'sm',
  isUploaded,
  docName,
  errorText,
  onDownload,
  onUploaded,
}: IDocumentCardProps) => {
  const prefix = 'postulationDocs';
  const { t } = useTranslation();
  const postulationDocs = useDocsContent();
  const { postulationDetail } = useTuitionProcess();
  const inputValues = getInputs(docName);

  const [open, setOpen] = useState<boolean>(false);
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);

  const btnIconName = isUploaded ? 'check' : undefined;
  const btnText = `${prefix}.buttons.${isUploaded ? 'uploaded' : 'upload'}`;

  const closeModal = () => setOpen(false);

  const submitFunction = useCallback(
    async (values: IInput) => {
      const { error } = await uploadPostulationDocs(
        docName,
        values,
        postulationDetail?.id,
      );
      if (!error) {
        closeModal();
        onUploaded();
        addToast({
          icon: 'success',
          color: 'success',
          text: t(`${prefix}.uploadSuccess`),
        });
      } else {
        addToast({
          icon: 'error',
          color: 'danger',
          text: t(`${prefix}.uploadError`),
        });
      }
    },
    [onUploaded, docName, t, postulationDetail],
  );

  const inputs = useMemo(
    () =>
      inputValues.map(({ name, labelPath, type }, index) => {
        if (type === 'file')
          return (
            <UploadModal.Input
              key={index}
              name={name}
              label={t(labelPath)}
              requiredMsgError={t(`postulationDocs.required.image`)}
            />
          );
        return (
          <UploadModal.TextArea
            key={index}
            name={name}
            label={t(labelPath)}
            requiredMsgError={t(`postulationDocs.required.textArea`)}
          />
        );
      }),
    [inputValues, t],
  );

  const UploadBtn = useCallback(
    () => (
      <Button
        icon={btnIconName}
        onClick={() => setOpen(true)}
        size="sm"
        text={t(btnText)}
        fullwidth
      />
    ),
    [btnIconName, btnText, t],
  );

  return (
    <Fragment>
      <div className="d-flex align-items-center">
        <span className="text-uppercase fs-18 fw-600 text-secondary">
          {t(`${prefix}.${docName}.name`)}
        </span>

        {postulationDocs[docName].hasTooltip && (
          <div id={`inputTooltip_${docName}`} className="pl-2">
            <Icon
              className="cursor-pointer"
              name="information_input"
              size="1rem"
            />
            <Tooltip
              placement="top"
              isOpen={tooltipOpen}
              autohide={false}
              target={`inputTooltip_${docName}`}
              toggle={() => setTooltipOpen((prevState) => !prevState)}
            >
              <Trans
                i18nKey={`${prefix}.${docName}.tooltip`}
                components={{
                  urlLink: <UrlLink />,
                }}
              />
            </Tooltip>
          </div>
        )}
      </div>

      <div
        className="d-flex flex-column justify-content-end bg-light px-4 py-3 mt-2"
        style={{ minHeight: '150px' }}
      >
        {size === 'sm' ? (
          <Row className="text-center">
            <Col xs={12} className="pb-2">
              <Icon
                name={postulationDocs[docName].icon}
                size="79px"
                color="secondary"
              />
            </Col>
            <Col xs={12} className="px-5 px-md-1 align-self-end">
              <UploadBtn />
            </Col>
          </Row>
        ) : (
          <Row className="flex-grow-1 h-100">
            <Col
              className="text-center align-self-center py-3 py-md-1 flex-grow-1"
              xs={12}
              md={6}
            >
              <Icon
                name={postulationDocs[docName].icon}
                size="95px"
                color="secondary"
              />
            </Col>
            <Col
              className="text-center px-5 px-md-3 align-self-end"
              xs={12}
              md={6}
            >
              <Button
                icon="download"
                onClick={onDownload}
                outlined
                size="sm"
                text={t(`${prefix}.buttons.download`)}
                fullwidth
              />
              <UploadBtn />
            </Col>
          </Row>
        )}
      </div>

      <FormText
        className="g-input-error"
        color="danger"
        style={{ minHeight: '21px' }}
      >
        {errorText}
      </FormText>

      <UploadModal
        prefix={`${prefix}.${docName}`}
        isOpen={open}
        onClose={closeModal}
        submitFunction={submitFunction}
        onSuccess={() => null}
        onError={() => null}
      >
        {inputs}
      </UploadModal>
    </Fragment>
  );
};

export default DocumentCard;
