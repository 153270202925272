import { TextAreaInput, TextField, TextInput } from '@octano/global-ui';
import { Fragment, ReactChild, useCallback } from 'react';
import { Col, Form, Row } from 'reactstrap';
import { SectionTitle } from '../../components/text';
import { useTranslation } from 'react-i18next';
import { useForm, FormProvider } from 'react-hook-form';
import { VersionValues } from '../../api/requests/versions';
import { useValidations } from '../../hooks/useValidations';
import { useLayoutState } from '../../hooks/useLayoutState';
import { StudyPlanItem } from '../interfaces/sudy-plan-item.interface';

export interface VersionFormProps {
  studyPlan: StudyPlanItem;
  children: (props: { isSubmitting: boolean; isDirty?: boolean }) => ReactChild;
  onSubmit: (
    values: VersionValues,
  ) => Promise<'CONNECTION' | 'HTTP_ERROR' | 'NOT_UNIQUE_CODE' | undefined>;
}

const VersionRecordForm = (props: VersionFormProps) => {
  const { t } = useTranslation();
  const prefix = 'studyPlans';

  const { studyPlan, onSubmit: onSubmitProp, children } = props;

  // validations
  const { msgValidations } = useValidations();

  // mostrar error al guardar
  const { showErrorModal } = useLayoutState();

  // valores predeterminados
  const defaultValues = Object.assign({
    code: '',
    name: '',
    studyPlanId: studyPlan.id,
    entryRequirements: '',
    graduationRequirements: '',
    degreeRequirements: '',
    status: 'Borrador',
  });

  const methods = useForm<VersionValues>({
    mode: 'onSubmit',
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting, isDirty },
    control,
    setError,
  } = methods;

  // guardar version
  const onSubmit = useCallback(
    async (formValues: VersionValues) => {
      const response = await onSubmitProp(formValues);
      if (response === 'CONNECTION') {
        showErrorModal();
      } else if (response === 'NOT_UNIQUE_CODE') {
        setError('code', {
          message: t('common.errors.codeError', { code: formValues.code }),
        });
      }
    },
    [onSubmitProp, showErrorModal, setError, t],
  );

  return (
    <Fragment>
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col xs={12} md={6}>
              <SectionTitle text={t(`${prefix}.version.dataVersion`)} />
              <Row>
                <Col xs={12}>
                  <TextField placeholder={t(`${prefix}.form.planName`)}>
                    {studyPlan.name}
                  </TextField>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={12} className="pb-3">
                  <TextInput
                    name="name"
                    control={control}
                    label={t(`${prefix}.versionList.name_version`)}
                    rules={{
                      required: msgValidations.required,
                      maxLength: {
                        value: 255,
                        message: t('common.validations.maxLength', {
                          length: 255,
                        }),
                      },
                    }}
                  />
                </Col>
                <Col xs={12} md={12}>
                  <div className="pb-3">
                    <TextInput
                      name="code"
                      control={control}
                      label={t(`${prefix}.versionList.code_version`)}
                      rules={{
                        required: msgValidations.required,
                        maxLength: {
                          value: 50,
                          message: t('common.validations.maxLength', {
                            length: 50,
                          }),
                        },
                        pattern: {
                          value: /^[0-9a-zA-Z]+$/,
                          message: t('common.validations.invalidAlphanumeric'),
                        },
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
            <Col xs={12} md={6} style={{ borderLeft: '1px solid #F4F4F4' }}>
              <Row>
                <Col xs={12} md={12}>
                  <div className="pb-3">
                    <TextAreaInput
                      name="entryRequirements"
                      control={control}
                      label={t(`${prefix}.version.entryRequirement`)}
                      height={100}
                      placeholder={t(`common.forms.descriptionPlaceholder`)}
                    />
                  </div>
                </Col>
                <Col xs={12} md={12}>
                  <div className="pb-3">
                    <TextAreaInput
                      name="graduationRequirements"
                      control={control}
                      label={t(`${prefix}.version.graduationRequirements`)}
                      height={100}
                      placeholder={t(`common.forms.descriptionPlaceholder`)}
                    />
                  </div>
                </Col>
                <Col xs={12} md={12}>
                  <div className="pb-3">
                    <TextAreaInput
                      name="degreeRequirements"
                      control={control}
                      label={t(`${prefix}.version.degreeRequirement`)}
                      height={100}
                      placeholder={t(`common.forms.descriptionPlaceholder`)}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          {children({ isSubmitting, isDirty })}
        </Form>
      </FormProvider>
    </Fragment>
  );
};

export default VersionRecordForm;
