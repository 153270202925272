import { OutlinedSelect, OutlinedSelectOptionType } from '@octano/global-ui';
import { ReactElement, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

import { StudyPlansAdmissionDetailForm } from '../../api/requests/studyPlans';
import { isNumber } from '../../utils/math';

import DisplayError from '../../components/info/DisplayError';
import './admissionsPlanesStudent.scss';

interface AdmissionsListProps extends StudyPlansAdmissionDetailForm {
  children?: (
    periodId: number,
    campusId?: number,
    schoolId?: number,
  ) => ReactElement;
}

export default function AdmissionsList({
  periods,
  currentPeriod,
  campuses,
  schools,
  children,
}: AdmissionsListProps) {
  const { t } = useTranslation();
  const dictPrefix = 'packages';

  const optionsSchools = useMemo<OutlinedSelectOptionType[]>(() => {
    return schools.map((p) => {
      return {
        value: p.id,
        label: p.name,
      };
    });
  }, [schools]);

  const optionsCampuses = useMemo<OutlinedSelectOptionType[]>(() => {
    return campuses.map((p) => {
      return {
        value: p.id,
        label: p.name,
      };
    });
  }, [campuses]);

  const optionsPeriods = useMemo<OutlinedSelectOptionType[]>(() => {
    return periods.map((p) => {
      return {
        value: p.id,
        label: p.name,
      };
    });
  }, [periods]);

  const [campus, setCampus] = useState<OutlinedSelectOptionType>();

  const [school, setSchool] = useState<OutlinedSelectOptionType>();

  const [period, setPeriod] = useState(() => {
    const option = optionsPeriods.find((o) => o.value === currentPeriod?.id);
    if (option) {
      return option;
    }
    if (optionsPeriods.length > 0) {
      return optionsPeriods[0];
    }
    return undefined;
  });

  const campusId = campus ? Number(campus.value) : undefined;
  const periodId = period?.value;
  const schoolId = school ? Number(school.value) : undefined;

  if (!periodId || !isNumber(periodId)) {
    return (
      <DisplayError
        insideCard
        textBody="Ha ocurrido un error al seleccionar el periodId"
      />
    );
  }

  return (
    <div id="admissionsPlanesList">
      <div className="g-table-container mt-2 pb-4 pt-4">
        <div className="mb-5">{t('packages.selectFilters')}</div>
        <Row>
          <Col md={4}>
            <OutlinedSelect
              name="period"
              label={t('packages.period')}
              options={optionsPeriods}
              value={period}
              onChange={(v) => {
                setPeriod(v);
              }}
              isClearable={false}
            />
          </Col>

          <Col xs={12} md={4} lg={4}>
            <OutlinedSelect
              name="campus"
              label={t(`${dictPrefix}.campus`)}
              options={optionsCampuses}
              placeholder="Todas las sedes"
              value={campus}
              onChange={(v) => {
                setCampus(v);
              }}
              isClearable={true}
            />
          </Col>

          <Col xs={12} md={4} lg={4}>
            <OutlinedSelect
              name="school"
              label={t(`${dictPrefix}.faculty`)}
              options={optionsSchools}
              placeholder="Todas las facultades"
              value={school}
              onChange={(s) => setSchool(s)}
              isClearable={true}
            />
          </Col>
        </Row>
        {children && children(periodId, campusId, schoolId)}
      </div>
    </div>
  );
}
