import { Button, Icon, IconPaths, Modal } from '@octano/global-ui';
import { Trans } from 'react-i18next';
import { Col, Row } from 'reactstrap';

interface BtnProps {
  text: string;
  action: () => void;
  color?: 'primary' | 'secondary' | 'danger';
}

interface ConfirmationModalProps {
  isOpen: boolean;
  toggle: () => void;
  closeOnBackdrop?: boolean;
  iconName?: keyof typeof IconPaths;
  iconColor?: 'primary' | 'secondary' | 'danger';
  title: string;
  body?: string | JSX.Element;
  primaryBtn: BtnProps;
  secondaryBtn?: BtnProps;
  loading?: boolean;
  boxDetail?: {
    title: string;
    body: (string | JSX.Element)[];
  };
}

const ConfirmationModal = ({
  isOpen,
  toggle,
  closeOnBackdrop = false,
  iconName = 'information',
  iconColor = 'secondary',
  title,
  body,
  primaryBtn,
  secondaryBtn,
  loading = false,
  boxDetail,
}: ConfirmationModalProps) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} closeOnBackdrop={closeOnBackdrop}>
      <Row className="text-center">
        <Col xs={12} className="pt-2">
          <Icon name={iconName} size="56px" color={iconColor} />
        </Col>
        <Col xs={12} className="pt-4 pb-3">
          <span className="fs-22 text-dark">{title}</span>
        </Col>
        {body && (
          <Col xs={12}>
            <p className="fs-18 fw-300">
              <Trans i18nKey={body as string} />
            </p>
          </Col>
        )}
        {boxDetail && (
          <Col xs={12} className="pt-3 pb-4 text-left">
            <span>{boxDetail.title}</span>
            <div
              className="g-box box-outlined box-secondary text-medium fs-16 w-100 mt-1"
              style={{ minHeight: '100px' }}
            >
              <ul className="m-0">
                {boxDetail.body.map((detail, i) => {
                  return <li key={`${detail}_${i}`}>{detail}</li>;
                })}
              </ul>
            </div>
          </Col>
        )}
      </Row>
      <Row className="pt-3">
        {secondaryBtn && (
          <Col xs={12} sm={6} className="pb-2 px-3 px-sm-2 mx-auto">
            <Button
              outlined
              color={secondaryBtn.color || 'primary'}
              onClick={secondaryBtn.action}
              text={secondaryBtn.text}
              disabled={loading}
              fullwidth
            />
          </Col>
        )}
        <Col
          xs={12}
          sm={secondaryBtn ? 6 : 12}
          className="pb-2 px-3 px-sm-2 mx-auto"
        >
          <Button
            color={primaryBtn.color || 'primary'}
            onClick={primaryBtn.action}
            text={primaryBtn.text}
            loading={loading}
            disabled={loading}
            fullwidth
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default ConfirmationModal;
