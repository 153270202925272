import {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { AxiosResultDefaultError } from '../../api/request';
import {
  searchPostulantPending,
  StudyPlanOffertResponse,
} from '../../api/requests/studyPlansOffer';

export type AdmissionPendingListContextErrorCode = 'CONNECTION' | 'HTTP_ERROR';

export interface AdmissionPendingListContextState {
  readonly loading: boolean;
  readonly data: StudyPlanOffertResponse;
  readonly error?: AxiosResultDefaultError['code'];
  readonly query: { page: number; search?: string };
  readonly updateQuery: (query?: { page: number; search?: string }) => void;
}

const contextDefaultValues: AdmissionPendingListContextState = {
  loading: true,
  data: { data: [], total: 0, total_pages: 0 },
  error: undefined,
  query: { page: 1 },
  updateQuery: (_query) => {
    throw new Error('Update Query No implementado');
  },
};

export const AdmissionPendingListContext =
  createContext<AdmissionPendingListContextState>(contextDefaultValues);

export function useAdmissionPendingList() {
  return useContext(AdmissionPendingListContext);
}

const AdmissionPendingListProvider: FC = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<StudyPlanOffertResponse>({
    data: [],
    total: 0,
    total_pages: 0,
  });
  const [error, setError] = useState<
    AdmissionPendingListContextErrorCode | undefined
  >(undefined);
  const [query, setQuery] = useState<{ page: number; search?: string }>({
    page: 1,
  });

  const fetchData = useCallback(
    async ({ page, search }: { page: number; search?: string }) => {
      setLoading(true);
      const result = await searchPostulantPending({ search }, { page });
      if (result.error) {
        setError(result.error.code);
      } else {
        setError(undefined);
        setData(result.data);
      }
      setLoading(false);
    },
    [],
  );

  useEffect(() => {
    fetchData(query);
  }, [fetchData, query]);

  const updateQuery = useCallback((q?: { page: number; search?: string }) => {
    setQuery(q ?? { page: 1 });
  }, []);

  return (
    <AdmissionPendingListContext.Provider
      value={{ loading, data, error: error, query, updateQuery }}
    >
      {children}
    </AdmissionPendingListContext.Provider>
  );
};

export default AdmissionPendingListProvider;
