import { addToast } from '@octano/global-ui';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from 'reactstrap';
import {
  getPostulationStateByRut,
  PostulationStateByRutResponse,
} from '../api/requests/tuitionProcesses';
import DisplayInfo from '../components/info/DisplayInfo';
import Loading from '../components/info/Loading';
import { useLayoutState } from '../hooks/useLayoutState';
import { Student } from '../types/tuitionProcessOnSite';
import { TuitionProcessForm } from './TuitionProcessForm';
import TuitionProcessSearchStudentForm from './TuitionProcessSearchStudentForm';

const TuitionProcessSearchStudent = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [postulationState, setPostulationState] = useState<
    PostulationStateByRutResponse | { state: 'INITIAL' }
  >({
    state: 'INITIAL',
  });

  const [student, setStudent] = useState<Student>();

  const { showErrorModal } = useLayoutState();

  const onSubmit = useCallback(
    async ({ rut }) => {
      setLoading(true);
      const postulationStateResult = await getPostulationStateByRut(rut);
      if (postulationStateResult.error) {
        if (postulationStateResult.error.code === 'CONNECTION') {
          showErrorModal();
        } else if (postulationStateResult.error.code === 'HTTP_ERROR') {
          addToast({
            icon: 'error',
            color: 'danger',
            text: t('common.errors.get'),
          });
        }
      } else {
        setPostulationState(postulationStateResult.data);
        setStudent({
          rut: rut,
          passportNumber: '',
          country: '',
          names: '',
          lastnamePather: '',
          lastnameMother: '',
          email: '',
          phone: '',
          status: 'sin_postulacion',
        });
      }
      setLoading(false);
    },
    [showErrorModal, t],
  );
  if (loading) {
    return (
      <div className="mx-3">
        <Loading insideCard />
      </div>
    );
  }
  return (
    <div className="mx-3">
      <TuitionProcessSearchStudentForm onSubmit={onSubmit} />
      <Card className="p-3 p-md-4 flex-grow-1">
        {postulationState.state === 'INITIAL' ? (
          <DisplayInfo
            maxWidth="256px"
            title={t(`tuitionProcess.searchStudent.initial.title`)}
            textBody={t(`tuitionProcess.searchStudent.initial.body`)}
          />
        ) : (
          student && (
            <TuitionProcessForm
              student={student}
              passport={false}
              preload={() => console.log('preload')}
            />
          )
        )}
      </Card>
    </div>
  );
};

export default TuitionProcessSearchStudent;
