import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Col, Row } from 'reactstrap';
import { DefaultValues } from 'react-hook-form';
import { format } from 'rut.js';
import { getStudentFile } from '../../../../api/requests/tuitionProcess';
import {
  BackgroundSectionType,
  EducationalBackgroundType,
  StudentFileResponse,
} from '../../../../types/studentFileSectionTypes';
import { useLoadingState } from '../../../../hooks/useLoadingState';
import DisplayError from '../../../../components/info/DisplayError';
import Loading from '../../../../components/info/Loading';
import StudentFileForm, { FieldsStudentFile } from './StudentFileForm';
import {
  educationalLevelOptions,
  familyGroupOptions,
  healthCareProviderOptions,
  healthCareSystemOptions,
  healthInsuranceOptions,
  maritalStatusOptions,
  parentalSurvivalOptions,
  sexCIOptions,
} from '../../../../utils/selectOptions';
import { getSelectedOption } from '../../../../utils/selectFormat';
import { Alert, SelectOptionType } from '@octano/global-ui';
import { useTuitionProcess } from '../../TuitionProcessContext';
import { useParameters } from '../../../../hooks/useParameters';

const formatDefaultValues = (
  data: StudentFileResponse,
  communeOptions: SelectOptionType[],
  regionOptions: SelectOptionType[],
): DefaultValues<FieldsStudentFile> => {
  const selectedCommune = getSelectedOption(
    data.contact?.communeId,
    communeOptions,
  );
  const selectedCommuneFamily = getSelectedOption(
    data.familyContact?.communeId,
    communeOptions,
  );
  return {
    sexID: getSelectedOption(data.student.sexId ?? undefined, sexCIOptions),
    maritalStatus: getSelectedOption(
      data.student.maritalStatusId ?? undefined,
      maritalStatusOptions,
    ),
    addressStreet: data.contact?.addressStreet,
    addressNumber: data.contact?.addressNumber,
    addressExtra: data.contact?.addressExtra ?? undefined,
    region: getSelectedOption(selectedCommune?.regionId, regionOptions),
    commune: selectedCommune,
    phone: data.contact?.phone ?? undefined,
    cellphone: data.contact?.cellPhone,
    email: data.contact?.email,
    addressStreetFamily: data.familyContact?.addressStreet,
    addressNumberFamily: data.familyContact?.addressNumber,
    addressExtraFamily: data.familyContact?.addressExtra ?? undefined,
    regionFamily: getSelectedOption(
      selectedCommuneFamily?.regionId,
      regionOptions,
    ),
    communeFamily: selectedCommuneFamily,
    phoneFamily: data.familyContact?.phone ?? undefined,
    cellphoneFamily: data.familyContact?.cellPhone,
    emailFamily: data.familyContact?.email,
    emergencyNames: data.emergencyContact?.name,
    mobilePhoneNumberEmergency: data.emergencyContact?.cellPhone,
    emailEmergency: data.emergencyContact?.email,
    fatherEducationalLevel: getSelectedOption(
      data.parentalEducationalBackground?.educationalLevelPaternalId,
      educationalLevelOptions,
    ),
    motherEducationalLevel: getSelectedOption(
      data.parentalEducationalBackground?.educationalLevelMaternalId,
      educationalLevelOptions,
    ),
    familyGroup: getSelectedOption(
      data.parentalEducationalBackground?.familyGroupId,
      familyGroupOptions,
    ),
    parentalSurvival: getSelectedOption(
      data.parentalEducationalBackground?.parentalSurvivalId,
      parentalSurvivalOptions,
    ),
    healthCareSystem: getSelectedOption(
      data.healthCare?.systemId,
      healthCareSystemOptions,
    ),
    healthCareProvider: getSelectedOption(
      data.healthCare?.providerId ?? undefined,
      healthCareProviderOptions,
    ),
    healthCareContributorID: data.healthCare?.contributorRut
      ? format(data.healthCare.contributorRut)
      : undefined,
    healthInsurance: getSelectedOption(
      data.healthCare?.hasInsurance !== undefined
        ? +data.healthCare?.hasInsurance
        : undefined,
      healthInsuranceOptions,
    ),
    healthInsuranceContributorID: data.healthCare?.insuranceContributorRut
      ? format(data.healthCare.insuranceContributorRut)
      : undefined,
    phoneNumberHealthCare: data.healthCare?.insuranceCellPhone ?? undefined,
  };
};

/**
 * Paso "Ficha Alumno" del Formulario de proceso de matrícula
 */
const StudentFile = () => {
  const { prefix: translationPrefix } = useTuitionProcess();
  const prefix = `${translationPrefix}.studentFile`;
  const { t } = useTranslation();
  const { postulationDetailId, onError } = useTuitionProcess();
  const { loading, setLoading, errorLoading, setErrorLoading } =
    useLoadingState();
  const [admissionType, setAdmissionType] =
    useState<{ id: string; name: string }>();
  const [backgroundData, setBackgroundData] = useState<BackgroundSectionType>();
  const [educationalBackground, setEducationalBackground] =
    useState<EducationalBackgroundType>();
  const [defaultValues, setDefaultValues] =
    useState<DefaultValues<FieldsStudentFile>>();
  const { communeOptions, regionOptions } = useParameters();

  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [alertText, setAlertText] = useState('');
  const showAlert = useCallback(
    (textKey: string) => {
      setIsAlertOpen(true);
      setAlertText(t(`${translationPrefix}.${textKey}`));
    },
    [t, translationPrefix],
  );

  const getStudentFileInfo = useCallback(async () => {
    const { data, error } = await getStudentFile(postulationDetailId);
    if (data) {
      setDefaultValues(
        formatDefaultValues(data, communeOptions, regionOptions),
      );
      setAdmissionType(data.admissionType);
      setBackgroundData({
        student: data.student,
        studyPlan: data.studyPlan,
        postulation: data.postulation,
      });
      setEducationalBackground(data.educationalBackground);
      setErrorLoading(undefined);

      showAlert(data.hasGratuity ? 'hasGratuity' : 'noGratuity');
    } else if (error) {
      setErrorLoading(error.code);
      onError(error.code);
    }
    setLoading(false);
  }, [
    setErrorLoading,
    setLoading,
    showAlert,
    postulationDetailId,
    onError,
    communeOptions,
    regionOptions,
  ]);

  useEffect(() => {
    if (loading) {
      getStudentFileInfo();
    }
  }, [loading, getStudentFileInfo]);

  if (errorLoading) {
    return (
      <DisplayError
        insideCard
        textBody={errorLoading}
        retryAction={() => setLoading(true)}
        loadingAction={loading}
      />
    );
  }
  if (loading) {
    return <Loading insideCard />;
  }
  return (
    <>
      <Alert
        isOpen={isAlertOpen}
        toggle={() => setIsAlertOpen(false)}
        text={alertText}
        icon="information"
        fade
      />
      <Card className="px-4 px-md-5 py-4">
        <Row className="pb-4 pb-md-0">
          <Col xs={12} lg={7} md={8} xl={9}>
            <span className="fs-18 text-primary">{t(`${prefix}.student`)}</span>
            <br />
            <span className="fs-18 text-primary fw-600">
              {backgroundData?.student.fullName}
            </span>
          </Col>
          <Col xs={12} lg={5} md={4} xl={3} className="pt-3 pt-md-0">
            <div className="box-outlined w-100 ml-md-auto w-100">
              <span className="text-secondary fw-700 text-uppercase">
                {t(`${prefix}.admissionType`)}
              </span>
              <br />
              <span className="fs-16 text-secondary">
                {admissionType?.name}
              </span>
            </div>
          </Col>
        </Row>
        <StudentFileForm
          backgroundData={backgroundData}
          educationalBackground={educationalBackground}
          defaultValues={defaultValues}
        />
      </Card>
    </>
  );
};

export default StudentFile;
