import { Campus } from '../../types/campus';
import { MAINTAINERPORTAL } from '../endpoints';
import request from '../request';
import { SearchParamsPagination } from './Filters';
import { PackagesListResponse } from './packages';
import { SchoolsListItem } from './schools';

export enum SectionStudentSummaryStateEnum {
  Passed = 'PASSED',
  FailedGradeAssistance = 'FAILED_GRADE_ASSISTANCE',
  FailedGrade = 'FAILED_GRADE',
  FailedAssistance = 'FAILED_ASSISTANCE',
}

export type Grade = number | string | null;

export interface Student {
  id: number;
  fullName: string;
  run: string | null;
  passportNumber: string | null;
  countryId: string | null;
  grades: {
    testGrades: Record<
      number,
      {
        studentTestGradeId: number;
        grade: Grade;
        testGradeId: number;
      }
    >;
    examGrade: {
      studentFinalGradeId: number;
      grade: Grade;
      finalGradeId: number;
    };
    partialGrade: number;
    finalGrade: string | number | null;
    passedGrade: boolean;
  };

  generalPassed: SectionStudentSummaryStateEnum | null;
  assistances: Record<
    number,
    {
      present: number;
      total: number;
      percentage: number | null;
      minPercentage: number;
      passed: number | null;
    }
  >;
  summary: string;
}

export interface GetSectionsTeacherPortalByIDReponse {
  section: Section;
  students: Student[];
  passingGrade: number;
}

export interface SectionTeacherPortal {
  sectionsClosed: Section[];
  sectionsNotClosed: Section[];
}
export interface Period {
  id: number;
  code: string;
  name: string;
  description: string;
  creditMin: number;
  creditMax: number;
  startDate: Date;
  endDate: Date;
  lastLoadDate: Date | null;
  loadingDeadline: Date | null;
  /**
   * ultima fecha de carga de atributos de secciones
   */
  uploadDateSectionAttributes: Date | null;
  /**
   * fecha de tope carga de atributos de secciones
   */
  sectionAttributesUploadDeadline: Date | null;
  createdAt: Date;
  updatedAt: Date;
  active: boolean;
}

export interface Section {
  id: number;
  name: string;
  maximumStudent: number;
  createdAt: Date;
  updatedAt: Date;
  campusId: number;
  closedAt: Date | null;
  closedWithId: number | null;
  closedWith?: {
    id: number;
    passPercentage: number;
    isSomeStudentPending: boolean;
    createdById: number;
    sectionId: number;
  };
  period: Period;
  assistanceRequirements: Array<{
    id: number;
    minPercentage: number;
    activityTypeId: number;
    sectionId: number;
    createdAt: Date;
    updatedAt: Date;
    activityType: {
      id: number;
      name: string;
      createdAt: Date;
      updatedAt: Date;
    };
  }>;
  course: {
    id: number;
    name: string;
    code: string;
    shortening: string;
    credits: number;
    createdAt: Date;
    updatedAt: Date;
    school: {
      id: number;
      code: string;
      name: string;
      description: string;
      createdAt: Date;
      updatedAt: Date;
      studyPlans: Array<{
        id: number;
        code: string;
        name: string;
        createdAt: Date;
        updatedAt: Date;
      }>;
    };
  };
  professors: Array<{
    id: number;
    createdAt: Date;
    updatedAt: Date;
    account: {
      id: number;
      email: string;
      personalEmail: string | null;
      name: string;
      run: number | null;
      paternalLastName: string;
      maternalLastName: string;
      paternalPreference: boolean;
      birthday: Date;
      lastLogin: Date | null;
      isActive: boolean;
      passportNumber: string | null;
      countryId: string | null;
      studentId: number;
      assistantId: number;
      teacherId: number;
      fullName: string;
    };
  }>;
  campus: {
    id: number;
    code: string;
    name: string;
    addressStreet: string;
    addressNumber: string;
    addressExtra: string | null;
    communeId: number;
    createdAt: Date;
    updatedAt: Date;
  };
  summaries?: Array<{
    id: number;
    passPercentage: number;
    isSomeStudentPending: boolean;
    createdById: number;
    sectionId: number;
  }>;
}

export interface GetPeriodOFMantainerPortalResponse {
  periods: Period[];
  currentPeriod: Period;
}

export interface GetPeriodOFMantainerPortalResponseV2 {
  periods: Period[];
  currentPeriod: Period;
  campuses: { id: number; name: string }[];
  schools: { id: number; name: string }[];
}

export interface GetFiltersMantainerPortalSectionResponse {
  schools: SchoolsListItem[];
  campus: Campus[];
}

export interface GetSectionsOFMantainerPortalResponse {
  data: Section[];
  total_pages: number;
  total: number;
}

export function getPeriodOFMantainerPortal() {
  const url = MAINTAINERPORTAL.GET_PERIOD_MAINTAINER_PORTAL;
  return request<GetPeriodOFMantainerPortalResponse>(url, {
    method: 'get',
  });
}

export function getPeriodOFMantainerPortalV2() {
  const url = MAINTAINERPORTAL.GET_PERIOD_MAINTAINER_PORTAL;
  return request<GetPeriodOFMantainerPortalResponseV2>(url, {
    method: 'get',
  });
}

export function getFiltersMantainerPortalSection() {
  const url = MAINTAINERPORTAL.FILTER_GET_SECTION_BY_PERIOD_ID_AND_STATE;
  return request<GetFiltersMantainerPortalSectionResponse>(url, {
    method: 'get',
  });
}

export function getSectionByPeriodIDAndState({
  page = 0,
  itemsPerPage = 10,
  state,
  periodId,
  schoolId,
  campusId,
  searchSection,
}: {
  state: string;
} & SearchParamsPagination) {
  const url = MAINTAINERPORTAL.GET_SECTION_BY_PERIOD_ID_AND_STATE;
  const params = {
    items_per_page: itemsPerPage,
    page: page,
    state,
    periodId,
    schoolId,
    campusId,
    searchSection,
  };
  return request<GetSectionsOFMantainerPortalResponse>(url, {
    method: 'get',
    params,
  });
}

export const getSectionsTeacherPortalByIDRequest = (sectionID: string) => {
  const url = MAINTAINERPORTAL.GET_SECTION_BY_ID(sectionID);
  return request<GetSectionsTeacherPortalByIDReponse>(url, {
    method: 'get',
  });
};

export const postClosedSectionTeacherPortalRequest = (sectionId: number) => {
  const url = MAINTAINERPORTAL.POST_CLOSED_SECTION(sectionId);
  return request<any>(url, {
    method: 'post',
  });
};

export const postRejectClosureSectionMantainerPortalRequest = (
  sectionId: number,
) => {
  const url = MAINTAINERPORTAL.POST_REJECT_CLOSURE_SECTION(sectionId);
  return request<any>(url, {
    method: 'post',
  });
};

export const getPDFOfSectionClose = (sectionId: number) => {
  const url = MAINTAINERPORTAL.DOWNLOAD_PDF_SECTION_CLOSE(sectionId);
  return request<any>(url, {
    method: 'get',
    responseType: 'blob',
  });
};

export const getPackageOfSection = (periodId: number) => {
  const url = `${MAINTAINERPORTAL.GET_SECTION_PACKAGE}?periodId=${periodId}`;
  return request<PackagesListResponse>(url, {
    method: 'get',
  });
};
