export const moneyFormatter = (
  locale: string = 'es-CL',
  currency: string = 'CLP',
) => {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
  });
};

export const TariffFormatter = (
  value?: number | string,
  data?: {
    id: number;
    currency: string;
    locale: string;
  },
  defaultValue = '',
) => {
  if (value) {
    const numberValue =
      typeof value === 'string' ? Number(value.replace(/\D/g, '')) : value;
    return moneyFormatter(data?.locale, data?.currency).format(numberValue);
  }
  return defaultValue;
};
