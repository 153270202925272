import { AccountType } from '../../types/account';
import { PackageList } from '../../types/packageTypes';
import { Course } from '../../types/parametersTypes';
import { Teacher } from '../../types/sectionMaintainerTypes';
import { PARAMETERS } from '../endpoints';
import request from '../request';

export const COUNTRY_CHILE_ID = 'cl';

/**
 * Obtiene listado de países
 */
export const getCountriesRequest = () => {
  return request<{ id: string; name: string; nationality: string }[]>(
    PARAMETERS.COUNTRIES,
  );
};

/**
 * Obtiene listado de países
 */
export const getIntitutionTypesRequest = () => {
  return request<{ data: { id: string; name: string; description: string }[] }>(
    PARAMETERS.INSTITUTION_TYPES,
  );
};

/**
 * Obtiene listado con los profesores en base a un texto de búsqueda
 * @param searchText
 * @returns
 */
export const getTeachersRequest = (searchText: string) => {
  return request<{ data: Teacher[] }>(PARAMETERS.SEARCH_TEACHERS, {
    params: { search_text: searchText },
  });
};

/**
 * Obtiene listado con los ayudantes en base a un texto de búsqueda
 * @param searchText
 * @returns
 */
export const getAssistantsRequest = (searchText: string) => {
  return request<{ data: Teacher[] }>(PARAMETERS.SEARCH_ASSISTANT, {
    params: { search_text: searchText },
  });
};

/**
 * Obtiene listado con los responsables (docente o ayudante) en base a un texto de búsqueda
 * @param searchText
 * @returns
 */
export const getResponsiblesRequest = (searchText: string) => {
  return request<{ data: AccountType[] }>(PARAMETERS.SEARCH_RESPONSIBLE, {
    params: { search_text: searchText },
  });
};

/**
<<<<<<< HEAD
 * Obtiene listado con los paquetes en base a un texto de búsqueda pertenecientes a un periodo específico
 * @param searchText
 * @returns
 */
export const getPackagesByPeriodIdRequest = (
  periodId: number,
  sectionId?: string,
) => {
  const params = sectionId ? `?sectionId=${sectionId}` : '';
  return request<PackageList[]>(PARAMETERS.SEARCH_PACKAGES(periodId) + params);
};

/**
 * Obtiene listado de todas las asignaturas con su facultad que concuerdan con la búsqueda
 * @param searchText
 * @returns
 */
export const getCoursesRequest = (searchText: string) => {
  return request<Course[]>(PARAMETERS.GET_COURSES(searchText));
};

/**
 * Obtiene todos los tipos de actividades que puede tener una asignatura
 * (EJ: Cátedra, Ayudantía, Laboratorio...)
 * @returns
 */
export const getActivitiesRequest = () => {
  return request<
    {
      id: number;
      name: string;
      createdAt: Date;
      updatedAt: Date;
    }[]
  >(PARAMETERS.ACTIVITIES);
};
