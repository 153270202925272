import { Button, Icon, Modal } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import styles from './PeriodCantCreateAcademicCalendarModal.module.scss';

export interface PeriodCantCreateAcademicCalendarModalProps {
  open?: boolean;
  onClose: () => void;
}
const PeriodCantCreateAcademicCalendarModal = (
  props: PeriodCantCreateAcademicCalendarModalProps,
) => {
  const { open = false, onClose } = props;

  const { t } = useTranslation();
  const prefix = 'maintainers.periodsForm';

  return (
    <Modal
      isOpen={open}
      toggle={() => {
        onClose();
      }}
      size="md"
    >
      <div className="d-flex flex-column align-items-center">
        <Icon name="warning" size="52px" />
        <div
          className={`${styles.cantCreateEventsTitle} mt-4 fw-600 text-black fs-22 text-center`}
        >
          {t(`${prefix}.cantCreateEventsTitle`)}
        </div>
        <div
          className={`${styles.cantCreateEventsSubtitle} mx-auto mt-4 mb-5 fs-16 text-center`}
        >
          {t(`${prefix}.cantCreateEventsSubtitle`)}
        </div>
        <Button
          type="button"
          text={t(`common.actions.ok`)}
          fullwidth
          onClick={() => onClose()}
        />
      </div>
    </Modal>
  );
};

export default PeriodCantCreateAcademicCalendarModal;
