import {
  addToast,
  Button,
  Icon,
  Modal,
  SelectOptionType,
} from '@octano/global-ui';
import clsx from 'clsx';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CampusListResponse } from '../../api/requests/campus';
import { useLayoutState } from '../../hooks/useLayoutState';
import CampusForm, { RecordToEditBody } from './CampusForm';
import { AxiosResult, AxiosResultDefaultError } from '../../api/request';
import { PermissionName } from '../../types/Auth';
import useUserState from '../../hooks/useUserState';

export interface Campus {
  code: string;
  name: string;
  addressStreet: string;
  addressNumber: string;
  addressExtra?: string;
  commune: SelectOptionType | null;
  region: SelectOptionType | null;
}

interface CampusModalProps {
  isCreate?: boolean;
  reloadCampusList: () => void;
  recordToEdit?: RecordToEditBody;
}

const getTranslationsTexts = (isEditionMode: boolean) => {
  const translationPreffix = 'maintainers.campusForm';
  return isEditionMode
    ? {
        create: 'common.actions.saveChanges',
        success: 'common.messages.editSuccess',
        formTitle: 'maintainers.formEditTitle',
      }
    : {
        create: `${translationPreffix}.create`,
        success: `${translationPreffix}.createSuccess`,
        formTitle: `${translationPreffix}.addTitle`,
      };
};

const CampusModal = ({
  isCreate,
  recordToEdit,
  reloadCampusList,
}: CampusModalProps) => {
  const { isAuthorizedTo } = useUserState();
  const { t } = useTranslation();
  const { showErrorModal } = useLayoutState();

  const [showCampusForm, setShowCampusForm] = useState<boolean>(false);
  const isEditionMode = !!recordToEdit;
  const translationPreffix = 'maintainers.campusForm';

  const texts = getTranslationsTexts(isEditionMode);

  const onSubmit = async (
    result: AxiosResult<CampusListResponse, AxiosResultDefaultError>,
  ) => {
    const { error, data } = result;
    if (error && error.code === 'CONNECTION') {
      showErrorModal();
    } else if (error) {
      addToast({
        icon: 'error',
        color: 'danger',
        text: t('common.errors.save'),
      });
    } else if (data) {
      closeModal(true);
      addToast({
        icon: 'success',
        color: 'success',
        text: t(texts.success),
      });
    }
  };

  const handleEditClick = () => {
    if (isAuthorizedTo([PermissionName.EDIT_CAMPUS])) {
      setShowCampusForm(true);
    }
  };

  const closeModal = (reloadTable?: boolean) => {
    if (reloadTable) {
      reloadCampusList();
    }
    setShowCampusForm(false);
  };

  return (
    <>
      {isCreate ? (
        <Button
          text={t(`${translationPreffix}.addTitle`)}
          type="button"
          size="sm"
          icon="plus"
          className="g-add-button mb-3 mt-3"
          onClick={() => setShowCampusForm(true)}
          disabled={!isAuthorizedTo([PermissionName.CREATE_CAMPUS])}
        />
      ) : (
        <span className="cursor-pointer" onClick={handleEditClick}>
          <Icon
            name="edit"
            key="edit"
            color="secondary"
            className={clsx(
              !isAuthorizedTo([PermissionName.EDIT_CAMPUS]) && 'icon-disabled',
            )}
          />
        </span>
      )}
      <Modal
        isOpen={showCampusForm}
        toggle={() => {
          setShowCampusForm(!showCampusForm);
        }}
        size="lg"
        unmountOnClose
      >
        <CampusForm
          recordToEdit={recordToEdit}
          onSubmit={onSubmit}
          onCancel={closeModal}
        />
      </Modal>
    </>
  );
};
export default CampusModal;
