import {
  Button,
  Icon,
  IconColorType,
  IconPaths,
  SelectOptionType,
  Table,
} from '@octano/global-ui';
import {
  CellFormatOptions,
  PaginationType,
} from '@octano/global-ui/dist/components/Table/types/TableTypes';
import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import Select, { SingleValueProps } from 'react-select';
import {
  VersionValues,
  changeStatusVersion,
  getVersionsRequest,
} from '../../api/requests/versions';
import DisplayError from '../../components/info/DisplayError';
import TableEmptyContent from '../../components/text/TableEmptyContent';
import { useLoadingState } from '../../hooks/useLoadingState';
import useUserState from '../../hooks/useUserState';
import { PermissionName } from '../../types/Auth';
import {
  CURRICULUM_STATUS,
  CurriculumTypeFull,
} from '../../types/CurriculumTypes';
import { VersionTypes } from '../../types/VersionTypes';
import { PaginationRequestType } from '../../types/paginationRequestType';
import VersionStatusModal from './VersionStatusModal';

// interface para los props del componente
export interface VersionListTypeData<T = number> {
  planStudy: {
    id: T;
  };
}

// Enum Status
enum Status {
  Borrador = 'Borrador',
  Activo = 'Activo',
  Vigente = 'Vigente',
  Cerrado = 'Cerrado',
}

// valores dummy del select
const optionsSelect: SelectOptionType[] = [
  { value: Status.Borrador, label: Status.Borrador },
  { value: Status.Activo, label: Status.Activo },
  { value: Status.Vigente, label: Status.Vigente },
  { value: Status.Cerrado, label: Status.Cerrado },
];

// valores iniciales para la paginacion
const ITEMS_PER_PAGE_DEFAULT = 10;
const PAGE_INITIAL_DEFAULT = 1;

type statusColorType = { [key: string]: IconColorType };
type statusIconType = {
  [key: string]: keyof typeof IconPaths;
};
const statusColor: statusColorType = {
  Borrador: 'danger',
  Activo: 'primary',
  Vigente: 'primary',
  Cerrado: 'danger',
};
const statusIcon: statusIconType = {
  Borrador: 'warning',
  Activo: 'success',
  Vigente: 'success',
  Cerrado: 'error',
};

const isStatusEnum = (b: string): b is Status => {
  return (b as Status) !== undefined;
};

const IconOption = (props: SingleValueProps<any>) => {
  if (isStatusEnum(props.data.value)) {
    return (
      <div>
        <Icon
          className={'icon-select'}
          color={statusColor[props.data.value]}
          name={statusIcon[props.data.value]}
          size="15px"
        />
        <span>{props.data.label}</span>
      </div>
    );
  }
  return <></>;
};

const VersionList = (props: VersionListTypeData) => {
  const { t } = useTranslation();
  const { isAuthorizedTo } = useUserState();

  const history = useHistory();

  // mostrar modal
  const [modalToShow, setModalToShow] = useState<string | null>(null);
  const [subTitleModal, setSubTitleModal] = useState<string>('');
  const [reload, setReload] = useState<boolean>(false);
  // data para la  tabla
  const [versionListData, setVersionListData] = useState<
    PaginationRequestType<VersionTypes>
  >({
    data: [],
    total_pages: 0,
    total: 0,
  });

  // actualizacion de la paginacion
  const [currentPage, setCurrentPage] = useState<number>(PAGE_INITIAL_DEFAULT);
  const [itemsPerPage] = useState<number>(ITEMS_PER_PAGE_DEFAULT);

  // loading
  const { loading, setLoading, errorLoading, setErrorLoading } =
    useLoadingState();

  // obtener la lista de versiones
  const getVersionList = useCallback(async () => {
    const { error, data: response } = await getVersionsRequest(
      props.planStudy.id,
      itemsPerPage,
      currentPage - 1,
    );
    if (response) {
      setVersionListData(response);
      setErrorLoading(undefined);
    } else if (error) {
      setErrorLoading(error.code);
      return;
    }
    setLoading(false);
  }, [props, itemsPerPage, currentPage, setErrorLoading, setLoading]);

  const handlerOnChangePage = useCallback(
    async (page: number) => setCurrentPage(page),
    [],
  );

  const pagination = useMemo<PaginationType | undefined>(() => {
    if (versionListData.total > itemsPerPage) {
      return {
        totalItems: versionListData.total,
        itemsPerPage: itemsPerPage,
        totalPages: versionListData.total_pages,
        currentPage: currentPage,
        onChangePage: handlerOnChangePage,
      };
    }
  }, [versionListData, itemsPerPage, currentPage, handlerOnChangePage]);

  //button create version
  const createVersion = useCallback(() => {
    history.push(`${props.planStudy.id}/version/create`);
  }, [props, history]);

  const goToCreateCurriculum = useCallback(
    (id: string) => {
      history.push(`${props.planStudy.id}/version/${id}/curriculum/create`);
    },
    [props, history],
  );

  const goToEditCurriculum = useCallback(
    (id: string, primaryCurriculum: CurriculumTypeFull | undefined) => {
      if (primaryCurriculum) {
        history.push(
          `${props.planStudy.id}/version/${id}/curriculum/${primaryCurriculum.id}`,
        );
      } else {
        history.push(`${props.planStudy.id}/version/${id}/mention/list`);
      }
    },
    [props, history],
  );

  const openModal = () => {
    setModalToShow('open');
  };

  // validacion del modal para abrir el modal
  const changeStatus = useCallback(
    async (
      value: SelectOptionType | undefined,
      row: VersionValues,
      newValue: { label: string; value: any },
    ) => {
      const { error } = await changeStatusVersion(row.id, {
        newStatus: newValue.value,
      });

      if (error) {
        if (error.code === 'no_curriculum_created') {
          setSubTitleModal(t('studyPlans.versionList.modalSubtitle'));
          openModal();
        }

        if (error.code === 'no_curriculum_active') {
          setSubTitleModal(t('studyPlans.versionList.modalSubtitleActive'));
          openModal();
        }

        if (error.code === 'other_version_active') {
          setSubTitleModal(
            t('studyPlans.versionList.modalSubtitleActiveOther'),
          );
          openModal();
        }
      } else {
        setReload(!reload);
        setLoading(true);
      }
    },
    [reload, setReload, setLoading, t],
  );

  // columnas para las tablas
  const columns = [
    {
      columnName: 'name',
      headerText: t('studyPlans.versionList.name_version'),
      width: '20%',
    },
    {
      columnName: 'createdAt',
      headerText: t('studyPlans.versionList.createdAt'),
      width: '10%',
      cellFormat: (options: CellFormatOptions) =>
        dayjs(options.value).format('DD/MM/YYYY'),
    },
    {
      columnName: 'code',
      headerText: t('studyPlans.versionList.code'),
      width: '20%',
    },
    {
      columnName: 'curriculums',
      headerText: t('studyPlans.versionList.mentions'),
      width: '10%',
      cellFormat: (options: CellFormatOptions) => {
        const mentions: CurriculumTypeFull[] = options.value;

        if (mentions.some((item: CurriculumTypeFull) => item.isPrimary)) {
          return <div>0</div>;
        } else {
          return <div>{mentions.length}</div>;
        }
      },
    },
    {
      columnName: 'status',
      headerText: t('studyPlans.versionList.status'),
      width: '20%',
      cellFormat: (options: CellFormatOptions) => {
        const value = optionsSelect.find(
          (select) =>
            select.value.toString().toLowerCase() ===
            options.value.toLowerCase(),
        );
        return (
          <Select
            options={optionsSelect}
            styles={{
              indicatorSeparator: () => ({}),
              container: (styles) => ({
                ...styles,
                width: '50%',
                margin: '0 auto',
              }),
            }}
            className="react-select"
            classNamePrefix="react-select"
            onChange={(newValue) => changeStatus(value, options.row, newValue)}
            value={value}
            components={{ SingleValue: IconOption }}
            isDisabled={!isAuthorizedTo([PermissionName.EDIT_STATUS_VERSION])}
          />
        );
      },
    },
    {
      columnName: 'id',
      headerText: t('studyPlans.versionList.resume'),
      width: '20%',
      cellFormat: (options: CellFormatOptions) => {
        const row: VersionValues = options.row;
        const mentions = row.curriculums || [];
        const primaryCurriculum = mentions.find(
          (item: CurriculumTypeFull) => item.isPrimary,
        );

        if (!mentions.length) {
          return (
            <div className="justify-content-center">
              <Button
                type="button"
                text={t('studyPlans.versionList.createResume')}
                size="sm"
                outlined
                fullwidth
                color="primary"
                onClick={() => goToCreateCurriculum(options?.value)}
                disabled={!isAuthorizedTo([PermissionName.CREATE_MALLA])}
              />
            </div>
          );
        } else {
          return (
            <div className="justify-content-center">
              <Button
                type="button"
                text={t('studyPlans.versionList.editResume')}
                size="sm"
                outlined
                fullwidth
                color="primary"
                onClick={() =>
                  goToEditCurriculum(options?.value, primaryCurriculum)
                }
                disabled={
                  (primaryCurriculum &&
                    row.status.toLowerCase() !== CURRICULUM_STATUS.borrador) ||
                  !isAuthorizedTo([PermissionName.EDIT_MALLA])
                }
              />
            </div>
          );
        }
      },
    },
    {
      columnName: 'id',
      headerText: '',
      width: '40px',
      cellFormat: (options: CellFormatOptions) => {
        const row: VersionValues = options.row;
        const mentions = row.curriculums || [];
        const primaryCurriculum = mentions.find(
          (item: CurriculumTypeFull) => item.isPrimary,
        );
        let url = '';

        if (primaryCurriculum) {
          url = `${props.planStudy.id}/version/${options.value}/curriculum/${primaryCurriculum.id}/view`;
        } else {
          url = `${props.planStudy.id}/version/${options.value}/mention/list`;
        }

        if (!mentions.length) {
          return <></>;
        } else {
          return (
            <Link to={url}>
              <Icon className="custom-eye" name="eye" key="eye" size="22px" />
            </Link>
          );
        }
      },
    },
  ];

  useEffect(() => {
    getVersionList();
  }, [getVersionList, reload]);

  if (errorLoading) {
    return (
      <DisplayError
        insideCard
        textBody={errorLoading}
        retryAction={() => setLoading(true)}
        loadingAction={loading}
      />
    );
  }
  if (loading) {
    return <></>;
  }

  return (
    <div className="g-table-container padding-none">
      <Button
        text={t('studyPlans.versionList.createVersion')}
        type="button"
        size="sm"
        icon="plus"
        className="g-add-button mb-3 mt-3"
        onClick={createVersion}
        disabled={!isAuthorizedTo([PermissionName.CREATE_STUDY_PLAN_VERSION])}
      />
      <div id={'version-list-table'}>
        <Table
          columns={columns}
          data={versionListData.data}
          pagination={pagination}
          noResultsText={
            <TableEmptyContent
              title={t('studyPlans.versionList.TableEmptyTitle')}
              subtitle={t('studyPlans.versionList.TableEmptySubtitle')}
            />
          }
        />
      </div>
      <VersionStatusModal
        show={!!modalToShow}
        onCloseModal={() => setModalToShow(null)}
        title={t('studyPlans.versionList.modalTitle')}
        subtitle={subTitleModal}
      />
    </div>
  );
};

export default VersionList;
