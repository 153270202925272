import { Box, Button } from '@octano/global-ui';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Card } from 'reactstrap';
import { getCompleted } from '../../../../api/requests/tuitionProcess';
import { ReactComponent as CompletedImage } from '../../../../assets/svg/completed_image.svg';
import DisplayError from '../../../../components/info/DisplayError';
import Loading from '../../../../components/info/Loading';
import { useLoadingState } from '../../../../hooks/useLoadingState';
import { useTuitionProcess } from '../../TuitionProcessContext';

const Completed = () => {
  const {
    prefix: translationPrefix,
    postulationDetailId,
    onError,
  } = useTuitionProcess();
  const prefix = `${translationPrefix}.completed`;
  const { t } = useTranslation();
  const { loading, setLoading, errorLoading, setErrorLoading } =
    useLoadingState();
  const [studentEmail, setStudentEmail] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getStatus = useCallback(async () => {
    const { data, error } = await getCompleted(postulationDetailId);
    if (data) {
      setErrorLoading(undefined);
      setStudentEmail(data.studentEmail);
    } else if (error) {
      setErrorLoading(error.code);
      onError(error.code);
    }
    setLoading(false);
  }, [setErrorLoading, setLoading, onError, postulationDetailId]);

  useEffect(() => {
    getStatus();
  }, [getStatus]);

  const history = useHistory();

  if (errorLoading) {
    return (
      <DisplayError
        insideCard
        textBody={errorLoading}
        retryAction={() => setLoading(true)}
        loadingAction={loading}
      />
    );
  }
  if (loading) {
    return <Loading insideCard />;
  }
  return (
    <>
      <Card className="px-4 px-md-5 py-4">
        <div
          className="d-flex py-4 flex-column align-items-center m-auto"
          style={{ maxWidth: '694px', gap: 24 }}
        >
          <CompletedImage width="230px" />
          <span className="text-center fs-20 text-dark">
            {t(`${prefix}.title`)}
          </span>
          <span className="text-center fs-18 fw-300">
            {t(`${prefix}.body`)}
          </span>
          <Box
            variant="standard"
            title={t(`${prefix}.email`)}
            body={studentEmail}
          />
          <span className="mt-4">
            <Button
              color="primary"
              size="lg"
              text={t(`${prefix}.backToTuitionProcessOnSite`)}
              onClick={() => {
                history.push('/tuition-process');
              }}
            />
          </span>
        </div>
      </Card>
    </>
  );
};

export default Completed;
