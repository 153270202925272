import { ReactComponent as CampusLogo } from '../../assets/campus/logo_expanded.svg';
import { ReactComponent as AddemicLogo } from '../../assets/addemic/logo_expanded.svg';
import { IS_ADDEMIC } from '../../config/constants';

export const CampusFooter = () => {
  return (
    <div className="d-flex justify-content-end pt-4 pb-3 pr-3">
      {IS_ADDEMIC ? <AddemicLogo width={130} /> : <CampusLogo width={140} />}
    </div>
  );
};
