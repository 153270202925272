import { ReactElement, useCallback } from 'react';
import { DefaultValues } from 'react-hook-form';
import {
  AuthenticationError,
  getSustainer,
} from '../../../../api/requests/tuitionProcess';
import DisplayError from '../../../../components/info/DisplayError';
import Loading from '../../../../components/info/Loading';
import { useParameters } from '../../../../hooks/useParameters';
import { createFetchContext } from '../../../../components/contexts/FetchContextFactory';

import {
  DataOwnSustainer,
  SustainerStepResponse,
} from '../../../../types/sustainerTypes';
import { FieldsSustainerForm } from './SustainerForm';
import { formatDataOwnSustainer, formatDefaultValues } from './utils';
import { AxiosResultDefaultError } from '../../../../api/request';

export interface SustainerLoaderProps {
  postulationDetailId: string | number;
  onError: (code: string, onUnknown?: (() => void) | undefined) => void;
  children: (props: {
    sustainerPassportAvailable: boolean;
    studentFullName: string;
    defaultValues: DefaultValues<FieldsSustainerForm>;
    ownSustainer: DataOwnSustainer;
    refresh: () => Promise<void>;
  }) => ReactElement;
}

const { FetchProvider, FetchConsumer } = createFetchContext<
  undefined,
  SustainerStepResponse,
  AuthenticationError | AxiosResultDefaultError
>();

export const SustainerLoaderConsumer = FetchConsumer;

export default function SustainerLoader({
  postulationDetailId,
  onError,
  children,
}: SustainerLoaderProps) {
  const { communeOptions, regionOptions, countryOptions, nationalityOptions } =
    useParameters();

  const request = useCallback(async () => {
    return getSustainer(postulationDetailId);
  }, [postulationDetailId]);

  console.log('SustainerLoader', {
    communeOptions,
    regionOptions,
    countryOptions,
    nationalityOptions,
  });

  return (
    <FetchProvider request={request} defaultQuery={undefined} fetchImmediately>
      <FetchConsumer>
        {({ data, loading, error, refresh }) => {
          if (error) {
            onError(error.code);
            return (
              <DisplayError
                insideCard
                textBody={error.code}
                retryAction={refresh}
                loadingAction={loading}
              />
            );
          }

          if (loading) {
            return <Loading insideCard />;
          }

          if (!data) {
            return (
              <DisplayError
                insideCard
                textBody="Data no cargada"
                retryAction={refresh}
                loadingAction={loading}
              />
            );
          }

          return children({
            sustainerPassportAvailable:
              data.sustainerPassportAvailable ?? false,
            studentFullName: data.studentFullName,
            defaultValues: formatDefaultValues(
              data,
              communeOptions,
              regionOptions,
              countryOptions,
              nationalityOptions,
            ),
            ownSustainer: formatDataOwnSustainer(
              data,
              communeOptions,
              regionOptions,
              countryOptions,
              nationalityOptions,
            ),
            refresh,
          });
        }}
      </FetchConsumer>
    </FetchProvider>
  );
}
