import { CheckInput, Select, TextInput } from '@octano/global-ui';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { SectionTitle } from '../../../../components/text';
import { useParameters } from '../../../../hooks/useParameters';
import { useValidations } from '../../../../hooks/useValidations';
import {
  maritalStatusOptions,
  relationshipOptions,
  RELATIONSHIP_OWN_SUSTAINER,
} from '../../../../utils/selectOptions';
import { useTuitionProcess } from '../../TuitionProcessContext';
import { FieldsSustainerForm } from './SustainerForm';

const PersonalInformation = ({
  sustainerPassportAvailable,
  postulantHasPassport,
}: {
  sustainerPassportAvailable: boolean;
  postulantHasPassport: boolean;
}) => {
  const { control, watch } = useFormContext<FieldsSustainerForm>();
  const { prefix: translationPrefix } = useTuitionProcess();
  const prefix = `${translationPrefix}.sustainer.personalInformation`;
  const { t } = useTranslation();
  const { msgValidations, validateTextNotEmpty, validateRut } =
    useValidations();
  const ownSustainer = watch('ownSustainer');
  const { countryOptions, nationalityOptions } = useParameters();

  const filteredRelationshipOptions = useMemo(
    () =>
      relationshipOptions.filter(
        (row) =>
          (ownSustainer && row.value === RELATIONSHIP_OWN_SUSTAINER) ||
          row.value !== RELATIONSHIP_OWN_SUSTAINER,
      ),
    [ownSustainer],
  );

  const hasPassport = watch('hasPassport');

  const requestPassport = useMemo(() => {
    if (!sustainerPassportAvailable) {
      return false;
    }
    return ownSustainer ? postulantHasPassport : hasPassport;
  }, [
    sustainerPassportAvailable,
    ownSustainer,
    postulantHasPassport,
    hasPassport,
  ]);

  return (
    <>
      <Row>
        <Col xs={12}>
          <SectionTitle text={t(`${prefix}.title`)} />
        </Col>
        <Col xs={12} className="pb-4">
          <span className="fs-18 text-light fw-300">
            {t(`${prefix}.subtitle`)}
          </span>
          {postulantHasPassport && (
            <span className="fs-18 text-light fw-300">
              {t(`${prefix}.postulantWithPassport`)}
            </span>
          )}
        </Col>
      </Row>
      <Row>
        <Col className="pb-3" xs={12}>
          {!postulantHasPassport && (
            <CheckInput
              name="ownSustainer"
              label={t(`${prefix}.ownSustainer`)}
              control={control}
            />
          )}
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <Select
            name="relationship"
            label={t(`${prefix}.relationship`)}
            options={filteredRelationshipOptions}
            isSearchable={false}
            control={control}
            rules={{ required: msgValidations.required }}
            disabled={ownSustainer === true}
          />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <TextInput
            name="occupation"
            label={t(`${prefix}.occupation`)}
            control={control}
            rules={{ validate: validateTextNotEmpty }}
          />
        </Col>

        <Col className="pb-3" xs={12} lg={4}>
          <TextInput
            name="names"
            label={t(`${prefix}.names`)}
            control={control}
            rules={{ validate: validateTextNotEmpty }}
            disabled={ownSustainer === true}
          />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <TextInput
            name="paternalLastName"
            label={t(`${prefix}.paternalLastName`)}
            control={control}
            rules={{ validate: validateTextNotEmpty }}
            disabled={ownSustainer === true}
          />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <TextInput
            name="maternalLastName"
            label={t(`${prefix}.maternalLastName`)}
            control={control}
            rules={{ validate: validateTextNotEmpty }}
            disabled={ownSustainer === true}
          />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <Row>
            {!requestPassport ? (
              <Col xs={12}>
                <TextInput
                  name="rut"
                  label={t(`${prefix}.rut`)}
                  control={control}
                  formatter="rut"
                  rules={{
                    validate: { empty: validateTextNotEmpty, rut: validateRut },
                  }}
                  shouldUnregister={true}
                  disabled={ownSustainer === true}
                />
              </Col>
            ) : (
              <>
                <Col xs={12} md={6} className="pr-md-1">
                  <Select
                    name="passportCountry"
                    label={t(`${prefix}.passportCountry`)}
                    control={control}
                    options={countryOptions}
                    shouldUnregister={true}
                    isClearable={false}
                    disabled={ownSustainer === true}
                  />
                </Col>
                <Col xs={12} md={6} className="pl-md-1">
                  <TextInput
                    name="passportNumber"
                    label={t(`${prefix}.passportNumber`)}
                    control={control}
                    shouldUnregister={true}
                    disabled={ownSustainer === true}
                  />
                </Col>
              </>
            )}
            {sustainerPassportAvailable && !ownSustainer && (
              <Col xs={12} className="mb-n5">
                <CheckInput
                  name="hasPassport"
                  label={t(`${prefix}.checkPassport`)}
                  control={control}
                />
              </Col>
            )}
          </Row>
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <Select
            name="nationality"
            label={t(`${prefix}.nationality`)}
            control={control}
            options={nationalityOptions}
            isSearchable
            rules={{ required: msgValidations.required }}
            disabled={ownSustainer === true}
          />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <Select
            name="maritalStatus"
            label={t(`${prefix}.maritalStatus`)}
            options={maritalStatusOptions}
            isSearchable={false}
            control={control}
            rules={{ required: msgValidations.required }}
            disabled={ownSustainer === true}
          />
        </Col>
      </Row>
    </>
  );
};

export default PersonalInformation;
