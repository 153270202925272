import { ReactElement } from 'react';
import AdmissionStatusListProvider from '../contexts/AdmissionStatusListContext';
import { AdmissionsListParamId } from './AdmissionsListParamId';

export interface AdmissionsListProps {
  status:
    | 'en_proceso'
    | 'matriculado'
    | 'en_espera'
    | 'desistido'
    | 'retractado';
  children: ReactElement;
}

export default function AdmissionsList({
  status,
  children,
}: AdmissionsListProps) {
  return (
    <AdmissionsListParamId>
      {(academicOfferStudyPlanVersionOffersId) => (
        <AdmissionStatusListProvider
          academicOfferStudyPlanVersionOffersId={
            academicOfferStudyPlanVersionOffersId
          }
          status={status}
          fetchImmediately
        >
          {children}
        </AdmissionStatusListProvider>
      )}
    </AdmissionsListParamId>
  );
}
