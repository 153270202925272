import { Button, Modal, Icon } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import {
  CourseInfo,
  COURSE_TYPES,
  LegendInfo,
} from '../../types/CurriculumTypes';

interface CurriculumSemesterDeleteModalProps {
  show: boolean;
  onClose: Function;
  semesterIndex: number;
  semesterCount: number;
  legend: LegendInfo;
  setLegend: (legend: LegendInfo) => void;
  setCoursesList: (courseInfo: CourseInfo[]) => void;
  setSemesterCount: (semesterCount: number) => void;
  setSemesterInputToValue: (index: number) => void;
  coursesList: CourseInfo[];
}

export const CurriculumSemesterDeleteModal = ({
  show,
  onClose,
  semesterIndex,
  setCoursesList,
  coursesList,
  semesterCount,
  setSemesterCount,
  setSemesterInputToValue,
  legend,
  setLegend,
}: CurriculumSemesterDeleteModalProps) => {
  const { t } = useTranslation();
  const prefix = 'studyPlans.curriculum.deleteSemesterModal';

  const closeModal = () => {
    onClose();
  };

  const deleteCourses = () => {
    const newCoursesList = coursesList.filter(
      (course) => course.semester <= semesterIndex,
    );
    setCoursesList(newCoursesList);
    setSemesterCount(semesterIndex);
    let newLegend: LegendInfo = {
      total: 0,
      totalNeeded: legend.totalNeeded,
      required: 0,
      requiredNeeded: legend.requiredNeeded,
      optional: 0,
      optionalNeeded: legend.optionalNeeded,
      other: 0,
      otherNeeded: legend.otherNeeded,
      activities: 0,
      activitiesNeeded: legend.activitiesNeeded,
    };
    newCoursesList.forEach((course) => {
      if (course.type === COURSE_TYPES.OBLIGATORIO.alias) {
        newLegend = {
          ...newLegend,
          total: newLegend.total + parseInt(course.credits.toString()),
          required: newLegend.required + parseInt(course.credits.toString()),
        };
      } else if (course.type.includes('Optativo')) {
        newLegend = {
          ...newLegend,
          total: newLegend.total + parseInt(course.credits.toString()),
          optional: newLegend.optional + parseInt(course.credits.toString()),
        };
      } else if (course.type === COURSE_TYPES.OTROS.alias) {
        newLegend = {
          ...newLegend,
          total: newLegend.total + parseInt(course.credits.toString()),
          other: newLegend.other + parseInt(course.credits.toString()),
        };
      } else if (course.type === COURSE_TYPES.ACTIVIDAD.alias) {
        newLegend = {
          ...newLegend,
          activities: newLegend.activities + 1,
        };
      }
    });

    setLegend(newLegend);
    closeModal();
  };

  const cancel = () => {
    closeModal();
    setSemesterInputToValue(semesterCount);
  };

  return (
    <Modal isOpen={show} toggle={cancel} size="md">
      <div className="error-modal">
        <Row className="mb-4">
          <Col className="text-center">
            <Icon color="secondary" name="warning" size="50" />
          </Col>
        </Row>
        <Row className="mb-4">
          <Col className="text-center tittle">
            {t(`${prefix}.confirmMessage`)}
          </Col>
        </Row>
        <Row className="mb-4">
          <Col className="text-center desc">{t(`${prefix}.descMessage`)}</Col>
        </Row>
        <Row>
          <Col xs={12} md={6} className="mt-4">
            <Button
              type="button"
              text={t(`${prefix}.cancel`)}
              onClick={cancel}
              fullwidth
              outlined
            />
          </Col>
          <Col xs={12} md={6} className="mt-4">
            <Button
              type="button"
              text={t(`${prefix}.confirm`)}
              onClick={deleteCourses}
              fullwidth
            />
          </Col>
        </Row>
      </div>
    </Modal>
  );
};
