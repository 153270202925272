import { useState } from 'react';
import { addToast, Button, Icon, Modal, useMobile } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import { requestCourseRegistrationDeletion } from '../../../api/requests/courseRegistration';

interface Props {
  isOpen: boolean;
  enrrollmentData: {
    accountId: number;
    sectionId: number;
  };
  onSave?: () => void;
  onCancel?: () => void;
  onRequestError?: () => void;
}

export default function ModalConfirmDeleteRegistration({
  isOpen,
  enrrollmentData,
  onSave = () => null,
  onCancel = () => null,
  onRequestError = () => null,
}: Props) {
  const { t } = useTranslation();
  const prefix = 'courseRegistrationList.detail.modalConfirmDeleteRegistration';
  const isMobile = useMobile();
  const [hasEnoughCredits, setHasEnoughCredits] = useState(true);

  const deleteSectionCourseRegistration = async () => {
    const { error } = await requestCourseRegistrationDeletion({
      accountId: enrrollmentData.accountId,
      sectionId: enrrollmentData.sectionId,
      ignoreWarning: !hasEnoughCredits,
    });

    if (error) {
      if (error.data.message.includes('MIN_CREDITS')) {
        return setHasEnoughCredits(false);
      }

      addToast({
        icon: 'error',
        color: 'danger',
        text: t(`${prefix}.errorDeleting`),
      });

      onRequestError();
    } else {
      addToast({
        icon: 'success',
        color: 'success',
        text: t(`${prefix}.successfullyDeleted`),
      });
      onSave();
    }
  };

  return (
    <>
      <Modal isOpen={isOpen && hasEnoughCredits} toggle={() => null}>
        <div className="d-flex flex-column justify-content-center align-items-center text-center">
          <Icon name="warning" size={45} color="primary" />
          <h1 className="text-dark fs-20 fw-600 mt-4">
            {t(`${prefix}.basicConfirmation.title`)}
          </h1>
          <p className="text-medium mt-4 fs-16" style={{ maxWidth: 315 }}>
            {t(`${prefix}.basicConfirmation.description`)}
          </p>
          <div
            className="d-flex flex-wrap justify-content-between mt-4"
            style={{ gap: 20 }}
          >
            <Button
              text={t(`common.actions.cancel`)}
              outlined
              style={{ minWidth: 200 }}
              onClick={() => onCancel()}
              className={isMobile ? 'w-100' : ''}
            />
            <Button
              text={t(`${prefix}.basicConfirmation.dangerBtn`)}
              color={'danger'}
              style={{ minWidth: 200 }}
              onClick={() => deleteSectionCourseRegistration()}
              className={isMobile ? 'w-100' : ''}
            />
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={isOpen && !hasEnoughCredits}
        toggle={() => null}
        onClosed={() => setHasEnoughCredits(true)}
      >
        <div className="d-flex flex-column justify-content-center align-items-center text-center">
          <Icon name="warning" size={45} color="primary" />
          <h1 className="text-dark fs-20 fw-600 mt-4">
            {t(`${prefix}.minCreditsConfirmation.title`)}
          </h1>
          <p className="text-medium mt-4 fs-16" style={{ maxWidth: 315 }}>
            {t(`${prefix}.minCreditsConfirmation.description`)}
          </p>
          <div
            className="d-flex justify-content-between mt-4"
            style={{ gap: 20 }}
          >
            <Button
              text={t(`common.actions.cancel`)}
              outlined
              style={{ minWidth: 200 }}
              onClick={() => onCancel()}
            />
            <Button
              text={t(`common.actions.confirm`)}
              color={'primary'}
              style={{ minWidth: 200 }}
              onClick={() => deleteSectionCourseRegistration()}
            />
          </div>
        </div>
      </Modal>
    </>
  );
}
