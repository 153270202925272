import { useCallback, useEffect, useState } from 'react';
import { addToast, Steps } from '@octano/global-ui';
import { Col, Row } from 'reactstrap';
import { getStatusTuitionProcess } from '../../api/requests/tuitionProcess';
import DisplayError from '../../components/info/DisplayError';
import { useLoadingState } from '../../hooks/useLoadingState';
import { useTuitionProcessNoSuaSteps } from '../../hooks/useTuitionProcessNoSuaSteps';
import { useTuitionProcess } from '../../hooks/useTuitionProcess';
import withProvider from '../../providers/withProvider';
import { useStepState } from '../../components/step/useStepState';
import StepProvider from '../../components/step/StepProvider';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Loading from '../../components/info/Loading';

/**
 * Vista del proceso de matrícula del proceso SIN SUA
 */
const TuitionProcessNoSua = () => {
  const { loading, setLoading, errorLoading, setErrorLoading } =
    useLoadingState();
  const { t } = useTranslation();
  const {
    setPostulant,
    setStatusPostulation,
    setPostulationDetail,
    setNewPostulationDetail,
    statusPostulation,
  } = useTuitionProcess();
  const { namesSteps, CurrentStepComponent, getIndexStep } =
    useTuitionProcessNoSuaSteps(statusPostulation);
  const { currentStep, setCurrentStep } = useStepState();
  const [idStep, setIdStep] = useState<string>();
  const { postulantId, postulationDetailId, fromStart } = useParams<{
    postulantId: string;
    postulationDetailId: string;
    fromStart: string;
  }>();

  const getStatus = useCallback(async () => {
    setLoading(true);
    const { data, error } = await getStatusTuitionProcess(
      postulationDetailId,
      postulantId,
    );
    if (data) {
      // Si fromStart es true lo deja en el primer paso,
      // de lo contrario calcula en el paso que iba el proceso
      if (fromStart !== 'true') {
        setIdStep(data.step);
      }
      setPostulant({ id: data.postulantId, fullName: data.studentFullName });
      setPostulationDetail(
        data.postulationDetailId && data.admission
          ? {
              id: data.postulationDetailId,
              admissionId: data.admission.admissionId,
              campus: {
                id: data.admission.campus.id,
                name: data.admission.campus.name,
              },
              schedule: {
                id: data.admission.schedule.id,
                name: data.admission.schedule.name,
              },
              studyPlanName: data.admission.studyPlanName,
              admissionMethod: data.admissionMethod,
            }
          : null,
      );
      setStatusPostulation(data.status);
      setNewPostulationDetail(data.newAdmission || null);
      setErrorLoading(undefined);
    } else if (error) {
      addToast({
        icon: 'error',
        color: 'danger',
        text: t('common.displayError.errorUnexpected'),
      });
      setErrorLoading(error.code);
    }

    setLoading(false);
  }, [
    setErrorLoading,
    setLoading,
    fromStart,
    postulantId,
    postulationDetailId,
    setPostulant,
    setStatusPostulation,
    setPostulationDetail,
    setNewPostulationDetail,
    t,
  ]);

  useEffect(() => {
    getStatus();
  }, [getStatus, fromStart, postulantId]);

  useEffect(() => {
    if (idStep) {
      const index = getIndexStep(idStep);
      setCurrentStep(index);
    }
  }, [idStep, getIndexStep, setCurrentStep]);

  if (errorLoading) {
    return (
      <DisplayError
        insideCard
        textBody={errorLoading}
        retryAction={getStatus}
        loadingAction={loading}
      />
    );
  }
  if (loading) {
    return <Loading insideCard />;
  }
  return (
    <Row>
      {/* Pasos del proceso */}
      <Col xs={12} className="pb-4">
        <Steps steps={namesSteps} currentStep={currentStep} />
      </Col>
      <Col xs={12} className="pt-2">
        {/* Se renderiza el componente adecuado para cada paso */}
        {CurrentStepComponent && <CurrentStepComponent />}
      </Col>
    </Row>
  );
};

export default withProvider(TuitionProcessNoSua, StepProvider);
