import { useState, useEffect, useCallback, useRef } from 'react';
import { getTimeVariableConfig } from '../../../../api/requests/settings';
import { Setting } from '../../../../types/Settings';

export default function useTimeVariableConfig() {
  const [isLoading, setIsLoading] = useState(true);
  const [isRefetching, setIsRefetching] = useState(false);
  const alreadyFetched = useRef(false);

  const [config, setConfig] = useState<Setting | null>();
  const [error, setError] = useState(false);

  const fetchConfig = useCallback(async () => {
    if (alreadyFetched.current) setIsRefetching(true);
    else alreadyFetched.current = true;

    const { data, error: fetchError } = await getTimeVariableConfig();

    if (fetchError) {
      setError(true);
    } else {
      setConfig(data);
    }

    setIsLoading(false);
    setIsRefetching(false);
  }, [alreadyFetched]);

  useEffect(() => {
    if (!alreadyFetched.current) {
      fetchConfig();
    }
  }, [fetchConfig]);

  return {
    isLoading,
    config,
    error,
    refetch: fetchConfig,
    isRefetching,
  };
}
