import { generatePath } from 'react-router-dom';

const ACADEMIC_RECORD = {
  BASE: '/maintainers-portal/academic-record-load/base',
  TEMPLATE: '/maintainers-portal/academic-record-load/template',
  UPLOAD: '/maintainers-portal/academic-record-load',
};

const ASSISTANTS = {
  CREATE: '/assistant',
};

const AUTH = {
  LOGIN: '/credential-login',
  REQUEST_ACTIVATION_EMAIL: '/check-activate-account',
  REQUEST_FORGOT_PASSWORD_EMAIL: '/recovery-password',
  ACTIVATE_ACCOUNT: '/activate-account',
  CHANGE_PASSWORD: '/reset-password',
  CHECK_PASSWORD_CHANGE_TOKEN: '/check-token',
};

const BASE = {
  BASE: '/maintainers-portal',
};

const COURSE_MAINTAINER = {
  LIST: '/course/all',
  COURSE_TYPES: '/course/type/all',
  CREATE_COURSE: '/course',
  GET_COURSE: (courseId: number) => `/course/${courseId}`,
  UPDATE_COURSE: (courseId: number) => `/course/${courseId}`,
};

// Endpoints para la modificación de inscripción de asignaturas
const COURSE_REGISTRATION = {
  LIST: '/students/students-enrollment-modification',
  GET_DETAIL: (studyPlanEnrollmentId: string | number) =>
    `/students/section-enrolled-by-student/${studyPlanEnrollmentId}`,
  AVAILABLE_COURSES:
    'maintainers-portal/course-enrollment/list-course-available-in-period',
  GET_AVAILABLE_SECTIONS: (courseId: number) =>
    `maintainers-portal/course-enrollment/list-available-section-for-course/${courseId}`,
  SAVE_ADMINISTRATIVE_ENROLLMENT:
    '/course-enrollment/save-administrative-enrollment',
  DETELE_COURSE_REGISTRATION: '/course-enrollment/remove-enrollment',
  UPDATE_COURSE_REGISTRATION_SECTION: (accountId: number) =>
    `/course-enrollment/update-enrollment/${accountId}`,
};

// Endpoints de mantenedores
const MAINTAINERS = {
  PERIODS: '/periods',
  PERIODS_TYPE: '/maintainers-portal/periods-types',
  PERIODS_TYPE_ALL: '/maintainers-portal/periods-types/all',
  MODALITY: '/modality',
  SCHEDULES: '/schedules',
  SCHEDULES_CALENDAR: '/schedules-calendar',
  CAMPUS: '/campus',
  SCHOOLS: '/school',
  STUDY_PLANS: '/study-plans',
  STUDY_PLAN_VERSION: '/study-plan-version',
  CURRICULUM: '/maintainers-portal/curriculum',
  AVAILABLE_COURSES: '/course/type',
};

const STUDY_PLANS_MAINTAINER = {
  BASE: '/study-plans',
  PUT_BY_ID: (studyPlanId: number | string) => `/study-plans/${studyPlanId}`,
  GET_BY_SCHOOL: (schoolId: number, academicOfferId: number) =>
    `/study-plans/school/${schoolId}/${academicOfferId}`,
  GET_BY_ID: (studyPlanId: number | string) => `/study-plans/${studyPlanId}`,
  GET_CURRICULUM_INFO: (studyPlanId: number, versionId: number) =>
    `/study-plans/curriculum/${studyPlanId}/${versionId}`,
};

const ACADEMIC_OFFERS_MAINTAINER = {
  GET_ALL: '/academic-offer/all',
  GET_BY_ID: (academicOfferId: number) =>
    `/academic-offer/edit/${academicOfferId}`,
  PUT_ACADEMIC_OFFER: (academicOfferId: string) =>
    `/academic-offer/${academicOfferId}`,
  DELETE_ACADEMIC_OFFER: (academicOfferId: string) =>
    `/academic-offer/${academicOfferId}`,
  GET_VALIDATE_QUOTA: (academicOfferId: string) =>
    `/academic-offer/validate-quota/${academicOfferId}`,
  POST_VALIDATE_QUOTA: (academicOfferId: number) =>
    `/academic-offer/update-quota/${academicOfferId}`,
  GET_COMPLETE_OFFER: (academicOfferId: number) =>
    `/academic-offer/complete-offer/${academicOfferId}`,
  VACANT_OFFER_GET_PENDING: (academicOfferId: number) =>
    `/academic-offer/pending-offers/${academicOfferId}`,
  POST_OFFER_REPORT: (academicOfferId: number) =>
    `/academic-offer/offer-report/${academicOfferId}`,
  POST_VISUALIZE: (academicOfferId: number) =>
    `/academic-offer/visualize/${academicOfferId}`,
  GET_RESOLUTION: (resolutionId: number) =>
    `/academic-offer/resolution-url/${resolutionId}`,
  POST_RESOLUTION: (resolutionId: number) =>
    `/academic-offer/attach-resolution/${resolutionId}`,
  VACANT_OFFERS_GET_ALL_NON_DRAFT:
    'maintainers-portal/academic-offer/vacant-offers/non-draft',
  VACANT_OFFERS_FORM_DATA:
    'maintainers-portal/academic-offer/vacant-offers/form-data',
  BASE: 'maintainers-portal/academic-offer/vacant-offers',
};

const PACKAGES = {
  GET_ALL: '/section-packages',
  DETAIL: '/section-packages/detail',
  SECTIONS: '/section-packages/sections',
  TIME_CALENDAR: '/section-packages/time-calendar',
  ENROLLED_STUDENTS: '/section-packages/enrolled-students',
  ACADEMIC_OFFER_STUDY_PLAN_VERSION_OFFER: '/section-packages/studyplan-detail',
  PENDING_STUDENTS: '/section-packages/pending-students',
  ENROLL_NEW_STUDENTS: '/course-enrollment/package/enroll-new-students',
};

const PARAMETERS = {
  ACTIVITIES: '/activity',
  COUNTRIES: '/countries',
  INSTITUTION_TYPES: '/postulation/institutions-types',
  REGION_AND_COMMUNES: '/region/all',
  SEARCH_ASSISTANT: '/assistant/search',
  SEARCH_TEACHERS: '/teacher/search-teacher',
  SEARCH_RESPONSIBLE: '/section/search-responsable',
  SEARCH_PACKAGES: (periodId: number) => `/section-packages/period/${periodId}`,
  GET_COURSES: (searchText: string) => `/course/find-by/${searchText}`,
};

const PERIODS = {
  GET_ALL: '/section-packages-load/periods',
  GET_BY_ID: '/section-packages-load/detail',
  BASE_INFO_DOCUMENT: (periodId: string | number) =>
    `/section-packages-load/base-information/${periodId}`,
  LOADED_INFO_DOCUMENT: '/section-packages-load/loaded-sections-information',
  UPLOAD_SECTIONS_DOCUMENT: '/section-packages-load/create-from-file',
  DOWNLOAD_ERROR_DOCUMENT: '/section-packages-load/section-load-error-file',
};

const POSTULANT_PROCESS = {
  STATE_BY_RUT: '/postulant-process',
  CHANGE_DOCUMENT_STATE: '/postulant-process/change-document-state',
  CHANGE_IDENTITY_CARD_STATE: '/postulant-process/change-identity-card-state',
  DELETE_DOCUMENT: '/postulant-process/delete-document',
  DELETE_IDENTITY_CARD: '/postulant-process/delete-identity-card',
  GET_LINK_DOWNLOAD_DOCUMENT: '/postulant-process/download-document',
  GET_LINK_DOWNLOAD_IDENTITY_CARD: '/postulant-process/download-identity-card',
  UPLOAD_DOCUMENT_POSTULANT: '/postulant-process/upload-document',
  UPLOAD_IDENTITY_CARD_POSTULANT: '/postulant-process/upload-identity-document',
  GET_OTHER_DOCUMENTS: '/postulant-other-documents',
  UPLOAD_OTHER_DOCUMENTS: '/postulant-other-documents',
  DOWNLOAD_OTHER_DOCUMENTS: '/postulant-other-documents/download',
  DELETE_OTHER_DOCUEMNTS: '/postulant-other-documents',
  VIEW_OTHER_DOCUMENTS: '/postulant-process/view-document',
  VIEW_IDENTITY_CARD: '/postulant-process/view-identity-card',
};

const POSTULANTS = {
  CREATE: '/postulants',
  UPDATE: '/postulants',
  SEARCHRUT: '/postulants/search-rut',
  SEARCHBYPASSPORT: '/postulants/search-passport',
  GETDOCUMENTS: (studyPlanVersionOfferId: number) =>
    generatePath('/postulants/:studyPlanVersionOfferId/documentation', {
      studyPlanVersionOfferId,
    }),
};

const POSTULATION = {
  CREATE: '/postulation/create',
  INFORMATION_WAITING_LIST: (postulantId?: number) =>
    `/postulation/information-waiting-list${postulantId && '/' + postulantId}`,
  UPDATE: (postulationDetailId?: string) =>
    `/postulation/update-postulation/${postulationDetailId}`,
};

const SECTION_ATTRIBUTES = {
  GET_ALL_PERIODS: '/maintainers-portal/section-attributes',
  GET_PERIOD_BY_ID: '/maintainers-portal/section-attributes',
  BASE_INFO_DOCUMENT: (periodId: string | number) =>
    `/maintainers-portal/section-attributes/base-info/${periodId}`,
  UPLOAD_ATTRIBUTES_DOCUMENT: '/maintainers-portal/section-attributes',
  DOWNLOAD_ERROR_DOCUMENT:
    '/maintainers-portal/section-attributes/section-attributes-error-file',
};

// Endpoints del mantenedor de secciones
const SECTION_MAINTAINER = {
  LIST: '/section/filtered-list',
  GET_SECTION: (sectionId: number) =>
    `/maintainers-portal/section/${sectionId}`,
  GET_DETAIL: (sectionId: number) => `/maintainers-portal/section/${sectionId}`,
  GET_GENERAL: (sectionId: number) =>
    `/maintainers-portal/section/${sectionId}/general`,
  PUT_GENERAL: (sectionId: number) =>
    `/maintainers-portal/section/${sectionId}/general`,
  GET_SCHEDULES: (sectionId: number) =>
    `/maintainers-portal/section/${sectionId}/schedules`,
  PUT_SCHEDULES: (sectionId: number) =>
    `/maintainers-portal/section/${sectionId}/schedules`,
  PERIOD_LIST: '/periods/all',
  SCHOOL_LIST: '/school/all',
  STUDY_PLAN_LIST: '/study-plans/all',
  CAMPUS_LIST: '/campus/all',
  TEACHER_LIST: '/teacher/all',
  COURSES_LIST: '/course/filtered-list',
  CREATE_SECTION: '/section/create-section',
  ASIGN_PACKAGE_TO_SECTION: (sectionId: string | number) =>
    `/section/update-packages-section/${sectionId}`,
};

const STUDENT_STATUS = {
  LIST: '/students/student-status',
  STATUS_OPTIONS: 'students/types-student-status',
  GET_CHANGE_STATUS: (studyPlanEnrollmentId: number) =>
    `students/change-status/${studyPlanEnrollmentId}`,
  GET_STUDENT_STATUS: (studyPlanEnrollmentId: number) =>
    `/students/get-status/${studyPlanEnrollmentId}`,
  GET_UPDATE_STATUS: (studyPlanEnrollmentId: number) =>
    `/students/update-status-information/${studyPlanEnrollmentId}`,
  REPORT_STATUS_STUDENT: '/report/status-student',
};

const STUDY_PLANS = {
  STUDY_PLAN_QUOTA: '/study-plans/study-plan-quota',
  STUDY_PLAN_ADMISSION_DETAIL_FORM:
    '/maintainers-portal/admission-management/form',
  STUDY_PLAN_ADMISSION_DETAIL: '/maintainers-portal/admission-management',
};

//Enpoints de admisión
const STUDY_PLANS_OFFER = {
  RAIZ: '/study-plan-version-offer',
  STATUS_LIST: (id: number) => `/study-plan-version-offer/${id}/status`,
  GET_POSTULANT: (id: number) => `/study-plan-version-offer/${id}/postulant`,
  CHANGE_TO_DESIST: '/study-plan-version-offer/to-desist',
  TO_STATUS_DESISTED: '/postulation-detail/status-desisted',
  GET_STATUS_STUDY_PLAN_CHANGE: (id: number) =>
    `postulation-detail/check-study-plan-change/${id}`,
  SET_DISCOUNT: '/postulation-detail/discount',
  TO_STATUS_RETRACTED: '/postulation-detail/status-retracted',
  TO_IN_PROCESS: '/postulation-detail/status-in-process',
  POSTULANTSPENDING: '/study-plan-version-offer/postulants-pending',
  DELETE_POSTULANT: '/postulants',
  SEARCH_POSTULANT_PENDING:
    '/study-plan-version-offer/postulants-pending-search',
  DOCUMENTATION_REVIEW: '/study-plan-version-offer/documentation-review',
  SEARCH_BY_RUT: (id: number, rut: string) =>
    `/study-plan-version-offer/${id}/search-by-rut/${rut}`,
  SEARCH_BY_PASSPORT: (id: number, countryId: string, search: string) =>
    `/study-plan-version-offer/${id}/search-by-passport/${countryId}/${search}`,
};

//Endpoints de aranceles y matrícula
const TARIFF_TUITION = {
  //Descuentos
  DISCOUNTS: '/discounts',
  ALL_DISCOUNTS: '/discounts/all',
  //Matriculas
  TUITIONS: '/tuition-period',
  PERIODS: '/periods/all-tuition',
  //Aranceles
  ACADEMIC_OFFER_TARIFF: 'academic-offer-tariff',
};

// Endpoints relacionados con el proceso de matrícula
const TUITION_PROCESS = {
  ACTIVE_TUITION_PROCESS: '/tuition-processes/active',
  LOGIN: '/login',
  TUITION_PROCESS_STEPS: '/tuition-process-steps',
  STUDENT_FILE: '/tuition-process-steps/student-file',
  SUSTAINER: '/tuition-process-steps/sustainer',
  COSIGNER: '/tuition-process-steps/cosigner',
  TERMS_AND_CONDITIONS: '/tuition-process-steps/terms-and-conditions',
  TERMS_AND_CONDITIONS_PREVIEW_TOKEN:
    '/tuition-process-steps/terms-and-conditions/preview/token',
  TERMS_AND_CONDITIONS_PREVIEW:
    '/tuition-process-steps/terms-and-conditions/preview',
  PAYMENT: '/tuition-process-steps/payment',
  SIGN_DOCUMENTATION: '/tuition-process-steps/sign-documentation',
  SIGN_DOCUMENTATION_SIGN: '/tuition-process-steps/sign-documentation/sign',
  SIGN_DOCUMENTATION_IDENTITY_CARD_UPLOAD: (postulationDetailId?: number) =>
    `/tuition-process-steps/sign-documentation/identity-card/${postulationDetailId}`,
  SIGN_DOCUMENTATION_PREVIEW_TOKEN:
    '/tuition-process-steps/sign-documentation/preview-document/token',
  SIGN_DOCUMENTATION_PREVIEW:
    '/tuition-process-steps/sign-documentation/preview-document',
  SIGN_DOCUMENTATION_VERIFY: '/tuition-process-steps/sign-documentation',
  COMPLETED: '/tuition-process-steps/completed',
  SIGN_DOCUMENTATION_LEM_NEM: (postulationDetailId?: number) =>
    `/tuition-process-steps/sign-documentation/upload-documents/${postulationDetailId}`,
  SIGN_DOWNLOAD_CONTRACT: (postulationDetailId?: number) =>
    `/tuition-process-steps/sign-documentation/download-contract/${postulationDetailId}`,
  CHANGE_ENROLLED_STUDY_PLAN:
    '/tuition-process-step/study-plan-selection/study-plan-change',
  GET_DESIST_STUDY_PLAN_CHANGE: (postulationDetailId: number) =>
    `/postulation-detail/status-change-desisted/${postulationDetailId}`,
  TARIFF_QUOTAS: '/tuition-process-steps/tariff/inf',
  TARIFF_QUOTAS_SAVE: '/tuition-process-steps/tariff/save-quota',
  SIGN_DOCUMENTATION_SUSTAINER_INCOME: (postulationDetailId?: number) =>
    `/tuition-process-steps/sign-documentation/upload-sustainer-income/${postulationDetailId}`,
  SIGN_DOCUMENTATION_SUSTAINER_INCOME_OTHERS: (postulationDetailId?: number) =>
    `/tuition-process-steps/sign-documentation/upload-other-document/${postulationDetailId}`,
};

const TUITION_CONTINUITY_PROCESS_MANAGEMENT = {
  BASE: '/maintainers-portal/tuition-continuity-process/form',
  LIST: '/maintainers-portal/tuition-continuity-process',
  DISCOUNT: '/maintainers-portal/tuition-continuity-process/discount',
  DETAIL: (studyPlanVersionOfferId: number | string) =>
    `/maintainers-portal/tuition-continuity-process/${studyPlanVersionOfferId}`,
  DETAIL_STUDENTS: (studyPlanVersionOfferId: number | string) =>
    `/maintainers-portal/tuition-continuity-process/${studyPlanVersionOfferId}/students`,
};

const REPORTS = {
  GET_ALL: '/report',
  GET_STUDY_PLAN_VERSION_REPORTS: '/report/study-plan-version/form',
  DOWNLOAD_BY_URL: (url: string) => `/report${url}`,
  UPLOAD_DATA_BY_URL: (url: string) => `/report${url}`,
};

const TEACHER = {
  SEARCH: '/teacher/search',
  SEND_ACTIVATION_LINK: '/teacher/activate-teacher',
  SEND_PASSWORD_RECOVERY_LINK: '/teacher/recovery-password',
  DEACTIVATE_TEACHER: '/teacher/deactivate-teacher',
};

const MAINTAINERPORTAL = {
  GET_PERIOD_MAINTAINER_PORTAL: '/maintainers-portal/period-close/form',
  FILTER_GET_SECTION_BY_PERIOD_ID_AND_STATE:
    '/maintainers-portal/period-close/filter/form',
  GET_SECTION_BY_PERIOD_ID_AND_STATE: '/maintainers-portal/period-close',
  GET_SECTION_BY_ID: (sectionId: string) =>
    `/maintainers-portal/period-close/review/${sectionId}`,
  POST_CLOSED_SECTION: (sectionId: number) =>
    `/maintainers-portal/period-close/close/${sectionId}`,
  POST_REJECT_CLOSURE_SECTION: (sectionId: number) =>
    `/maintainers-portal/period-close/reopen/${sectionId}`,
  DOWNLOAD_PDF_SECTION_CLOSE: (sectionId: number) =>
    `/maintainers-portal/period-close/${sectionId}/download`,
  GET_SECTION_PACKAGE: '/maintainers-portal/sections-packages',
};

const GRADES_ENTRY = {
  CONFIG: '/grades-entry/config',
};

const SETTINGS = {
  TIME_STATUS_LESSONS: '/settings/time-status-lessons',
  TIMEZONE: '/settings/timezone',
};

const STUDENTS_POST_CLOSE = {
  SEARCH: '/maintainers-portal/students-post-close',
  STUDENT_DETAIL: (courseEnrollmentId: string | number) =>
    `/maintainers-portal/students-post-close/${courseEnrollmentId}`,
  STUDENT_GRADES_UPDATE: (courseEnrollmentId: string) =>
    `/maintainers-portal/students-post-close/${courseEnrollmentId}`,
};

const GRADUATION_PROCESS_MASSIVE = {
  CURRENT_PERIOD_AND_FACULTIES: '/maintainers-portal/graduation-process/form',
  STUDY_PLANS_BY_FACULTY: '/maintainers-portal/graduation-process',
  PROCESS_GRADUATES_BY_STUDY_PLANS: '/maintainers-portal/graduation-process',
  DOWNLOAD_GRADUATES_LIST: (studyPlanId: number) =>
    `/maintainers-portal/graduation-process/student-processed/${studyPlanId}`,
  GET_GRADUATES_LIST: '/maintainers-portal/graduation-process/graduates',
  FILTER_GRADUATES_LIST: '/maintainers-portal/graduation-process/filter/form',
  DOWNLOAD_GRADUATES_LIST_BY_FILTER:
    '/maintainers-portal/graduation-process/graduates/excel',
};

const GRADUATION_PROCESS_SINGLE = {
  SEARCH_STUDENTS: '/maintainers-portal/single-graduation-process',
  PROCESS_GRADUATE: '/maintainers-portal/single-graduation-process',
  GET_SUMMARY: '/maintainers-portal/single-graduation-process/summary',
};

const TUITION_CONTINUITY_TARIFF = {
  TUITION_LIST: '/maintainers-portal/tuition-continuity-tariff/tuition-values',
  PERIODS: '/maintainers-portal/tuition-continuity-tariff/available-periods',
  SAVE_TUITION_VALUE:
    '/maintainers-portal/tuition-continuity-tariff/tuition-values',
  GET_PERIOD_AND_OFFER:
    '/maintainers-portal/tuition-continuity-tariff/period-school',
  GET_TARIFF_LIST: '/maintainers-portal/tuition-continuity-tariff/tariff',
  SAVE_TARIFFS: '/maintainers-portal/tuition-continuity-tariff/tariff',
};

const TUITION_CONTINUITY_DOCUMENT_REVIEW = {
  LIST: (periodId: number | string) =>
    `/maintainers-portal/tuition-continuity-process/document-review/${periodId}`,
  STUDENT_TABS:
    '/maintainers-portal/tuition-continuity-process/document-review/student-tabs',
  STUDENT: (tcProcessId: number | string) =>
    `maintainers-portal/tuition-continuity-process/document-review-student/${tcProcessId}`,
  DOCUMENT: (documentId: number | string) =>
    `maintainers-portal/tuition-continuity-process/document-review-student/document/${documentId}`,
  DOCUMENT_CHANGE_STATUS: (documentId: number | string) =>
    `maintainers-portal/tuition-continuity-process/document-review-student/document/${documentId}`,
  DOCUMENT_DELETE: (documentId: number | string) =>
    `maintainers-portal/tuition-continuity-process/document-review-student/document/${documentId}`,
  DOCUMENT_UPLOAD: (tcProcessId?: number | string) =>
    `maintainers-portal/tuition-continuity-process/document-review-student/${tcProcessId}/document`,
  DOCUMENT_OTHER: (documentId: number | string) =>
    `maintainers-portal/tuition-continuity-process/document-review-student/document-other/${documentId}`,
  DOCUMENT_OTHER_DELETE: (documentId: number | string) =>
    `maintainers-portal/tuition-continuity-process/document-review-student/document-other/${documentId}`,
  DOCUMENT_OTHER_UPLOAD: (tcProcessId?: number | string) =>
    `maintainers-portal/tuition-continuity-process/document-review-student/document-other/${tcProcessId}`,
};

const CURRICULUM_PROGRESS = {
  BASE: 'maintainers-portal/student-curriculum-progress',
  STUDENTS: 'maintainers-portal/student-curriculum-progress/students',
  STUDENT: (studyPlanEnrollmentId: number | string) =>
    `maintainers-portal/student-curriculum-progress/students/${studyPlanEnrollmentId}`,
  HISTORY_DOWNLOAD: (studyPlanEnrollmentId: number | string) =>
    generatePath(
      `maintainers-portal/student-curriculum-progress/students/:studyPlanEnrollmentId/history-document`,
      { studyPlanEnrollmentId },
    ),
};

const STUDENT_COURSE_VALIDATION = {
  INTERNAL: {
    BASE: 'maintainers-portal/student-course-validation/internal',
    VALIDATE: (studyPlanEnrollmentId: number | string) =>
      `maintainers-portal/student-course-validation/internal/${studyPlanEnrollmentId}`,
    STUDENTS: 'maintainers-portal/student-course-validation/internal/students',
    STUDY_PLAN_ENROLLMENT: (studyPlanEnrollmentId: number | string) =>
      `maintainers-portal/student-course-validation/internal/students/${studyPlanEnrollmentId}`,
    CURRICULUM: (studyPlanEnrollmentId: number | string) =>
      `maintainers-portal/student-course-validation/internal/curriculum/${studyPlanEnrollmentId}`,
  },
};

const STUDENT_PETITIONS = {
  GET_STUDENT_PETITIONS_LIST: '/maintainers-portal/student-petitions',
  GET_STUDENT_RESOLVED_PETITIONS_LIST:
    '/maintainers-portal/student-petitions/resolved',
  FILTER_STUDENT_PETITIONS_LIST:
    '/maintainers-portal/student-petitions/filter/form',
  FILTER_STUDENT_PETITIONS_EXCEL_LIST:
    '/maintainers-portal/student-petitions/filter/excel/form',
  DOWNLOAD_STUDENT_PETITIONS_LIST_BY_FILTER:
    '/maintainers-portal/student-petitions/report/excel',
};

const STUDENT_PETITION_DETAIL = {
  GET_STUDENT_PETITION: (petitionId: number | string) =>
    `/maintainers-portal/student-petitions/${petitionId}`,
  PUT_STUDENT_PETITION: (petitionId: number | string) =>
    `/maintainers-portal/student-petitions/${petitionId}`,
  GET_STUDENT_PETITION_FILES: (fileId: number | string) =>
    `/maintainers-portal/student-petitions/file/${fileId}`,
  GET_STUDENT_PETITION_STORY: (petitionId: number | string) =>
    `/maintainers-portal/student-petitions/${petitionId}/history`,
};

const STUDENTS_DEGREE = {
  GET_STUDENTS_DEGREE_LIST: '/maintainers-portal/students-degree',
  FILTER_STUDENTS_DEGREE_LIST:
    '/maintainers-portal/students-degree/filter/form',
  DOWNLOAD_STUDENTS_DEGREE_LIST_BY_FILTER:
    '/maintainers-portal/students-degree/report/excel',
};

const STUDENTS_DEGREE_STUDENT = {
  GET_BASE: (studyPlanEnrollmentId: number | string) =>
    generatePath('/maintainers-portal/students-degree/:studyPlanEnrollmentId', {
      studyPlanEnrollmentId,
    }),
  GET_ACTIVITY: (degreeProcessActivityId: number | string) =>
    generatePath(
      '/maintainers-portal/students-degree/activity/:degreeProcessActivityId',
      {
        degreeProcessActivityId,
      },
    ),
  POST_CREATE_RENDITION: (degreeProcessActivityId: number | string) =>
    generatePath(
      '/maintainers-portal/students-degree/activity/:degreeProcessActivityId/rendition',
      {
        degreeProcessActivityId,
      },
    ),
  POST_COMPLETE: (studyPlanEnrollmentId: number | string) =>
    generatePath('/maintainers-portal/students-degree/:studyPlanEnrollmentId', {
      studyPlanEnrollmentId,
    }),
};

const CURRICULUM = {
  GET_DEGREE_ACTIVITY_TYPES_LIST:
    '/maintainers-portal/curriculum/activity-types',
  POST_DEGREE_ACTIVITY_BY_CURRICULUM_CREATE:
    '/maintainers-portal/curriculum/activity-types',
  GET_DEGREE_ACTIVITY_BY_CURRICULUM_LIST: (curriculumId: number | string) =>
    generatePath('/maintainers-portal/curriculum/:curriculumId/activity', {
      curriculumId,
    }),
  DELETE_DEGREE_ACTIVITY_BY_CURRICULUM_REMOVE: (
    curriculumId: number | string,
    curriculumDegreeActivityId: number | string,
  ) =>
    generatePath(
      '/maintainers-portal/curriculum/:curriculumId/activity/:curriculumDegreeActivityId',
      {
        curriculumId,
        curriculumDegreeActivityId,
      },
    ),
};

const ENROLLMENT_SOFT_CHANGE = {
  GET_REGULAR_STUDENTS_LIST: `maintainers-portal/enrollment-soft-change`,
  GET_REGULAR_STUDENTS_LIST_CONTINUITY: `maintainers-portal/enrollment-soft-change/continuity`,
  GET_FILTERS: `maintainers-portal/enrollment-soft-change/form-data`,
  MODIFY_FORM_DATA: (studyPlanEnrollmentId: string | number) =>
    `maintainers-portal/enrollment-soft-change/${studyPlanEnrollmentId}/form-data`,
  MODIFY: (studyPlanEnrollmentId: string | number) =>
    `maintainers-portal/enrollment-soft-change/${studyPlanEnrollmentId}`,
  VERIFY_SOFTCHANGE_TO_CONTINUITY: (
    accoundId: string | number,
    studyPlanEnrollmentId: string | number,
  ) =>
    `maintainers-portal/enrollment-soft-change/continuity/${accoundId}/${studyPlanEnrollmentId}/verify`,
  MODIFY_FORM_DATA_TO_CONTINUITY: (studyPlanEnrollmentId: string | number) =>
    `maintainers-portal/enrollment-soft-change/continuity/${studyPlanEnrollmentId}/form-data`,
  MODIFY_TO_CONTINUITY: (studyPlanEnrollmentId: string | number) =>
    `maintainers-portal/enrollment-soft-change/continuity/${studyPlanEnrollmentId}`,
};

export {
  ACADEMIC_OFFERS_MAINTAINER,
  ACADEMIC_RECORD,
  ASSISTANTS,
  AUTH,
  BASE,
  COURSE_MAINTAINER,
  COURSE_REGISTRATION,
  CURRICULUM,
  CURRICULUM_PROGRESS,
  GRADES_ENTRY,
  GRADUATION_PROCESS_MASSIVE,
  GRADUATION_PROCESS_SINGLE,
  MAINTAINERPORTAL,
  MAINTAINERS,
  ENROLLMENT_SOFT_CHANGE as MODIFY_ENROLLMENT,
  PACKAGES,
  PARAMETERS,
  PERIODS,
  POSTULANTS,
  POSTULANT_PROCESS,
  POSTULATION,
  REPORTS,
  SECTION_ATTRIBUTES,
  SECTION_MAINTAINER,
  SETTINGS,
  STUDENTS_DEGREE,
  STUDENTS_DEGREE_STUDENT,
  STUDENTS_POST_CLOSE,
  STUDENT_COURSE_VALIDATION,
  STUDENT_PETITIONS,
  STUDENT_PETITION_DETAIL,
  STUDENT_STATUS,
  STUDY_PLANS,
  STUDY_PLANS_MAINTAINER,
  STUDY_PLANS_OFFER,
  TARIFF_TUITION,
  TEACHER,
  TUITION_CONTINUITY_DOCUMENT_REVIEW,
  TUITION_CONTINUITY_PROCESS_MANAGEMENT,
  TUITION_CONTINUITY_TARIFF,
  TUITION_PROCESS,
};
