import { ColumnTable, Table } from '@octano/global-ui';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

import { SectionTitle } from '../../../../components/text';

interface ITariffTableProps {
  quotas: { amount?: string; expirationDate?: string; quota?: string }[];
}

export default function TariffTable({ quotas }: ITariffTableProps) {
  const prefix = 'tuitionProcessNoSua.tariffQuotas';
  const { t } = useTranslation();

  const printClassName = (index: number, data: any[], className?: string) => {
    return clsx(
      className,
      index === data.length - 1 &&
        'h-100 d-flex justify-content-center align-items-center',
    );
  };

  const columns: ColumnTable[] = [
    {
      columnName: 'quota',
      headerText: t(`${prefix}.table.quota`),
      width: '20%',
      tdClassName: 'p-0',
      cellFormat({ row, data, index }) {
        if (index === data!.length - 1)
          return (
            <div className={printClassName(index, data!, 'bg-tertiary')}>
              <p className="text-primary m-auto">
                <strong>Total</strong>
              </p>
            </div>
          );
        return row.quota;
      },
    },
    {
      columnName: 'expirationDate',
      headerText: t(`${prefix}.table.expirationDate`),
      width: '20%',
      tdClassName: 'p-0',
      cellFormat({ row, data, index }) {
        if (index === data!.length - 1)
          return (
            <div className={printClassName(index, data!, 'bg-tertiary')} />
          );
        return row.expirationDate;
      },
    },
    {
      columnName: 'amount',
      headerText: t(`${prefix}.table.amount`),
      width: '20%',
      tdClassName: 'p-0',
      cellFormat({ row, data, index }) {
        if (index === data!.length - 1)
          return (
            <div className={printClassName(index, data!, 'bg-tertiary')}>
              <p className="text-primary m-auto">
                <strong>{row.total}</strong>
              </p>
            </div>
          );
        return row.amount;
      },
    },
  ];
  return (
    <Row>
      <Col xs={12} className="py-3">
        <SectionTitle text={t(`${prefix}.table.title`)} />
      </Col>
      <Col xs={12}>
        <div className="w-75 mx-auto px-4 ">
          <div className="text-center">
            <Table columns={columns} data={[...quotas]} />
          </div>
        </div>
      </Col>
    </Row>
  );
}
