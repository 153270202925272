import { useState, useEffect, useCallback, useMemo } from 'react';
import { Card, Col, Form, Row } from 'reactstrap';
import {
  addToast,
  Button,
  OutlinedSelect,
  SelectOptionType,
} from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import DisplayError from '../../../../components/info/DisplayError';
import Loading from '../../../../components/info/Loading';
import { useValidations } from '../../../../hooks/useValidations';
import { useLoadingState } from '../../../../hooks/useLoadingState';
import ConfirmationModal from '../../../../components/modals/ConfirmationModal';
import {
  getStudyPlanQuota,
  requestChangeEnrolledStudyPlan,
  requestSaveCareerSelection,
  requestUpdateCareerSelection,
} from '../../../../api/requests/tuitionProcess';
import { useHistory, useParams } from 'react-router-dom';
import { useTuitionProcess } from '../../../../hooks/useTuitionProcess';
import { PostulationDetailStatus } from '../../../../types/postulationDetailStatus';
import HeaderStep from '../HeaderStep';
import { useStepState } from '../../../../components/step/useStepState';
import { STATUS_POSTULATION } from '../../../../types/tuitionProcessOnSite';

type InputsForm = {
  career: SelectOptionType;
};

const SelectCareer = () => {
  const { t } = useTranslation();
  const prefix = 'tuitionProcessNoSua.selectCareer';
  const history = useHistory();
  const { handleSubmit, control, formState, getValues } = useForm<InputsForm>();
  const { msgValidations } = useValidations();
  const [optionsCareer, setOptionsCareer] = useState<SelectOptionType[]>([]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { loading, setLoading, errorLoading, setErrorLoading } =
    useLoadingState();
  const [confirming, setConfirming] = useState<boolean>(false);
  const { postulantId } = useParams<{ postulantId: string }>();
  const {
    postulationDetail,
    setStatusPostulation,
    statusPostulation,
    postulant,
  } = useTuitionProcess();
  const [confirmChangeCareer, setConfirmChangeCareer] =
    useState<boolean>(false);
  const [modalChangeCareer, setModalChangeCareer] = useState<boolean>(false);
  const [modalQuotaExceed, setModalQuotaExceed] = useState<boolean>(false);
  const { nextStep } = useStepState();
  const msgValidationChangeStudyPlan = t(
    `${prefix}.msgValidationChangeStudyPlan`,
  );

  const getCareers = useCallback(async () => {
    setLoading(true);
    const { data, error } = await getStudyPlanQuota();
    if (data) {
      setOptionsCareer(
        data.data.map((career: any) => {
          return {
            value: career.id,
            label: `${career.studyPlanVersion.name} - Campus ${career.campus.name} - ${career.schedule.name}`,
          };
        }),
      );
      setErrorLoading(undefined);
    }
    if (error) {
      setErrorLoading('error');
    }
    setLoading(false);
  }, [setLoading, setErrorLoading]);

  useEffect(() => {
    getCareers();
  }, [getCareers]);

  const saveChangeEnrolledCareer = useCallback(
    async (admissionId: number) => {
      const { data, error } = await requestChangeEnrolledStudyPlan({
        admissionId: admissionId,
        sub: postulant?.id ? postulant?.id : +postulantId,
        currentPostulationDetailId: postulationDetail?.id,
      });
      if (data) {
        addToast({
          icon: 'success',
          color: 'success',
          text: t('common.messages.editSuccess'),
        });
        history.replace(
          `/tuition-process/postulation/${postulantId}/${data.data.id}/SIN_SUA/false`,
        );
      }
      if (error) {
        const validateQuota = Array.isArray(error.data?.message)
          ? error.data?.message?.some((e: string) => e === 'QUOTA_EXCEEDED')
          : false;
        if (validateQuota) {
          setModalQuotaExceed(true);
        } else {
          addToast({
            icon: 'error',
            color: 'danger',
            text: t('common.errors.save'),
          });
        }
      }
    },
    [postulantId, postulationDetail, history, t, postulant],
  );

  const onSubmit = (values: InputsForm) => {
    if (statusPostulation === STATUS_POSTULATION.ENROLLED) {
      // Si selecciona una carrera distinta a la actual
      if (
        postulationDetail?.admissionId &&
        postulationDetail.admissionId !== values.career.value
      ) {
        saveChangeEnrolledCareer(+values.career.value);
      }
    } else {
      if (
        postulationDetail?.admissionId &&
        postulationDetail.admissionId !== values.career.value &&
        !confirmChangeCareer
      ) {
        setModalChangeCareer(true);
      } else if (
        postulationDetail?.admissionId &&
        postulationDetail.admissionId === values.career.value
      ) {
        // Si quiere cambiar por la misma carrera, solo se pasa al siguiente paso
        nextStep();
      } else {
        saveSelectionCareer(values.career.value, false);
      }
    }
  };

  const saveSelectionCareer = async (
    admissionId: number | string,
    acceptsWaitingList: boolean,
  ) => {
    let studentAdmissionId =
      typeof admissionId === 'string' ? parseInt(admissionId) : admissionId;
    let isUpdate =
      postulationDetail?.id && postulationDetail?.id !== studentAdmissionId;

    const dataRequest = {
      admissionId: studentAdmissionId,
      acceptsWaitingList,
      sub: Number(postulantId),
    };

    const { data, error } = isUpdate
      ? await requestUpdateCareerSelection(dataRequest, postulationDetail?.id)
      : await requestSaveCareerSelection(dataRequest);
    if (data) {
      addToast({
        icon: 'success',
        color: 'success',
        text: t(`${prefix}.saveSuccess`),
      });
      if (acceptsWaitingList) {
        setStatusPostulation(STATUS_POSTULATION.IN_WAITING_LIST);
      }

      setConfirmChangeCareer(false);
      setConfirming(false);

      const {
        postulantId: studentPostulantId,
        admissionType,
        details,
      } = data.data;
      // Sólo debería existir un detalle en alguno de estos estados
      // el cual correspondería a la postulación actual
      let postDetail = details.find(
        (detail) =>
          detail.status === PostulationDetailStatus.EN_PROCESO ||
          detail.status === PostulationDetailStatus.EN_ESPERA ||
          detail.status === PostulationDetailStatus.MATRICULADO,
      );

      let url = `/tuition-process/postulation/${studentPostulantId}/${
        postDetail?.id || 'undefined'
      }/${admissionType?.id || 'SIN_SUA'}/false`;
      history.replace(url);
    } else if (error) {
      if (
        error.data?.message?.[0] === 'QUOTA_EXCEEDED' &&
        !acceptsWaitingList
      ) {
        // Si excede la cuota y la confirmación es false
        // se abre modal para confirmar quedar en lista de espera
        setIsModalOpen(true);
      } else {
        addToast({
          icon: 'error',
          color: 'danger',
          text: t(`${prefix}.saveError`),
        });
      }
    }
  };

  /**
   * Se utiliza para inscribir al alumno en lista de espera luego de confirmar.
   */
  const acceptWaitingList = async () => {
    setConfirming(true);
    const admissionId = getValues('career').value;
    await saveSelectionCareer(
      typeof admissionId === 'string' ? parseInt(admissionId) : admissionId,
      true,
    );
    setIsModalOpen(false);
    setConfirming(false);
  };

  /**
   * Se utiliza para confirmar el cambio de una carrera a otra
   */
  const acceptChangeCareer = async () => {
    setConfirmChangeCareer(true);
    setModalChangeCareer(false);
    const admissionId = getValues('career').value;
    await saveSelectionCareer(
      typeof admissionId === 'string' ? parseInt(admissionId) : admissionId,
      false,
    );
  };

  const currentStudyPlanName = useMemo(() => {
    if (postulationDetail) {
      return `${postulationDetail.studyPlanName} - ${postulationDetail.campus.name} - ${postulationDetail.schedule.name}`;
    }
    return '';
  }, [postulationDetail]);

  if (loading && !errorLoading) {
    return <Loading insideCard />;
  }
  if (errorLoading) {
    return (
      <DisplayError
        insideCard
        textBody={t('common.selectCareer.errorLoadingInfo')}
        title={t('common.selectCareer.titleError')}
        buttonText={t('common.selectCareer.underStood')}
        retryAction={() => history.push('/tuition-process')}
        loadingAction={loading}
      />
    );
  }
  return (
    <Card className="px-4 px-lg-5 py-4">
      <HeaderStep prefixStep={prefix} />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col xs={12} className="pt-lg-4 pb-lg-5">
            <div
              className="w-100 mx-auto pb-5 pt-lg-5 pb-lg-5"
              style={{ maxWidth: '600px' }}
            >
              <span className="fs-14 text-medium">{t(`${prefix}.career`)}</span>
              <OutlinedSelect
                name={'career'}
                options={optionsCareer}
                control={control}
                rules={{
                  required: msgValidations.required,
                  validate: {
                    career: (career) => {
                      if (
                        statusPostulation === STATUS_POSTULATION.ENROLLED &&
                        postulationDetail?.admissionId &&
                        postulationDetail.admissionId === career.value
                      )
                        return msgValidationChangeStudyPlan;
                      return undefined;
                    },
                  },
                }}
                disabled={formState.isSubmitting}
              />
            </div>
          </Col>
        </Row>

        <Row className="py-5 justify-content-end">
          <Col xs={12} lg={4} className="pb-2 order-2 order-lg-1">
            <Button
              type="button"
              outlined
              onClick={() => history.push('/tuition-process')}
              text={t(`common.actions.cancel`)}
              loading={formState.isSubmitting}
              fullwidth
            />
          </Col>

          <Col xs={12} lg={4} className="pb-2 order-1 order-lg-2">
            <Button
              type="submit"
              text={t(`common.actions.next`)}
              loading={formState.isSubmitting}
              fullwidth
            />
          </Col>
        </Row>
      </Form>

      <ConfirmationModal
        iconName="warning"
        isOpen={modalChangeCareer}
        toggle={() => setModalChangeCareer(false)}
        title={t(`${prefix}.modalChangeCareer.title`)}
        body={t(`${prefix}.modalChangeCareer.body`)}
        boxDetail={{
          title: t(`${prefix}.modalChangeCareer.detailTitle`),
          body: [currentStudyPlanName],
        }}
        loading={confirming}
        primaryBtn={{
          text: t(`${prefix}.modalChangeCareer.primaryBtn`),
          action: acceptChangeCareer,
        }}
        secondaryBtn={{
          text: t(`${prefix}.modalChangeCareer.secondaryBtn`),
          action: () => setModalChangeCareer(false),
        }}
      />

      <ConfirmationModal
        isOpen={isModalOpen}
        toggle={() => setIsModalOpen(false)}
        title={t(`${prefix}.modalWaitingList.title`)}
        body={t(`${prefix}.modalWaitingList.body`)}
        loading={confirming}
        primaryBtn={{
          text: t(`${prefix}.modalWaitingList.primaryBtn`),
          action: acceptWaitingList,
        }}
        secondaryBtn={{
          text: t(`${prefix}.modalWaitingList.secondaryBtn`),
          action: () => setIsModalOpen(false),
        }}
      />

      <ConfirmationModal
        isOpen={modalQuotaExceed}
        toggle={() => setModalQuotaExceed(false)}
        title={t(`${prefix}.modalQuotaExceed.title`)}
        body={t(`${prefix}.modalQuotaExceed.body`)}
        primaryBtn={{
          text: t(`common.actions.understood`),
          action: () => setModalQuotaExceed(false),
        }}
      />
    </Card>
  );
};

export default SelectCareer;
