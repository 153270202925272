import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { formatDate } from '../utils/dates';

export interface DateRangeProps {
  startDate: string;
  endDate: string;
  setEndDate: (endDate: string) => void;
}

export const useDateRange = (props: DateRangeProps) => {
  const { startDate, endDate, setEndDate } = props;
  const [minDate, setMinDate] = useState(
    startDate ? dayjs(startDate).toDate() : undefined,
  );
  const [endDateDisabled, setEndDateDisabled] = useState(!Boolean(startDate));
  useEffect(() => {
    if (startDate) {
      setEndDateDisabled(false);
      setMinDate(dayjs(startDate).toDate());
    } else {
      setEndDateDisabled(true);
      setMinDate(undefined);
    }
  }, [startDate, setEndDate]);

  useEffect(() => {
    if (minDate && formatDate(minDate, 'YYYY-MM-DD') > endDate) {
      setEndDate('');
    }
  }, [minDate, endDate, setEndDate]);

  return { minDate, endDateDisabled };
};
