import { useCallback, useState, useEffect } from 'react';
import {
  SearchBox,
  SelectOptionType,
  Button,
  Icon,
  useMobile,
} from '@octano/global-ui';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { Form } from 'reactstrap';

import styles from './StudyPlansSearch.module.scss';

export interface SearchBody {
  nameFilter: string;
  schoolFilter: string;
  codeFilter: string;
  statusFilter: SelectOptionType | null;
}

export interface StudyPlansSearchProps {
  onSubmit: (values?: SearchBody) => void;
  isFiltering: boolean;
}

const SELECT_OPTIONS = [
  { label: 'Borrador', value: 'Borrador' },
  { label: 'Activo', value: 'Activo' },
  { label: 'Vigente', value: 'Vigente' },
  { label: 'Cerrado', value: 'Cerrado' },
];

const StudyPlansSearch = (props: StudyPlansSearchProps) => {
  const [statusFilter, setStatusFilter] = useState<SelectOptionType | null>(
    null,
  );

  const [showMobileFilter, setShowMobileFilter] = useState(false);

  const isMobile = useMobile();
  const { onSubmit, isFiltering } = props;
  const { t } = useTranslation();

  const methods = useForm<SearchBody>({
    mode: 'onSubmit',
  });
  const {
    handleSubmit,
    formState: { errors },
    register,
    watch,
  } = methods;

  const lengthValidation = {
    minLength: {
      value: 3,
      message: t('common.validations.minLengthSearch', {
        length: 3,
      }),
    },
  };

  const onChangeSelect = useCallback((value: SelectOptionType | null) => {
    setStatusFilter(value);
  }, []);

  const [nameFilter, codeFilter, schoolFilter] = watch([
    'nameFilter',
    'codeFilter',
    'schoolFilter',
  ]);

  const submit = useCallback(
    (formValues: SearchBody) => {
      const requestBody = {
        ...formValues,
        statusFilter,
      };

      onSubmit(requestBody);
    },
    [statusFilter, onSubmit],
  );

  useEffect(() => {
    if (
      !nameFilter &&
      !codeFilter &&
      !schoolFilter &&
      !statusFilter &&
      isFiltering
    ) {
      onSubmit();
    }
  }, [
    nameFilter,
    codeFilter,
    schoolFilter,
    statusFilter,
    onSubmit,
    isFiltering,
  ]);

  const getErrorMessage = useCallback((value?: string) => {
    if (!value) return null;
    return (
      <small className="g-input-error form-text text-danger">{value}</small>
    );
  }, []);

  return (
    <div className={`${styles.studyPlansSearch} pt-3 d-flex flex-wrap`}>
      {isMobile && (
        <div
          role="button"
          onClick={() => setShowMobileFilter(!showMobileFilter)}
          className={`${styles.toggleSearchMobile} border-secondary text-secondary mb-3 w-100 `}
        >
          <Icon color="secondary" size={13.5} name="filter" />
          <span> {t('studyPlans.list.mobileFilterTitle')}</span>
          <Icon
            className={`${styles.chevron} ${
              showMobileFilter ? styles.rotateIcon : ''
            }`}
            color="secondary"
            size={11}
            name="chevron_down"
          />
        </div>
      )}
      {(!isMobile || showMobileFilter) && (
        <FormProvider {...methods}>
          <Form
            className="w-100 d-flex align-items-start flex-column flex-md-row"
            onSubmit={handleSubmit(submit)}
          >
            <div className={`${styles.inputContainer} pb-2`}>
              <label>{t('common.forms.name')}</label>
              <SearchBox
                value={nameFilter}
                placeholder={t('studyPlans.list.searchPlaceholderName')}
                {...register('nameFilter', lengthValidation)}
              />
              {errors.nameFilter && getErrorMessage(errors.nameFilter.message)}
            </div>
            <div className={`${styles.inputContainer} pb-2`}>
              <label>{t('common.forms.school')}</label>
              <SearchBox
                value={schoolFilter}
                placeholder={t('studyPlans.list.searchPlaceholderSchool')}
                {...register('schoolFilter', lengthValidation)}
              />
              {errors.schoolFilter &&
                getErrorMessage(errors.schoolFilter.message)}
            </div>
            <div className={`${styles.inputContainer} pb-2`}>
              <label>{t('common.forms.status')}</label>
              <Select
                isClearable
                className="default-select"
                label="status"
                value={statusFilter}
                options={SELECT_OPTIONS}
                onChange={onChangeSelect}
                placeholder={t('studyPlans.list.searchPlaceholderStatus')}
              />
            </div>
            <div className={`${styles.inputContainer} pb-2`}>
              <label>{t('common.forms.code')}</label>
              <SearchBox
                value={codeFilter}
                placeholder={t('studyPlans.list.searchPlaceholderCode')}
                {...register('codeFilter', lengthValidation)}
              />
              {errors.codeFilter && getErrorMessage(errors.codeFilter.message)}
            </div>
            <div className={`pb-3 d-flex align-items-center ${styles.submit}`}>
              <Button
                size="md"
                type="submit"
                text={t(`common.actions.search`)}
                fullwidth
                disabled={
                  !nameFilter && !codeFilter && !schoolFilter && !statusFilter
                }
              />
            </div>
          </Form>
        </FormProvider>
      )}
    </div>
  );
};

export default StudyPlansSearch;
