import WaitingList from './WaitingList';
import { useTuitionProcess } from '../../../../hooks/useTuitionProcess';
import StudentFileNoSua from './StudentFileNoSua';
import { STATUS_POSTULATION } from '../../../../types/tuitionProcessOnSite';

const StudentFileNoSuaStep = () => {
  const { statusPostulation } = useTuitionProcess();

  if (statusPostulation === STATUS_POSTULATION.IN_WAITING_LIST) {
    return <WaitingList />;
  }
  return <StudentFileNoSua />;
};

export default StudentFileNoSuaStep;
