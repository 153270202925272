import { SidenavCategoryType, SidenavLinkType } from '@octano/global-ui';
import { PermissionName } from '../types/Auth';

export interface WithPermissions {
  requiredPermissions?: PermissionName[];
  allPermisionsRequired?: boolean;
}

export type LinkWithPermissions = SidenavLinkType & WithPermissions;

export type SubLinkWithPermissions = SidenavCategoryType['links'][number] &
  WithPermissions;

export type CategoryWithPermissions = Omit<SidenavCategoryType, 'links'> & {
  links: SubLinkWithPermissions[];
};

export type SidenavLinksWithPermissions = (
  | LinkWithPermissions
  | CategoryWithPermissions
)[];

export const SIDENAV_LINKS: SidenavLinksWithPermissions = [
  {
    icon: 'maintainer',
    name: 'Estructura Académica',
    links: [
      {
        name: 'Periodos',
        path: '/maintainers/periods',
        requiredPermissions: [
          PermissionName.CREATE_PERIOD,
          PermissionName.EDIT_PERIOD,
        ],
      },
      {
        name: 'Sedes',
        path: '/maintainers/campus',
        requiredPermissions: [
          PermissionName.CREATE_CAMPUS,
          PermissionName.EDIT_CAMPUS,
        ],
      },
      {
        name: 'Facultades',
        path: '/maintainers/schools',
        requiredPermissions: [
          PermissionName.CREATE_FACULTY,
          PermissionName.EDIT_FACULTY,
        ],
      },
      {
        name: 'Jornadas',
        path: '/maintainers/schedules',
        requiredPermissions: [
          PermissionName.CREATE_SCHEDULE,
          PermissionName.EDIT_SCHEDULE,
        ],
      },
      {
        name: 'Modalidades',
        path: '/maintainers/modality',
        requiredPermissions: [
          PermissionName.CREATE_MODALITY,
          PermissionName.EDIT_MODALITY,
        ],
      },
      // {
      //   name: 'Módulo y sus horarios',
      //   path: '/16',
      // },
    ],
  },
  {
    icon: 'academic_offer',
    name: 'Oferta Académica',
    links: [
      {
        name: 'Asignaturas de malla curricular',
        path: '/courses-maintainer',
        requiredPermissions: [PermissionName.SHOW_COURSES],
      },
      {
        name: 'Planes de estudio',
        path: '/study-plans',
        requiredPermissions: [
          PermissionName.CREATE_STUDY_PLAN,
          PermissionName.EDIT_STUDY_PLAN,
          PermissionName.CREATE_STUDY_PLAN_VERSION,
          PermissionName.EDIT_STATUS_VERSION,
          PermissionName.CREATE_MALLA,
          PermissionName.EDIT_MALLA,
          PermissionName.EDIT_STATUS_MENTION,
        ],
      },
      {
        name: 'Oferta de vacantes',
        path: '/academic-offers',
        requiredPermissions: [
          PermissionName.CREATE_ACADEMIC_OFFER,
          PermissionName.EDIT_ACADEMIC_OFFER,
          PermissionName.DELETE_ACADEMIC_OFFER,
        ],
      },
    ],
  },
  {
    icon: 'admision',
    name: 'Admisión y Matrícula',
    links: [
      {
        name: 'Aranceles y descuentos',
        path: '/tariff-tuiton',
        requiredPermissions: [
          PermissionName.CREATE_DISCOUNTS,
          PermissionName.EDIT_DISCOUNTS,
        ],
      },
      {
        name: 'Matrícula presencial',
        path: '/tuition-process',
        requiredPermissions: [PermissionName.ENROLLMENT_PERSON],
      },
      {
        name: 'Gestión de admisión',
        path: '/admissions',
        requiredPermissions: [
          PermissionName.SHOW_ADMISSIONS,
          PermissionName.EDIT_ADMISIONS,
        ],
      },
      {
        name: 'Revisión de documentación',
        path: '/documents',
        requiredPermissions: [PermissionName.SHOW_DOCUMENT_REVIEW],
      },
    ],
  },
  {
    icon: 'tuiton_continuity',
    name: 'Matrícula de Continuidad',
    links: [
      {
        name: 'Aranceles y Matrícula',
        path: '/tuition-continuity-tariff-tuition',
        requiredPermissions: [PermissionName.TUITION_CONTINUITY_VALUES_LIST],
      },
      {
        name: 'Gestión de Matrícula de Continuidad',
        path: '/tuition-continuity-process-management',
        requiredPermissions: [
          PermissionName.TUITION_CONTINUITY_PROCESS_MANAGEMENT_LIST,
        ],
      },
      {
        name: 'Revisión de documentación',
        path: '/tuition-continuity-document-review',
        requiredPermissions: [
          PermissionName.TUITION_CONTINUITY_DOCUMENT_REVIEW_LIST,
        ],
      },
    ],
  },
  {
    icon: 'packages',
    name: 'Secciones de asignaturas',
    links: [
      {
        name: 'Modificación de inscripción',
        path: '/course-registration',
        requiredPermissions: [PermissionName.SHOW_COURSE_REGISTRATION],
      },
      {
        name: 'Modificación de secciones',
        path: '/sections-maintainer',
        requiredPermissions: [PermissionName.SHOW_SECTION_MAINTAINER],
      },
      {
        name: 'Inscripción de paquetes',
        path: '/packages',
        requiredPermissions: [
          PermissionName.SHOW_PACKAGES,
          PermissionName.CREATE_PACKAGES,
          PermissionName.EDIT_PACKAGES,
        ],
      },
      {
        name: 'Ingreso de paquetes y secciones',
        path: '/packages-maintainer',
        requiredPermissions: [
          PermissionName.SHOW_SECTIONS_AND_PACKAGES,
          PermissionName.CREATE_SECTIONS_AND_PACKAGES,
          PermissionName.EDIT_SECTIONS_AND_PACKAGES,
        ],
      },
      {
        name: 'Atributos de sección',
        path: '/section-attributes',
        requiredPermissions: [
          PermissionName.SHOW_SECTION_ATTRIBUTES_LIST,
          PermissionName.GET_SECTION_ATTRIBUTES,
          PermissionName.DOWNLOAD_SECTION_ATTRIBUTES_BASE_INFO,
        ],
      },
      {
        name: 'Cierre de sección',
        path: '/closing-period',
        requiredPermissions: [
          PermissionName.SHOW_LIST_PERIOD_CLOSE_ADMIN_PORTAL,
        ],
      },
      {
        name: 'Modificación de cierre',
        path: '/students-post-close',
        requiredPermissions: [PermissionName.STUDENTS_POST_CLOSE],
      },
    ],
  },
  {
    icon: 'identification',
    name: 'Gestión de cuentas',
    links: [
      {
        name: 'Activación docente',
        path: '/teacher-activation',
        requiredPermissions: [PermissionName.SEARCH_TEACHER],
      },
      {
        name: 'Reportes',
        path: '/reports',
        requiredPermissions: [PermissionName.SHOW_REPORTS],
      },
      // {
      //   name: 'Activación de administrativos',
      //   path: '/53',
      // },
      // {
      //   name: 'Gestión de permisos',
      //   path: '/54',
      // },
      // {
      //   name: 'Asistencia docente',
      //   path: '/55',
      // },
      // {
      //   name: 'Activación del estudiante',
      //   path: '/56',
      // },
    ],
  },
  {
    icon: 'person_record',
    name: 'Gestión del estudiante',
    links: [
      // {
      //   name: 'Expendiente del alumno',
      //   path: '/61',
      // },
      {
        name: 'Estado del alumno',
        path: '/student-status',
        requiredPermissions: [
          PermissionName.SHOW_STUDENT_STATUS,
          PermissionName.CHANGE_STUDENT_STATUS,
        ],
      },
      {
        name: 'Ficha del alumno',
        path: '/student-file',
        requiredPermissions: [
          PermissionName.STUDENT_MANAGEMENT_STUDENT_FILE_UPDATE,
        ],
      },
      {
        name: 'Avance Curricular',
        path: '/avance-curricular',
        requiredPermissions: [PermissionName.STUDENT_CURRICULUM_PROGRESS_LIST],
      },
      {
        name: 'Cambio de Plan de Estudio',
        path: '/enrollment-soft-change',
        requiredPermissions: [PermissionName.EDIT_CHANGE_STUDENTY_PLAN],
      },

      {
        name: 'Solicitudes',
        path: '/student-petitions',
        requiredPermissions: [PermissionName.STUDENTS_PETITIONS_LIST],
      },
      {
        name: 'Egresos Masivos',
        path: '/graduation-process-massive',
        requiredPermissions: [
          PermissionName.SHOW_GRADUATION_PROCESS_MASSIVE,
          PermissionName.LAUNCH_GRADUATION_PROCESS_MASSIVE,
        ],
      },
      {
        name: 'Egresos Individuales',
        path: '/graduation-process-single',
        requiredPermissions: [
          PermissionName.SEARCH_GRADUATE_GRADUATION_PROCESS_SINGLE,
          PermissionName.LAUNCH_GRADUATION_PROCESS_SINGLE,
        ],
      },
      {
        name: 'Lista de egresados',
        path: '/graduates-list',
        requiredPermissions: [PermissionName.SHOW_GRADUATES_LIST],
      },
      {
        name: 'Titulación',
        path: '/students-degree',
        requiredPermissions: [PermissionName.STUDENTS_DEGREE_LIST],
      },
    ],
  },
  {
    icon: 'course_validation',
    name: 'Convalidación',
    links: [
      {
        name: 'Convalidación Interna',
        path: '/convalidacion-interna',
        requiredPermissions: [
          PermissionName.STUDENT_COURSE_VALIDATION_INTERNAL_LIST,
        ],
      },
    ],
  },
  {
    icon: 'power',
    name: 'Carga Inicial',
    links: [
      {
        name: 'Record Académico',
        path: '/record-academico',
        requiredPermissions: [PermissionName.ACADEMIC_RECORD_LOAD],
      },
    ],
  },
  {
    icon: 'settings',
    name: 'Configuración general de parámetros',
    links: [
      // {
      //   name: 'Demográficos',
      //   path: '/config?tab=demographics',
      // },
      {
        name: 'Académicos',
        path: '/config?tab=academics',
        requiredPermissions: [
          PermissionName.UPDATE_SETTINGS_TIME_LESSON,
          PermissionName.CREATE_SETTINGS_GRADE_ENTRY,
        ],
      },
      // {
      //   name: 'Generales',
      //   path: '/config?tab=general',
      // },
      // {
      //   name: 'Financieros',
      //   path: '/config?tab=finantial',
      // },
      // {
      //   name: 'Centro de ayuda',
      //   path: '/config?tab=helpCenter',
      // },
    ],
  },
];
