import { Button } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import ContactSection from '../../../../components/form/ContactSection';

import {
  BackgroundSectionType,
  EducationalBackgroundType,
} from '../../../../types/studentFileSectionTypes';
import EmergencyContactSection from '../../../TuitionProcess/parts/StudentFile/EmergencyContactSection';
import HealthCareSection from '../../../TuitionProcess/parts/StudentFile/HealthCareSection';
import ParentalEducationSection from '../../../TuitionProcess/parts/StudentFile/ParentalEducationSection';
import EducationalBackgroundNoSua from './EducationalBackgroundNoSua';
import StudentBackgroundNoSua from './StudentBackgroundNoSua';

type StudentFileNoSuaFormComponentsProps = {
  postulantId?: string | number;
  backgroundData?: BackgroundSectionType;
  educationalBackground?: EducationalBackgroundType;
  prefix: string;
  isSubmitting?: boolean;
  isStudentFileUpdate?: boolean;
  saveI18nKey?: string;
  onPressCancel?: () => void;
};

const StudentFileNoSuaFormComponents = ({
  postulantId,
  backgroundData,
  educationalBackground,
  isSubmitting,
  isStudentFileUpdate,
  prefix,
  saveI18nKey = 'common.actions.next',
  onPressCancel,
}: StudentFileNoSuaFormComponentsProps) => {
  const { t } = useTranslation();

  return (
    <>
      {/* SECCIONES DEL FORMULARIO */}
      <StudentBackgroundNoSua
        data={backgroundData}
        postulantId={postulantId}
        isStudentFileUpdate={isStudentFileUpdate}
      />
      <EducationalBackgroundNoSua
        educationalBackground={educationalBackground}
        isStudentFileUpdate={isStudentFileUpdate}
      />

      <ContactSection
        prefix={`${prefix}.academicContactSection`}
        optionalFields={['addressExtra', 'phone']}
      />
      <ContactSection
        prefix={`${prefix}.familyGroupSection`}
        fieldNames={{
          addressStreet: 'addressStreetFamily',
          addressNumber: 'addressNumberFamily',
          addressExtra: 'addressExtraFamily',
          region: 'regionFamily',
          commune: 'communeFamily',
          phone: 'phoneFamily',
          cellphone: 'cellphoneFamily',
          email: 'emailFamily',
        }}
      />
      <EmergencyContactSection prefix={prefix} />
      <ParentalEducationSection prefix={prefix} />
      <HealthCareSection prefix={prefix} />
      <Row className="py-5 justify-content-end">
        <Col xs={12} lg={4} className="pb-2 order-2 order-lg-1">
          <Button
            type="button"
            outlined
            onClick={onPressCancel}
            text={t(`common.actions.cancel`)}
            loading={isSubmitting}
            fullwidth
          />
        </Col>

        <Col xs={12} lg={4} className="pb-2 order-1 order-lg-2">
          <Button
            type="submit"
            text={t(saveI18nKey)}
            loading={isSubmitting}
            fullwidth
          />
        </Col>
      </Row>
    </>
  );
};

export default StudentFileNoSuaFormComponents;
