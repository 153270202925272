import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { getModalitiesRequest } from '../../api/requests/modalities';
import { ModalityBody } from '../../types/ModalityTypes';
import { PaginationRequestType } from '../../types/paginationRequestType';
import { Table } from '@octano/global-ui';
import { TextEllipsis } from '../../components/text';
import { useLoadingState } from '../../hooks/useLoadingState';
import DisplayError from '../../components/info/DisplayError';
import { ModalityForm } from './ModalityForm';
import styles from './ModalityList.module.scss';

import {
  CellFormatOptions,
  PaginationType,
} from '@octano/global-ui/dist/components/Table/types/TableTypes';
import { useLayoutState } from '../../hooks/useLayoutState';
import { useTranslation } from 'react-i18next';
import TableEmptyContent from '../../components/text/TableEmptyContent';
import Loading from '../../components/info/Loading';

const ITEMS_PER_PAGE_DEFAULT = 10;
const PAGE_INITIAL_DEFAULT = 1;


export const ModalityList = () => {
  const { showTabs } = useLayoutState();
  const { t } = useTranslation();

  useEffect(() => {
    showTabs();
  }, [showTabs]);

  const [modalities, setModalities] = useState<PaginationRequestType<ModalityBody>>({
    data: [],
    total_pages: 0,
    total: 0,
  });

  const [currentPage, setCurrentPage] = useState<number>(PAGE_INITIAL_DEFAULT);
  const [itemsPerPage] = useState<number>(ITEMS_PER_PAGE_DEFAULT);
  const { loading, setLoading, errorLoading, setErrorLoading } =
    useLoadingState();
  const getModalities = useCallback(async () => {
    const { error, data: response } = await getModalitiesRequest(
      itemsPerPage,
      currentPage - 1,
    );
    if (response) {
      setModalities(response);
      setErrorLoading(undefined);
    } else if (error) {
      setErrorLoading(error.code);
      return;
    }
    setLoading(false);
  }, [itemsPerPage, currentPage, setErrorLoading, setLoading]);

  const handlerOnChangePage = useCallback(
    async (page: number) => setCurrentPage(page),
    [],
  );

  const editModalitiesList = useCallback(
    (modality: ModalityBody) => {
      let modalitiesToUpdate = JSON.parse(JSON.stringify(modalities.data));
      const index = modalitiesToUpdate.findIndex(
        (e: ModalityBody) => e.id === modality.id,
      );

      if (index !== -1) {
        modalitiesToUpdate[index] = modality;
      } else {
        modalitiesToUpdate = [...modalitiesToUpdate, modality];
      }
      const newModalities = {
        ...modalities,
        data: modalitiesToUpdate,
      };
      setModalities(newModalities);
    },
    [modalities],
  );

  const pagination = useMemo<PaginationType | undefined>(() => {
    if (modalities.total > itemsPerPage) {
      return {
        totalItems: modalities.total,
        itemsPerPage: itemsPerPage,
        totalPages: modalities.total_pages,
        currentPage: currentPage,
        onChangePage: handlerOnChangePage,
      };
    }
  }, [modalities, itemsPerPage, currentPage, handlerOnChangePage]);
  // columnas a mostrar
  const columns = [
    {
      columnName: 'code',
      headerText: t('common.forms.code'),
      bold: true,
      width: '20%',
    },
    {
      columnName: 'name',
      headerText: t('common.forms.name'),
      width: '20%',
    },
    {
      columnName: 'description',
      headerText: t('common.forms.description'),
      cellFormat: (options: CellFormatOptions) => (
        <TextEllipsis key={options.index} textWidth={350}>
          {options.value}
        </TextEllipsis>
      ),
    },
    {
      columnName: 'id',
      headerText: '',
      width: '40px',
      cellFormat: (options: CellFormatOptions) => (
        <ModalityForm
          recordToEdit={options.row}
          editModalitiesList={editModalitiesList}
        />
      ),
    },
  ];

  useEffect(() => {
    getModalities();
  }, [getModalities]);

  if (errorLoading) {
    return (
      <DisplayError
        insideCard
        textBody={errorLoading}
        retryAction={() => setLoading(true)}
        loadingAction={loading}
      />
    );
  }
  if (loading) {
    return <Loading insideCard />;
  }
  return (
    <div className='g-table-container'>
      <ModalityForm editModalitiesList={editModalitiesList} isCreate />

      <div id={styles.modalityTable}>
        <Table
          columns={columns}
          data={modalities.data}
          pagination={pagination}
          noResultsText={
            <TableEmptyContent
              title={t('maintainers.modalityList.modalityTableEmptyTitle')}
              subtitle={t(
                'maintainers.modalityList.modalityTableEmptySubtitle',
              )}
            />
          }
        />
      </div>
    </div>
  );
};
