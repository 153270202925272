import { useReducer, createContext, FunctionComponent } from 'react';
import tuitionProcessReducer, {
  initialState,
} from '../reducers/tuitionProcessReducer';
import { TuitionProcessContextType } from '../types/tuitionProcessTypes';

export const TuitionProcessContext = createContext(
  {} as TuitionProcessContextType,
);

const TuitionProcessInfoProvider: FunctionComponent = ({ children }) => {
  const [state, dispatch] = useReducer(tuitionProcessReducer, initialState);

  return (
    <TuitionProcessContext.Provider value={{ state, dispatch }}>
      {children}
    </TuitionProcessContext.Provider>
  );
};

export default TuitionProcessInfoProvider;
