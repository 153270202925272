import { TablePagination } from '@octano/global-ui';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

import { getAdmissionDetail } from '../../api/requests/studyPlans';
import { getPostulansPendingRequest } from '../../api/requests/studyPlansOffer';
import { Card } from '../../components/article/Card';
import DisplayError from '../../components/info/DisplayError';
import Loading from '../../components/info/Loading';
import TableEmptyContent from '../../components/text/TableEmptyContent';
import { PathsLayouts } from '../../config/routes';
import { useLoadingState } from '../../hooks/useLoadingState';

interface PlanesStudentCards {
  id: number;
  name: string;
  campus_name: string;
  scheduleName: string;
  quotas: number;
  inProcess: number;
  enrolled: number;
  waitingList: number;
  withDrawn: number;
  retracted: number;
  occupiedSeats: number;
}

const ITEMS_PER_PAGE_DEFAULT = 11;

export function AdmissionsPlanesStudentCards({
  periodId,
  campusId,
  schoolId,
}: {
  periodId: number;
  campusId?: number;
  schoolId?: number;
}): JSX.Element {
  const { t } = useTranslation();
  const { loading, setLoading, errorLoading, setErrorLoading } =
    useLoadingState();

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalItems, setTotalItems] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);

  let [planesStudentCards, setPlanesStudentCards] = useState<
    PlanesStudentCards[]
  >([]);

  let [postulansPendint, setPostulansPendint] = useState<number>(0);

  const getPlanesStudent = useCallback(
    async (currentPage = 1) => {
      let { data, error } = await getAdmissionDetail({
        periodId,
        campusId,
        schoolId,
        items_per_page: ITEMS_PER_PAGE_DEFAULT,
        page: currentPage,
      });
      if (error) {
        setErrorLoading(error.code);
      } else if (data) {
        setErrorLoading(undefined);
        let planes = data.data.map(
          ({
            AcademicOfferStudyPlanVersionOffer_id,
            StudyPlan_name,
            Campus_name,
            Schedule_name,
            quota,
            in_process,
            enrolled,
            waiting,
            desisted,
            retracted,
            occupation_percentage,
          }) => {
            return {
              id: AcademicOfferStudyPlanVersionOffer_id,
              campus_name: Campus_name,
              name: StudyPlan_name,
              scheduleName: Schedule_name,
              quotas: quota,
              inProcess: in_process,
              enrolled: enrolled,
              waitingList: waiting,
              withDrawn: desisted,
              retracted: retracted,
              occupiedSeats: occupation_percentage,
            };
          },
        );
        setPlanesStudentCards([...planes]);
        setTotalItems(data.total);
        setTotalPages(data.total_pages);
      }
    },
    [periodId, campusId, schoolId, setErrorLoading],
  );

  const saveStorageAdmissionInfo = useCallback(
    ({ name, campus_name, scheduleName }) => {
      localStorage.setItem(
        'admission-info',
        JSON.stringify({
          name,
          campus_name,
          scheduleName,
        }),
      );
    },
    [],
  );

  const getPostulansPendint = useCallback(async () => {
    let { data, error } = await getPostulansPendingRequest();
    if (error) {
      setErrorLoading(error.code);
    } else if (data) {
      setPostulansPendint(data.data);
    }
  }, [setErrorLoading]);

  const getStudentyPlanesAndPostulansPendint = useCallback(
    async (currentPage = 1) => {
      setLoading(true);
      await getPlanesStudent(currentPage);
      await getPostulansPendint();
      setLoading(false);
    },
    [getPlanesStudent, getPostulansPendint, setLoading],
  );

  useEffect(() => {
    getStudentyPlanesAndPostulansPendint();
    setCurrentPage(1);
  }, [getStudentyPlanesAndPostulansPendint]);

  useEffect(() => {
    if (currentPage !== 1) {
      getStudentyPlanesAndPostulansPendint(currentPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  if (loading) {
    return <Loading insideCard />;
  }

  if (errorLoading) {
    return (
      <DisplayError
        insideCard
        textBody={errorLoading}
        retryAction={() => setLoading(true)}
        loadingAction={loading}
      />
    );
  }

  if (planesStudentCards.length === 0) {
    return (
      <div
        className="p-5"
        style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
      >
        <TableEmptyContent
          title={t(
            'academicOffers.form.AdmissionsPlanesStudentCardsTableEmptyTitle',
          )}
          subtitle={t(
            'academicOffers.form.AdmissionsPlanesStudentCardsTableEmptySubtitle',
          )}
        />
      </div>
    );
  }

  return (
    <>
      <Row>
        <Col md={3} className="mb-4">
          <Card
            title={t('admission.postulansPending.title')}
            markedText={{
              label: t('admission.postulansPending.pendings'),
              value: `${postulansPendint}`,
            }}
            to={`${PathsLayouts.admissions}/postulans-pending`}
          />
        </Col>
        {planesStudentCards.map(
          (
            {
              id,
              name,
              campus_name,
              scheduleName,
              quotas,
              inProcess,
              enrolled,
              waitingList,
              withDrawn,
              retracted,
              occupiedSeats,
            },
            key,
          ) => {
            return (
              <Col md={3} key={key} className="mb-4">
                <Card
                  title={name}
                  subtitle={`${campus_name} - ${scheduleName}`}
                  body={[
                    {
                      label: t('admissions.sectionPlanesStudentCards.quotas'),
                      value: quotas,
                    },
                    {
                      label: t(
                        'admissions.sectionPlanesStudentCards.inProcess',
                      ),
                      value: inProcess,
                    },
                    {
                      label: t('admissions.sectionPlanesStudentCards.enrolled'),
                      value: enrolled,
                    },
                    {
                      label: t(
                        'admissions.sectionPlanesStudentCards.waitingList',
                      ),
                      value: waitingList,
                    },
                    {
                      label: t(
                        'admissions.sectionPlanesStudentCards.withDrawn',
                      ),
                      value: withDrawn,
                    },
                    {
                      label: t(
                        'admissions.sectionPlanesStudentCards.retracted',
                      ),
                      value: retracted,
                    },
                  ]}
                  markedText={{
                    label: t(
                      'admissions.sectionPlanesStudentCards.occupiedPlaces',
                    ),
                    value: `${
                      occupiedSeats < 100
                        ? occupiedSeats.toFixed(1)
                        : Math.round(occupiedSeats)
                    }%`,
                  }}
                  to={`${PathsLayouts.admissions}/${id}/in-process`}
                  onClick={() =>
                    saveStorageAdmissionInfo({
                      name,
                      scheduleName,
                      campus_name,
                    })
                  }
                />
              </Col>
            );
          },
        )}
      </Row>
      {totalPages > 1 && (
        <TablePagination
          pagination={{
            currentPage: currentPage,
            itemsPerPage: ITEMS_PER_PAGE_DEFAULT,
            onChangePage: (page) => {
              setCurrentPage(page);
            },
            totalItems: totalItems,
            totalPages: totalPages,
          }}
        />
      )}
    </>
  );
}
