import { useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Form } from 'reactstrap';
import { addToast } from '@octano/global-ui';
import { format as formatRut } from 'rut.js';
import { getDate } from '../utils/dates';
import {
  changeStatusToRetracted,
  changeToDesist,
} from '../api/requests/studyPlansOffer';
import {
  createPostulant,
  CreatePostulantBody,
  putPostulants,
} from '../api/requests/postulants';
import { useHistory } from 'react-router-dom';
import { showToast } from '../utils/toast';
import TuitionProcessFormFields from './parts/TuitionProcessFormFields';
import { Student, StudentForm } from '../types/tuitionProcessOnSite';
import TuitionProcessFormHeader from './parts/TuitionProcessFormHeader';
import { useActionForm } from './parts/useActionForm';
import { ModalConfirmation } from '../components/modals/Confirmation';
import { ModalConfirmRetract } from '../components/modals/ModalConfirmRetract';
import ConfirmationModal from '../components/modals/ConfirmationModal';
import { desistStudyPlanChange } from '../api/requests/tuitionProcess';
import NoResultsModal from '../views/Reports/parts/NoResultsModal';

interface TuitionProcessFormProps {
  student: Student;
  passport: boolean;
  preload: () => void;
}

enum RetractedModalEnum {
  None,
  Confirm,
  Empty,
  Out,
}

const prefix = 'tuitionProcess.form';

export function TuitionProcessForm({
  passport,
  student,
  preload,
}: TuitionProcessFormProps): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();
  const actionsForm = useActionForm(student.admissionTypeId);

  const {
    getActionForm,
    modalConfirmOpen,
    setModalConfirmOpen,
    setSubmitLoad,
  } = actionsForm;

  const [openModalDesist, setOpenModalDesist] = useState<boolean>(false);
  const [confirmChangeEnrolled, setConfirmChangeEnrolled] =
    useState<boolean>(false);
  const [retractedModal, setRetractedModal] = useState<RetractedModalEnum>(
    RetractedModalEnum.None,
  );

  const validateRetracted = useCallback(async () => {
    let { status } = await changeStatusToRetracted(student.Details_id ?? 0, {
      showWarning: true,
    });
    switch (status) {
      case 200:
        setRetractedModal(RetractedModalEnum.Confirm);
        break;
      case 204:
        setRetractedModal(RetractedModalEnum.Empty);
        break;
      case 400:
        setRetractedModal(RetractedModalEnum.Out);
        break;
      default:
        setRetractedModal(RetractedModalEnum.None);
    }
  }, [student.Details_id]);

  const actionForm = getActionForm(
    student.newAdmission
      ? student.newAdmission.postulationDetailStatus
      : student.status,
    () => validateRetracted(),
    () => setConfirmChangeEnrolled(true),
    () => goToPostulation(false),
    () => setOpenModalDesist(true),
  );

  const defaultValues = Object.assign({
    rut: formatRut(student.rut),
    passportNumber: student.passportNumber,
    country: student.country,
    names: student.names,
    lastnamePather: student.lastnamePather,
    lastnameMother: student.lastnameMother,
    email: student.email,
    phone: student.phone,
    birthdate: student.birthdate && getDate(student.birthdate),
  });

  const methods = useForm<StudentForm>({
    mode: 'onSubmit',
    defaultValues,
  });
  const { handleSubmit } = methods;

  const goToPostulation = useCallback(
    (fromStart: boolean, postulantId?: number) => {
      let url = `/tuition-process/postulation/${
        postulantId || student.postulant_id
      }/${student.newAdmission?.postulationDetailId ?? student.Details_id}/${
        student.admissionTypeId || 'SIN_SUA'
      }/${fromStart}`;
      history.push(url);
    },
    [history, student],
  );

  const createNewPostulant = useCallback(
    async (values: StudentForm) => {
      let postulant: CreatePostulantBody = {
        type: student.rut && student.rut !== '' ? 'rut' : 'passport',
        names: values.names,
        rut: student.rut !== '' ? student.rut : undefined,
        passportNumber: student.passportNumber,
        passportCountry: values.country,
        birthday: values.birthdate
          ? new Date(values.birthdate).toISOString().split('T')[0]
          : '',
        paternalLastName: values.lastnamePather,
        maternalLastName: values.lastnameMother,
        paternalPreference: true,
        email: values.email,
        phone: values.phone,
      };
      const { data, error } = await createPostulant(postulant);
      if (data) {
        showToast(false, t);
        goToPostulation(false, data.data.id);
      }
      if (error) {
        showToast(true, t);
      }
    },
    [goToPostulation, student.passportNumber, student.rut, t],
  );

  const onSubmit = useCallback(
    async (values: StudentForm, e: any) => {
      let btnValue = e?.nativeEvent?.submitter?.value;
      setSubmitLoad(true);
      // Si existe el id del postulante se modifica la información
      if (student.postulant_id) {
        let { error } = await putPostulants(student.postulant_id, {
          names: values.names,
          maternalLastName: values.lastnameMother,
          paternalLastName: values.lastnamePather,
          birthday: values.birthdate
            ? new Date(values.birthdate).toISOString().split('T')[0]
            : '',
          email: values.email,
          phone: values.phone,
        });
        if (error) {
          showToast(true, t);
        } else {
          showToast(false, t);
          goToPostulation(btnValue === 'true');
        }
      } else {
        // Si no existe se crea un postulante nuevo
        createNewPostulant(values);
      }
      setSubmitLoad(false);
    },
    [createNewPostulant, goToPostulation, student, t, setSubmitLoad],
  );

  async function onConfirmDesisted() {
    setModalConfirmOpen(false);
    if (student.Details_id) {
      let { error } = await changeToDesist(student.Details_id);
      if (error) {
        showToast(true, t);
      } else {
        showToast(false, t);
        preload();
      }
    }
  }

  function onCloseDesisted() {
    setModalConfirmOpen(false);
  }

  const onConfirmDesist = async () => {
    if (student.newAdmission?.postulationDetailId) {
      const { data, error } = await desistStudyPlanChange(
        student.newAdmission?.postulationDetailId,
      );
      if (data) {
        addToast({
          icon: 'success',
          color: 'success',
          text: t(`${prefix}.desistAdmissionMessage`),
        });
      }
      if (error) {
        addToast({
          icon: 'error',
          color: 'danger',
          text: t('common.errors.save'),
        });
      }
    } else {
      addToast({
        icon: 'error',
        color: 'danger',
        text: t('common.errors.save'),
      });
    }
    preload();
    onCloseModalDesist();
  };

  const onCloseModalDesist = () => {
    setOpenModalDesist(false);
  };

  return (
    <div>
      <TuitionProcessFormHeader student={student} actionForm={actionForm} />

      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <TuitionProcessFormFields
            student={student}
            disabledInputs={actionForm.disabledInputs}
            passport={passport}
          />
          <div className="mt-4">{actionForm.actions}</div>
        </Form>
      </FormProvider>

      <ModalConfirmation
        open={modalConfirmOpen}
        title={t(`${prefix}.modalConfirm.title`)}
        subtitle={t(`${prefix}.modalConfirm.subtitle`)}
        onClose={onCloseDesisted}
        onConfirm={onConfirmDesisted}
      />

      <ModalConfirmation
        open={confirmChangeEnrolled}
        title={t(`${prefix}.modalConfirmChange.title`)}
        subtitle={t(`${prefix}.modalConfirmChange.subtitle`)}
        onClose={() => setConfirmChangeEnrolled(false)}
        onConfirm={() => {
          setConfirmChangeEnrolled(false);
          goToPostulation(true);
        }}
      />

      <ConfirmationModal
        iconName="warning"
        isOpen={openModalDesist}
        toggle={onCloseModalDesist}
        title={t(`${prefix}.modalDesist.title`)}
        body={t(`${prefix}.modalDesist.subtitle`)}
        boxDetail={{
          title: t(`${prefix}.modalDesist.detailTitle`),
          body: [student.studyPlanVersion_name ?? ''],
        }}
        primaryBtn={{
          text: t(`common.actions.confirm`),
          action: onConfirmDesist,
        }}
        secondaryBtn={{
          text: t(`common.actions.cancel`),
          action: onCloseModalDesist,
        }}
      />

      {/* Retractado */}

      <ModalConfirmRetract
        open={retractedModal === RetractedModalEnum.Confirm}
        postulationDetailId={student.Details_id ?? 0}
        onClose={() => {
          setRetractedModal(RetractedModalEnum.None);
          preload();
        }}
        onConfirm={() => setRetractedModal(RetractedModalEnum.None)}
        student={`${student.names} ${student.lastnamePather} ${student.lastnameMother}`}
      />
      <NoResultsModal
        isOpen={retractedModal === RetractedModalEnum.Out}
        icon="error"
        texts={{
          title: t('admission.modalRetractOutEvent.title'),
          description: t('admission.modalRetractOutEvent.description'),
        }}
        onConfirm={() => setRetractedModal(RetractedModalEnum.None)}
      />
      <NoResultsModal
        isOpen={retractedModal === RetractedModalEnum.Empty}
        icon="error"
        texts={{
          title: t('admission.modalRetractNotEvent.title'),
          description: t('admission.modalRetractNotEvent.description'),
        }}
        onConfirm={() => setRetractedModal(RetractedModalEnum.None)}
      />
    </div>
  );
}
