import {
  addToast,
  Button,
  Icon,
  Modal,
  TextAreaInput,
  TextInput,
} from '@octano/global-ui';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'reactstrap';
import { saveModalityRequest } from '../../api/requests/modalities';
import { CardTitle } from '../../components/text';
import { useLayoutState } from '../../hooks/useLayoutState';
import useUserState from '../../hooks/useUserState';
import { useValidations } from '../../hooks/useValidations';
import { ModalityBody } from '../../types/ModalityTypes';

import { PermissionName } from '../../types/Auth';

export interface Modality {
  code: string;
  name: string;
  description: string;
}

interface ModalityFormProps {
  isCreate?: boolean;
  editModalitiesList: Function;
  recordToEdit?: ModalityBody;
}

export const ModalityForm = ({
  isCreate,
  editModalitiesList,
  recordToEdit,
}: ModalityFormProps) => {
  const { isAuthorizedTo } = useUserState();
  const { t } = useTranslation();
  const { showErrorModal } = useLayoutState();

  const methods = useForm<Modality>({
    mode: 'onSubmit',
    defaultValues: {
      code: recordToEdit?.code || '',
      name: recordToEdit?.name || '',
      description: recordToEdit?.description || '',
    },
  });

  const [showModalityForm, setShowModalityForm] = useState<boolean>(false);
  const [wasEditedForm, setWasEditedForm] = useState<boolean>(false);

  const isEditionMode = !!recordToEdit;

  const {
    handleSubmit,
    formState: { isSubmitting },
    control,
    reset,
    setError,
    setValue,
    watch,
  } = methods;

  const formValues = watch(['code', 'name', 'description']);

  useEffect(() => {
    if (!!recordToEdit) {
      const [newCode, newName, newDescription] = formValues;
      const { code, name, description } = recordToEdit;
      const wasEditedCode = newCode !== code;
      const wasEditedName = newName !== name;
      const wasEditedDescription = newDescription !== description;

      if (
        !wasEditedForm &&
        (wasEditedCode || wasEditedName || wasEditedDescription)
      ) {
        setWasEditedForm(true);
      } else if (
        wasEditedForm &&
        !wasEditedCode &&
        !wasEditedName &&
        !wasEditedDescription
      ) {
        setWasEditedForm(false);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues]);

  useEffect(() => {
    if (showModalityForm && recordToEdit) {
      setValue('code', recordToEdit.code);
      setValue('name', recordToEdit.name);
      if (recordToEdit.description) {
        setValue('description', recordToEdit.description);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModalityForm]);

  const { validateTextNotEmpty } = useValidations();

  const onSubmit = async (formValues: Modality) => {
    const requestBody = {
      ...formValues,
      ...(isEditionMode && recordToEdit ? { id: recordToEdit.id } : {}),
    };

    const { data, error } = await saveModalityRequest(requestBody);
    if (error && error.code === 'CONNECTION') {
      showErrorModal();
    } else if (error && error.code === 'HTTP_ERROR') {
      if (error.status === 400) {
        setError('code', {
          message: t('common.errors.codeError', { code: formValues.code }),
        });
      } else {
        addToast({
          icon: 'error',
          color: 'danger',
          text: t('common.errors.save'),
        });
      }
    } else if (data) {
      editModalitiesList(data.data);
      closeModal();
      addToast({
        icon: 'success',
        color: 'success',
        text: t(
          isEditionMode
            ? 'common.messages.editSuccess'
            : 'modality.createSuccess',
        ),
      });
    }
  };

  const handleEditClick = () => {
    if (isAuthorizedTo([PermissionName.EDIT_MODALITY])) {
      setShowModalityForm(true);
    }
  };

  const closeModal = () => {
    setShowModalityForm(false);
    setWasEditedForm(false);
    reset();
  };

  return (
    <>
      {isCreate ? (
        <Button
          text={t('modality.createTitle')}
          type="button"
          size="sm"
          icon="plus"
          className="g-add-button mb-3 mt-3"
          onClick={() => setShowModalityForm(true)}
          disabled={!isAuthorizedTo([PermissionName.CREATE_MODALITY])}
        />
      ) : (
        <span onClick={handleEditClick}>
          <Icon
            name="edit"
            key="edit"
            color="secondary"
            className={clsx(
              !isAuthorizedTo([PermissionName.EDIT_MODALITY]) &&
                'icon-disabled',
            )}
          />
        </span>
      )}

      <Modal
        isOpen={showModalityForm}
        toggle={() => {
          setShowModalityForm(!showModalityForm);
        }}
        size="lg"
      >
        <FormProvider {...methods}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col>
                <div className="text-center">
                  <CardTitle
                    children={t(
                      isEditionMode
                        ? 'modality.formEditTitle'
                        : 'modality.createTitle',
                    )}
                  />
                </div>
                <Row className="mt-0 mt-md-5">
                  <Col xs="12" md="6">
                    <TextInput
                      name="code"
                      label={t('common.forms.code')}
                      control={control}
                      rules={{
                        validate: validateTextNotEmpty,
                        pattern: {
                          value: /^[0-9a-zA-Z]+$/,
                          message: t('common.validations.invalidAlphanumeric'),
                        },
                      }}
                    />
                  </Col>
                  <Col xs="12" md="6">
                    <TextInput
                      name="name"
                      label={t('common.forms.name')}
                      control={control}
                      rules={{
                        validate: validateTextNotEmpty,
                        minLength: {
                          value: 3,
                          message: t('common.validations.minLength', {
                            length: 3,
                          }),
                        },
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <TextAreaInput
                      name="description"
                      label={`${t('common.forms.description')} ${t(
                        'common.forms.optionalLabel',
                      )}`}
                      control={control}
                      height={200}
                      placeholder={t(`common.forms.descriptionPlaceholder`)}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col className="mt-5">
                <Row>
                  <Col xs={{ size: 12, order: 2 }} md={{ size: 6, order: 1 }}>
                    <Button
                      type="button"
                      text={t('common.actions.cancel')}
                      outlined
                      onClick={closeModal}
                      fullwidth
                    />
                  </Col>
                  <Col xs={{ size: 12, order: 1 }} md={{ size: 6, order: 2 }}>
                    <Button
                      type="submit"
                      text={t(
                        isEditionMode
                          ? 'common.actions.saveChanges'
                          : 'modality.create',
                      )}
                      loading={isSubmitting}
                      fullwidth
                      className="mb-3 mb-md-0"
                      disabled={isEditionMode && !wasEditedForm}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </FormProvider>
      </Modal>
    </>
  );
};
