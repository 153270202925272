import { Modal, Icon } from '@octano/global-ui';

interface Props {
  isOpen: boolean;
  title: string;
  description: string;
}

export default function LoadingModal({ isOpen, title, description }: Props) {
  return (
    <Modal isOpen={isOpen} toggle={() => null}>
      <div className="d-flex flex-column justify-content-center align-items-center">
        <div className="d-flex justify-content-center mb-5">
          <Icon className="spin" name="circle" color="secondary" size="51px" />
        </div>
        <h1 className="text-dark text-center fw-600 fs-22 mb-2">{title}</h1>
        <p className=" text-center fs-18 lh-30 mt-2 mb-4">{description}</p>
      </div>
    </Modal>
  );
}
