import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, FormText, Row } from 'reactstrap';
import { IdentityTypes } from '../../../../types/signDocumentationTypes';
import { useTuitionProcess } from '../../TuitionProcessContext';
import IdentityCard from './IdentityCard';

export type IdentityCardProps = {
  identityCards: {
    id: IdentityTypes;
    isUploaded: boolean;
  }[];
  error?: string;
  onChange: (id: IdentityTypes) => void;
};

/**
 * Listado de CI
 */
const IdentityCards = ({
  identityCards,
  error,
  onChange,
}: IdentityCardProps) => {
  const { prefix: translationPrefix } = useTuitionProcess();
  const prefix = `${translationPrefix}.sign.identityCard`;
  const { t } = useTranslation();
  return (
    <Fragment>
      <span className="text-uppercase fs-18 fw-600 text-secondary">
        {t(`${prefix}.title`)}
      </span>
      <div className="py-2 mt-2">
        <Row>
          {identityCards.map((identityCard, index) => (
            <Col xs={12} key={index}>
              <IdentityCard
                type={identityCard.id}
                name={t(`${prefix}.${identityCard.id}.name`)}
                isUploaded={identityCard.isUploaded}
                btnUpload={t(`${prefix}.${identityCard.id}.btnUpload`)}
                btnUploaded={t(`${prefix}.${identityCard.id}.btnUploaded`)}
                onChange={() => onChange(identityCard.id)}
              />
            </Col>
          ))}
        </Row>
        <FormText className="g-input-error" color="danger">
          {error || <>&nbsp;</>}
        </FormText>
      </div>
    </Fragment>
  );
};

export default IdentityCards;
