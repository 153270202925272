import { addToast, Button, CellFormatOptions } from '@octano/global-ui';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  changeStatusToRetracted,
  getStatusStudyPlanChange,
} from '../../../api/requests/studyPlansOffer';
import useUserState from '../../../hooks/useUserState';
import { PermissionName } from '../../../types/Auth';
import {
  AdmissionsStudentEnrolled,
  TableColumns,
} from '../AdmissionsListEnrolled';

export const useColumnsAdmissionListEnrolled = () => {
  const { t } = useTranslation();
  const { isAuthorizedTo } = useUserState();
  const [student, setStudent] = useState<string>('');
  const [selectRetractedID, setSelectRetractedID] = useState<number>();
  const [changeStudyPlan, setChangeStudyPlan] =
    useState<{ detailId: number; activeProcess: number | null }>();
  const [showRetractedModal, setRetractedModal] = useState<
    'none' | 'confirm' | 'empty' | 'out'
  >('none');

  const validateRetracted = useCallback(
    async (postulationDetailId: number, names: string, lastnames) => {
      let { status } = await changeStatusToRetracted(postulationDetailId, {
        showWarning: true,
      });
      switch (status) {
        case 200:
          setSelectRetractedID(postulationDetailId);
          setStudent(`${names} ${lastnames}`);
          setRetractedModal('confirm');
          break;
        case 204:
          setRetractedModal('empty');
          break;
        case 400:
          setRetractedModal('out');
          break;
        default:
          setRetractedModal('none');
      }
    },
    [],
  );
  const validateStudyPlanChange = useCallback(
    async (detailId: number) => {
      const { data, error } = await getStatusStudyPlanChange(detailId);
      if (data) {
        setChangeStudyPlan({
          detailId,
          activeProcess: data.studyPlanChangeDetail
            ? data.studyPlanChangeDetail.id
            : null,
        });
      }
      if (error) {
        addToast({
          icon: 'error',
          color: 'danger',
          text: t('common.displayError.title'),
        });
      }
    },
    [t],
  );

  const columns: TableColumns[] = useMemo(() => {
    return [
      {
        columnName: 'rut',
        headerText: t(`common.forms.rut`),
        width: '10%',
      },
      {
        columnName: 'names',
        headerText: t(`common.forms.names`),
        width: '10%',
      },
      {
        columnName: 'lastnames',
        headerText: t(`common.forms.lastName`),
        width: '10%',
      },
      {
        columnName: 'phone',
        headerText: t(`common.forms.phone`),
        width: '10%',
      },
      {
        columnName: 'email',
        headerText: t(`common.forms.email`),
        width: '10%',
      },
      {
        columnName: 'dateEnrolled',
        headerText: t(`common.forms.dateEnrolled`),
        width: '10%',
      },
      {
        columnName: 'action',
        headerText: '',
        width: '10%',
        cellFormat: ({ row }: CellFormatOptions<AdmissionsStudentEnrolled>) => {
          return (
            <div className="d-flex justify-content-center">
              <Button
                text={t('admissions.retracted')}
                size="sm"
                outlined
                color="danger"
                onClick={() =>
                  validateRetracted(row.id, row.names, row.lastnames)
                }
                disabled={!isAuthorizedTo([PermissionName.EDIT_ADMISIONS])}
              />
              <Button
                className="ml-2"
                text={t('admissions.changeStudyPlan')}
                size="sm"
                onClick={() => validateStudyPlanChange(row.id)}
              />
            </div>
          );
        },
      },
    ];
  }, [isAuthorizedTo, t, validateRetracted, validateStudyPlanChange]);

  return {
    columns,
    student,
    selectRetractedID,
    setSelectRetractedID,
    changeStudyPlan,
    setChangeStudyPlan,
    showRetractedModal,
    setRetractedModal,
  };
};
