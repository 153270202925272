import { Icon } from '@octano/global-ui';
import styles from './TableEmptyContent.module.scss';

interface TableEmpyContent {
  title: string;
  subtitle: string;
}

const TableEmptyContent = ({ title, subtitle }: TableEmpyContent) => {
  return (
    <div className="d-flex flex-column align-items-center no-result">
      <Icon className="mt-5" name="information" size="52px" color="secondary" />
      <div className="mt-4 fw-400 text-black fs-22 text-center">{title}</div>
      <div
        className={`mx-auto mt-4 mb-5 fs-16 text-center fw-400 ${styles.emptySubtitle}`}
      >
        {subtitle}
      </div>
    </div>
  );
};

export default TableEmptyContent;
