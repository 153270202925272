import request, { AxiosErrorWithResponse, AxiosResult, AxiosResultDefaultError } from '../request';
import { PaginationRequestType } from '../../types/paginationRequestType';
import { VersionTypes } from '../../types/VersionTypes';
import { MAINTAINERS } from '../endpoints';
import { CurriculumTypeFull } from '../../types/CurriculumTypes';

export interface ChangeStatusVersion {
  newStatus: 'Borrador' | 'Activo' | 'Vigente' | 'Cerrado';
}

// valores para el form data
export interface VersionValues {
  id?: number;
  code: string;
  name: string;
  studyPlanId: number;
  entryRequirements?: string;
  graduationRequirements?: string;
  degreeRequirements?: string;
  status: string;
  curriculums?: CurriculumTypeFull[];
}

export type VersionCodeNotUniqueError = {
  code: 'NOT_UNIQUE_CODE';
  error: AxiosErrorWithResponse;
  status: number;
  data?: any;
};

export type VersionRequestErrors =
  | AxiosResultDefaultError
  | VersionCodeNotUniqueError;

export type ChangeStatusRequestErrors =
  | AxiosResultDefaultError
  | VersionChangeStatusError;


export type VersionChangeStatusError = {
  code: 'no_curriculum_created' | 'no_curriculum_active' | 'other_version_active';
  error: AxiosErrorWithResponse;
  status: number;
  data?: any;
};

/**
 * Obtiene el listado de version del plan de estudio
 */
export const getVersionsRequest = (id: number, items: number = 10, page: number = 0) => {
  const url = `${MAINTAINERS.STUDY_PLANS}/${id}/versions?items_per_page=${items}&page=${page}`;
  return request<PaginationRequestType<VersionTypes>>(url);
};

/**
 * crear unaversion en un plan de estudio
 */
export const createVersionsRequest = async (
  data: VersionValues,
): Promise<AxiosResult<VersionValues, VersionRequestErrors>> => {
  const url = MAINTAINERS.STUDY_PLAN_VERSION;
  const response = await request<{ data: VersionValues }>(url, {
    method: 'post',
    data,
  });

  if (response.error) {
    if (response.error.code === 'HTTP_ERROR' && response.error.status === 400) {

      // en el caso si el codigo esta en uso
      if (response.error.data.message && response.error.data.message[0]) {
        const error_code = response.error.data.message[0].indexOf('code in use');
        if (error_code !== -1) {
          return {
            error: {
              ...response.error,
              code: 'NOT_UNIQUE_CODE',
            } as VersionRequestErrors,
          };
        }
      }

      // de lo contrario regresa cualquier error
      return response;
    } else {
      return response;
    }
  }

  return {
    data: response.data.data,
  };
};

/**
 * cambiar una version y validar que sea correcto
 */
export const changeStatusVersion = async (
  id: number | undefined,
  data: ChangeStatusVersion,
): Promise<AxiosResult<{}, ChangeStatusRequestErrors>> => {
  const url = `${MAINTAINERS.STUDY_PLAN_VERSION}/${id}`;
  const response = await request<{ data: {} }>(url, {
    method: 'put',
    data,
  });

  if (response.error) {
    if (response.error.code === 'HTTP_ERROR' && response.error.status === 400) {

      return validateDataError(response);

    } else {
      return response;
    }
  }

  return {
    data: response.data.data,
  };
};


const validateDataError = (response: any) => {

  if (response.error.data.message) {

    const other_version_active = response.error.data.message.indexOf('other_version_active');
    if (other_version_active !== -1) {
      return {
        error: {
          ...response.error,
          code: 'other_version_active',
        } as ChangeStatusRequestErrors,
      };
    }

    const error_code = response.error.data.message.indexOf('no_curriculum_created');
    if (error_code !== -1) {
      return {
        error: {
          ...response.error,
          code: 'no_curriculum_created',
        } as ChangeStatusRequestErrors,
      };
    }

    const error_code_active = response.error.data.message.indexOf('no_curriculum_active');
    if (error_code_active !== -1) {
      return {
        error: {
          ...response.error,
          code: 'no_curriculum_active',
        } as ChangeStatusRequestErrors,
      };
    }

  }
  // de lo contrario regresa cualquier error
  return response;
};
