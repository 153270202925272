import { Table, TablePagination } from '@octano/global-ui';
import { CellFormatOptions } from '@octano/global-ui/dist/components/Table/types/TableTypes';
import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DisplayError from '../../components/info/DisplayError';
import TableEmptyContent from '../../components/text/TableEmptyContent';
import { IdNotFound } from '../components/IdNotFound';
import SearchRut from '../../components/inputs/SearchRutValue';
import { useAdmissionStatusList } from '../contexts/AdmissionStatusListContext';

interface AdmissionsStudentRetracted {
  id: number;
  rut: string;
  names: string;
  lastnames: string;
  phone: string;
  email: string;
  dateEnrolled: string;
  dateRetracted: string;
}

interface TableColumns {
  width?: string;
  bold?: boolean;
  headerText: string;
  columnName: string;

  [key: string]: any;
}

const ITEMS_PER_PAGE_DEFAULT = 10;

const prefix = 'admission';

function getDate(data: string) {
  return dayjs(data).format('DD/MM/YYYY');
}

export function AdmissionsListRetracted(): JSX.Element {
  const { t } = useTranslation();
  const { loading, data, error, query, updateQuery } = useAdmissionStatusList();
  const currentPage = query.page;
  const totalItems = data.total;
  const totalPages = data.total_pages;
  const searching = loading;
  const showPagination = data.total_pages > 1;

  const columns = useMemo<TableColumns[]>(() => {
    return [
      {
        columnName: 'rut',
        headerText: t(`common.forms.rut`),
        width: '10%',
        cellFormat: (
          options: CellFormatOptions<AdmissionsStudentRetracted>,
        ) => {
          return <span>{options.row.rut}</span>;
        },
      },
      {
        columnName: 'names',
        headerText: t(`common.forms.names`),
        width: '10%',
        cellFormat: (
          options: CellFormatOptions<AdmissionsStudentRetracted>,
        ) => {
          return <span>{options.row.names}</span>;
        },
      },
      {
        columnName: 'lastnames',
        headerText: t(`common.forms.lastName`),
        width: '10%',
        cellFormat: (
          options: CellFormatOptions<AdmissionsStudentRetracted>,
        ) => {
          return <span>{options.row.lastnames}</span>;
        },
      },
      {
        columnName: 'phone',
        headerText: t(`common.forms.phone`),
        width: '10%',
        cellFormat: (
          options: CellFormatOptions<AdmissionsStudentRetracted>,
        ) => {
          return <span>{options.row.phone}</span>;
        },
      },
      {
        columnName: 'email',
        headerText: t(`common.forms.email`),
        width: '10%',
        cellFormat: (
          options: CellFormatOptions<AdmissionsStudentRetracted>,
        ) => {
          return <span>{options.row.email}</span>;
        },
      },
      {
        columnName: 'dateEnrolled',
        headerText: t(`common.forms.dateEnrolled`),
        width: '10%',
        cellFormat: (
          options: CellFormatOptions<AdmissionsStudentRetracted>,
        ) => {
          return <span>{options.row.dateEnrolled}</span>;
        },
      },
      {
        columnName: 'dateRetracted',
        headerText: t(`common.forms.dateRetracted`),
        width: '10%',
        cellFormat: (
          options: CellFormatOptions<AdmissionsStudentRetracted>,
        ) => {
          return (
            <span
              style={{ textAlign: 'center', display: 'block', width: '100%' }}
            >
              {options.row.dateRetracted}
            </span>
          );
        },
      },
    ];
  }, [t]);

  const getColumnResponsive = useCallback((): TableColumns[] => {
    let columnsRemove = ['dateEnrolled', 'email', 'phone', 'lastnames'];
    let limit = Math.round(window.innerWidth / 160);
    let nColumnsRemove = columns.length > limit ? columns.length - limit : 0;
    return columns.filter((column) => {
      if (
        columnsRemove.find((el) => el === column.columnName) &&
        nColumnsRemove !== 0
      ) {
        nColumnsRemove--;
        return false;
      } else return true;
    });
  }, [columns]);

  const students = useMemo(
    () =>
      data.data.map(
        ({
          Details_id,
          Postulant_rut,
          PostulantPassport_number,
          Postulant_names,
          Postulant_paternalLastName,
          Postulant_maternalLastName,
          Contact_cellPhone,
          Contact_email,
          Details_enrolledDate,
          Details_retractedDate,
        }) => ({
          id: Details_id,
          rut: Postulant_rut || PostulantPassport_number || '',
          names: Postulant_names,
          lastnames: `${Postulant_paternalLastName} ${Postulant_maternalLastName}`,
          phone: Contact_cellPhone,
          email: Contact_email,
          dateEnrolled: Details_enrolledDate
            ? getDate(Details_enrolledDate)
            : '',
          dateRetracted: Details_retractedDate
            ? getDate(Details_retractedDate)
            : '',
        }),
      ),
    [data],
  );

  const [filterColumnsResponsive, setFilterColumnsResponsive] = useState<
    TableColumns[]
  >(getColumnResponsive());

  useEffect(() => {
    window.onresize = () => {
      let array = getColumnResponsive();
      setFilterColumnsResponsive([...array]);
    };
  }, [getColumnResponsive]);

  const TableEmpty = useMemo(() => {
    if (query.search) {
      return <IdNotFound />;
    } else {
      return (
        <TableEmptyContent
          title={t(`${prefix}.studentRetracted.messageNotRetracted`)}
          subtitle={''}
        />
      );
    }
  }, [query, t]);

  if (error) {
    return (
      <DisplayError
        insideCard
        textBody={error}
        retryAction={() => updateQuery()}
        loadingAction={loading}
      />
    );
  }
  return (
    <div className="mt-3 text-info">
      <SearchRut
        value={query.search}
        onChange={(search) => updateQuery({ page: 1, search })}
        searching={searching}
      />
      <Table
        columns={filterColumnsResponsive}
        data={students}
        noResultsText={TableEmpty}
        isLoadingResults={loading}
      />
      {showPagination && (
        <TablePagination
          pagination={{
            currentPage,
            itemsPerPage: ITEMS_PER_PAGE_DEFAULT,
            onChangePage: (page) => {
              updateQuery({ page, search: query.search });
            },
            totalItems,
            totalPages,
          }}
        />
      )}
    </div>
  );
}
