import { Button, Icon, Modal } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import { useLayoutState } from '../../hooks/useLayoutState';

export const ConnectionError = () => {
  const { showConnectionErrorModal, hideErrorModal } = useLayoutState();
  const { t } = useTranslation();

  return (
    <Modal isOpen={showConnectionErrorModal} toggle={function noRefCheck() {}}>
      <div className="text-center">
        <Icon name="warning" size="52px" />
        <div
          className="py-4"
          style={{
            color: '#000',
            fontSize: '22px',
          }}
        >
          {t('modalErrors.anErrorHasOccurred')}
        </div>
        <div
          className="pb-4"
          style={{
            fontSize: '18px',
          }}
        >
          {t('modalErrors.anErrorHasOccurredMessage')}
        </div>
        <Button
          fullwidth
          onClick={hideErrorModal}
          text={t('common.actions.close')}
        />
      </div>
    </Modal>
  );
};
