import {
  addToast,
  Button,
  Modal,
  Select,
  SelectOptionType,
  TextInput,
} from '@octano/global-ui';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Col, Form, Input, Row } from 'reactstrap';
import {
  CourseType,
  requestAvailableCourses,
} from '../../../api/requests/courses';
import { CardTitle } from '../../../components/text';
import { useValidations } from '../../../hooks/useValidations';
import { COURSE_TYPES } from '../../../types/CurriculumTypes';

interface CourseModalProps {
  semester: number;
  addCourse: Function;
  show: boolean;
  onClose: Function;
  studyPlanName: string;
  setDisabledEditButton: (value: boolean) => void;
}
export interface CourseFormValues {
  type: SelectOptionType | null;
  name?: SelectOptionType | null;
  code?: string;
  credits: string;
  courseName?: string;
  courseCode?: string;
  courseCredits?: string;
}

export const CourseModal = ({
  semester,
  addCourse,
  show,
  onClose,
  studyPlanName,
  setDisabledEditButton,
}: CourseModalProps) => {
  const { msgValidations } = useValidations();
  const { t } = useTranslation();
  const prefix = 'studyPlans.curriculum.courseModal';
  const [availableCourses, setAvailableCourses] = useState<CourseType[]>([]);
  const [activityCourses, setActivityCourses] = useState<CourseType[]>([]);
  const [courseNameOptions, setCourseNameOptions] = useState<
    SelectOptionType[]
  >([]);

  // Remember add all course_types into course_type table in DB
  const courseTypes = useMemo(
    () => ({
      required: COURSE_TYPES.OBLIGATORIO.id,
      activity: COURSE_TYPES.ACTIVIDAD.id,
    }),
    [],
  );

  const courseTypeOptions: SelectOptionType[] = [
    { label: t(`${prefix}.required`), value: 'required' },
    { label: t(`${prefix}.religious`), value: 'religious' },
    { label: t(`${prefix}.sports`), value: 'sports' },
    { label: t(`${prefix}.optionalOther`), value: 'optionalOther' },
    { label: t(`${prefix}.activity`), value: 'activity' },
    { label: t(`${prefix}.other`), value: 'other' },
  ];

  const methods = useForm<CourseFormValues>({
    mode: 'onSubmit',
    defaultValues: {
      type: null,
      name: null,
      code: '',
      credits: '',
    },
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
    control,
    reset,
    setValue,
    watch,
  } = methods;

  const [typeValue, nameValue] = watch(['type', 'name']);
  const { mentionId, curriculumId } = useParams<{
    mentionId?: string;
    curriculumId?: string;
  }>();

  const resetFields = useCallback(() => {
    setValue('code', '');
    setValue('courseCode', '');
    setValue('credits', '');
    setValue('courseCredits', '');
    setValue('name', null);
    setValue('courseName', '');
  }, [setValue]);

  const fillFields = useCallback(
    (id: any) => {
      const results =
        typeValue?.value === 'required' ? availableCourses : activityCourses;
      const row = results.find(
        (course) => course.id === parseInt(id?.value || -1),
      );

      if (row) {
        setValue('code', row?.shortening);
        setValue('courseCode', row?.code);
        setValue('credits', row?.credits.toString());
        setValue('courseCredits', row?.credits.toString());
        setValue('courseName', row?.name);
      } else {
        resetFields();
      }
    },
    [activityCourses, availableCourses, resetFields, setValue, typeValue],
  );

  const getAvailableCourses = useCallback(async () => {
    const { data, error } = await requestAvailableCourses(courseTypes.required);
    if (data) {
      setAvailableCourses(data?.data || []);
    }
    if (error) {
      addToast({
        text: t(`${prefix}.errorOnSearch`),
        color: 'danger',
        icon: 'error',
      });
    }
  }, [setAvailableCourses, courseTypes, t]);

  const getActivityCourses = useCallback(async () => {
    const { data, error } = await requestAvailableCourses(courseTypes.activity);
    if (data) {
      setActivityCourses(data?.data || []);
    }
    if (error) {
      addToast({
        text: t(`${prefix}.errorOnSearch`),
        color: 'danger',
        icon: 'error',
      });
    }
  }, [setActivityCourses, courseTypes, t]);

  useEffect(() => {
    getAvailableCourses();
  }, [getAvailableCourses]);

  useEffect(() => {
    getActivityCourses();
  }, [getActivityCourses]);

  useEffect(() => {
    fillFields(nameValue);
  }, [nameValue, fillFields]);

  useEffect(() => {
    if (typeValue?.value === 'required')
      setCourseNameOptions(
        availableCourses.map((row) => ({
          label: row.name,
          value: row.id,
          shortening: row.shortening,
        })),
      );
    if (typeValue?.value === 'activity')
      setCourseNameOptions(
        activityCourses.map((row) => ({
          label: row.name,
          value: row.id,
          shortening: row.shortening,
        })),
      );
    resetFields();
  }, [typeValue, activityCourses, availableCourses, resetFields]);

  const onSubmit = async (formValues: CourseFormValues) => {
    if (!formValues.type) {
      return;
    }
    const added = addCourse({
      id: formValues.name?.value,
      type: formValues.type.label,
      name: formValues.courseName,
      code: formValues.courseCode,
      credits: formValues.courseCredits || formValues.credits,
      shortening: formValues.name?.shortening,
      semester: semester,
    });
    if (added) {
      closeModal();
      addToast({
        icon: 'success',
        color: 'success',
        text: t(`${prefix}.addedCourseMessage`, {
          type: typeValue?.value === 'activity' ? 'Actividad' : 'Asignatura',
        }),
      });
      if (
        (mentionId && !isNaN(parseInt(mentionId))) ||
        (curriculumId && !isNaN(parseInt(curriculumId)))
      ) {
        setDisabledEditButton(false);
      }
    } else {
      addToast({
        icon: 'error',
        color: 'danger',
        text: t(`${prefix}.errorCourseMessage`, {
          type: typeValue?.value === 'activity' ? 'actividad' : 'asignatura',
        }),
      });
    }
  };

  const closeModal = () => {
    reset();
    onClose();
  };

  return (
    <Modal
      isOpen={show}
      toggle={() => {
        closeModal();
      }}
      size="lg"
    >
      <Form onSubmit={handleSubmit(onSubmit)} className="course-modal">
        <Row>
          <Col>
            <div className="text-center">
              <CardTitle
                children={t(`${prefix}.title`, {
                  type:
                    typeValue?.value === 'activity'
                      ? 'Actividad'
                      : 'Asignatura',
                })}
              />
            </div>
            <Row className="mt-4 mt-md-5">
              {typeValue?.value === 'activity' && (
                <Col xs={12} md={6}>
                  <div className="border-secondary studyplan-info d-flex flex-column text-secondary mb-4 mb-md-0">
                    <span className="studyplan-name">
                      {t(`${prefix}.studyPlanName`)}
                    </span>
                    <span>{studyPlanName}</span>
                  </div>
                </Col>
              )}
              <Col xs={12} md={typeValue?.value === 'activity' ? 6 : 12}>
                <Select
                  name="type"
                  label={t(`${prefix}.type`)}
                  options={courseTypeOptions}
                  control={control}
                  rules={{ required: msgValidations.required }}
                />
              </Col>
            </Row>
            {(typeValue?.value === 'required' ||
              typeValue?.value === 'activity') && (
              <Row>
                <Col xs={12} md>
                  <Select
                    name="name"
                    label={t(`${prefix}.name`, {
                      type:
                        typeValue?.value === 'activity'
                          ? 'Actividad'
                          : 'Asignatura',
                    })}
                    options={courseNameOptions}
                    control={control}
                    rules={{ required: msgValidations.required }}
                  />
                  <Input type="hidden" name="courseName" control={control} />
                </Col>
              </Row>
            )}
            <Row>
              {(typeValue?.value === 'required' ||
                typeValue?.value === 'activity') && (
                <Col xs={12} md={6}>
                  <TextInput
                    name="code"
                    label={t(`${prefix}.code`)}
                    control={control}
                    disabled
                    rules={{
                      required: msgValidations.required,
                    }}
                  />
                  <Input type="hidden" name="courseCode" control={control} />
                </Col>
              )}
              {(typeValue?.value === 'required' ||
                typeValue?.value === 'religious' ||
                typeValue?.value === 'sports' ||
                typeValue?.value === 'optionalOther' ||
                typeValue?.value === 'other') && (
                <Col xs={12} md={typeValue?.value === 'required' ? 6 : 12}>
                  <TextInput
                    name="credits"
                    label={t(`${prefix}.credits`)}
                    control={control}
                    disabled={typeValue?.value === 'required'}
                    rules={{
                      required: msgValidations.required,
                      maxLength: {
                        value: 50,
                        message: t('common.validations.maxLength', {
                          length: 50,
                        }),
                      },
                      pattern: {
                        value: /^[0-9]*$/,
                        message: t('common.validations.invalidIntNumeric'),
                      },
                    }}
                  />
                  <Input type="hidden" name="courseCredits" control={control} />
                </Col>
              )}
            </Row>
          </Col>
        </Row>
        <Row>
          <Col className="mt-4">
            <Row>
              <Col xs={{ size: 12, order: 2 }} md={{ size: 6, order: 1 }}>
                <Button
                  type="button"
                  text={t('common.actions.cancel')}
                  outlined
                  onClick={closeModal}
                  fullwidth
                />
              </Col>
              <Col xs={{ size: 12, order: 1 }} md={{ size: 6, order: 2 }}>
                <Button
                  type="submit"
                  text={t('common.actions.add')}
                  loading={isSubmitting}
                  fullwidth
                  className="mb-3 mb-md-0"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
