import { StudyPlanItemCustom } from '../../views/academicOffers/vacant-offers/interfaces/study-plan-item-custom.interface';
import {
  StudyPlanItem,
  Version,
} from '../../studyPlans/interfaces/sudy-plan-item.interface';
import { PeriodType } from '../../types/PeriodType';
import { PaginationRequestType } from '../../types/paginationRequestType';
import { MAINTAINERS, STUDY_PLANS, STUDY_PLANS_MAINTAINER } from '../endpoints';
import request from '../request';

interface StudyPlansListResponse {
  data: StudyPlanItem[];
  total: number;
  total_pages: number;
}

interface StudyPlansListBySchoolResponse {
  data: StudyPlanItemCustom[];
  total: number;
  total_pages: number;
}

export interface StudyPlanResponse {
  data: StudyPlanBody;
}

export interface StudyPlanBody {
  id?: number;
  name: string;
  code: string;
  schoolId: number;
  modalityId: number;
  periodTypeId: number;
}

export interface CurriculumInfoBody {
  name: string;
  version: string;
  periodType?: PeriodType;
}

export interface StudyPlansAdmissionDetailForm {
  periods: Array<{ id: number; name: string }>;
  currentPeriod?: { id: number; name: string };
  campuses: Array<{ id: number; name: string }>;
  schools: Array<{ id: number; name: string }>;
}

interface StudyPlansAdmissionDetail {
  StudyPlanVersionOffer_id: number;
  StudyPlanVersion_id: number;
  StudyPlanVersion_name: string;
  AcademicOfferStudyPlanVersionOffer_id: number;
  Campus_name: string;
  Schedule_name: string;
  StudyPlan_id: number;
  StudyPlan_name: string;
  quota: number;
  enrolled: number;
  in_process: number;
  waiting: number;
  desisted: number;
  retracted: number;
  occupation_percentage: number;
}

/**
 * Obtiene el listado de planes de estudio
 */
export const getStudyPlansRequest = (
  items: number = 10,
  page: number = 0,
  searchQuery?: string,
) => {
  const search = searchQuery ? new URLSearchParams(searchQuery) : '';
  const searchParams = search ? Object.fromEntries(search) : {};

  const url = `${STUDY_PLANS_MAINTAINER.BASE}`;
  const params = {
    items_per_page: items,
    page: page,
    ...searchParams,
  };
  return request<StudyPlansListResponse>(url, {
    method: 'get',
    params,
  });
};

/**
 * Obtiene el listado de planes de estudio de una facultad
 */
export const getStudyPlansBySchoolRequest = (
  periodId: number,
  schoolId: number,
  academicOfferId: number,
  items: number = 10,
  page: number = 0,
) => {
  const url = `${STUDY_PLANS_MAINTAINER.GET_BY_SCHOOL(
    schoolId,
    academicOfferId,
  )}`;
  const params = {
    items_per_page: items,
    page: page,
    periodId,
  };
  return request<StudyPlansListBySchoolResponse>(url, {
    method: 'get',
    params,
  });
};

/**
 *  Crear o editar un plan de estudio
 */
export const saveStudyPlanRequest = (data: StudyPlanBody) => {
  const isEdit = !!data.id;

  const url =
    isEdit && data.id
      ? STUDY_PLANS_MAINTAINER.PUT_BY_ID(data.id)
      : STUDY_PLANS_MAINTAINER.BASE;
  return request<StudyPlanResponse>(url, {
    method: isEdit ? 'put' : 'post',
    data,
  });
};

/**
 * Obtiene un plan de estudios
 */
export const getStudyPlanRequest = (id: number | string) => {
  const url = `${STUDY_PLANS_MAINTAINER.GET_BY_ID(id)}`;
  return request<{ data: StudyPlanItem }>(url);
};

/**
 * Obtiene info de plan de estudios y version
 */
export const getCurriculumInfoByStudyPlan = (
  studyPlanId: number,
  versionId: number,
) => {
  const url = `${STUDY_PLANS_MAINTAINER.GET_CURRICULUM_INFO(
    studyPlanId,
    versionId,
  )}`;
  return request<{ data: CurriculumInfoBody }>(url);
};

export const getAdmissionDetailsForm = () => {
  const url = STUDY_PLANS.STUDY_PLAN_ADMISSION_DETAIL_FORM;
  return request<StudyPlansAdmissionDetailForm>(url, {
    method: 'get',
  });
};

export const getAdmissionDetail = ({
  periodId,
  campusId,
  schoolId,
  items_per_page,
  page,
}: {
  periodId: number;
  campusId?: number;
  schoolId?: number;
  items_per_page: number;
  page: number;
}) => {
  return request<PaginationRequestType<StudyPlansAdmissionDetail>>(
    STUDY_PLANS.STUDY_PLAN_ADMISSION_DETAIL,
    {
      method: 'GET',
      params: {
        periodId,
        campusId,
        schoolId,
        items_per_page,
        page: page - 1,
      },
    },
  );
};

export const getStudyPlanVersions = () => {
  const url = `${MAINTAINERS.STUDY_PLAN_VERSION}/form-data`;
  return request<{
    studyPlanVersions: Pick<
      Version,
      | 'degreeRequirements'
      | 'entryRequirements'
      | 'graduationRequirements'
      | 'id'
      | 'name'
    >[];
    campuses: {
      id: number;
      name: string;
    }[];
  }>(url);
};
