import { FieldsStudentNoSuaFile } from '../../../../types/studentFileNoSuaTypes';
import { SaveStudentFileBody } from '../../../../types/studentFileSectionTypes';

const checkLength = (value: any) =>
  Object.values(value).filter((item) => item !== undefined && item !== null)
    .length === 0;

export function formatStudenFileNoSuaData(
  values: FieldsStudentNoSuaFile,
  admissionMethod: string,
) {
  const formatData: SaveStudentFileBody = {
    loginProcessType: admissionMethod as 'con-sua' | 'sin-sua',
    background: {
      namesStudent: values?.namesStudent,
      paternalSurname: values?.paternalSurname,
      maternalSurname: values?.maternalSurname,
      birthday: values?.birthday,
      sexId: +values.sexID.value,
      maritalStatusId: +values.maritalStatus.value,
      nationalityId: values.nationality.value.toString(),
      officialEmail: values?.officialEmail,
      personalEmail: values?.personalEmail,
    },
    contact: {
      addressStreet: values.addressStreet,
      addressNumber: values.addressNumber,
      addressExtra: values.addressExtra,
      communeId: values.commune?.value as number,
      phone: values.phone,
      cellPhone: values.cellphone,
      email: values.email,
    },
    familyContact: {
      addressStreet: values.addressStreetFamily,
      addressNumber: values.addressNumberFamily,
      addressExtra: values.addressExtraFamily,
      communeId: +values.communeFamily.value,
      phone: values.phoneFamily,
      cellPhone: values.cellphoneFamily,
      email: values.emailFamily,
    },
    emergencyContact: {
      name: values.emergencyNames,
      cellPhone: values.mobilePhoneNumberEmergency,
      email: values.emailEmergency,
    },
    healthCare: {
      systemId: values.healthCareSystem?.value as number,
      providerId: values.healthCareProvider?.value as number,
      contributorRut: values.healthCareContributorID,
      hasInsurance:
        typeof values.healthInsurance?.value === 'number'
          ? Boolean(values.healthInsurance?.value)
          : undefined,
      insuranceContributorRut: values.healthInsuranceContributorID,
      insuranceCellPhone: values.phoneNumberHealthCare,
      otherSystem: values.otherHealthCare ?? undefined,
    },
    parentalEducationalBackground: {
      educationalLevelPaternalId: values.fatherEducationalLevel
        ?.value as number,
      educationalLevelMaternalId: values.motherEducationalLevel
        ?.value as number,
      familyGroupId: values.familyGroup?.value as number,
      parentalSurvivalId: values.parentalSurvival?.value as number,
    },
    postulantEducationalBackground: {
      highSchool: values.nameHighSchool,
      graduationYear: +values.graduationYear,
      countryId: values.countryHighSchool!.value.toString(),
      communeId: values.communeHighSchool
        ? +values.communeHighSchool.value
        : null,
      institutionTypeId: +values.typeHighSchool!.value,
    },
  };

  if (checkLength(formatData.contact)) {
    formatData.contact = undefined;
  }
  if (checkLength(formatData.healthCare)) {
    formatData.healthCare = undefined;
  }
  if (checkLength(formatData.parentalEducationalBackground)) {
    formatData.parentalEducationalBackground = undefined;
  }
  return formatData;
}
