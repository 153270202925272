import { useReducer, createContext } from 'react';
import layoutReducer, { initialState } from '../reducers/layoutReducer';
import { LayoutContextType } from '../types/LayoutTypes';

export const LayoutContext = createContext({} as LayoutContextType);

const LayoutProvider = (props: any) => {
  const [state, dispatch] = useReducer(layoutReducer, initialState);

  return (
    <LayoutContext.Provider value={{ state, dispatch }}>
      {props.children}
    </LayoutContext.Provider>
  );
};

export default LayoutProvider;
