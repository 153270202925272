import { addToast, Button, SelectOptionType } from '@octano/global-ui';
import { useCallback, useEffect } from 'react';
import { useForm, FormProvider, DefaultValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'reactstrap';
import { clean } from 'rut.js';
import { saveCosigner } from '../../../../api/requests/tuitionProcess';
import ContactSection from '../../../../components/form/ContactSection';
import { useStepState } from '../../../../components/step/useStepState';
import { useTuitionProcess } from '../../TuitionProcessContext';
import PersonalInformation from './PersonalInformation';

export type FieldsCosignerForm = {
  names: string;
  paternalLastName: string;
  maternalLastName: string;
  rut: string;
  checkSustainer: boolean;
  addressStreet: string;
  addressNumber: string;
  addressExtra: string;
  commune: SelectOptionType | null;
  region: SelectOptionType | null;
  phone: string;
  cellphone: string;
  email: string;
};

type CosignerFormProps = {
  studentRut: string;
  defaultValues?: DefaultValues<FieldsCosignerForm>;
  dataSustainer?: FieldsCosignerForm;
};

/**
 * Formulario del paso "Datos Aval" del proceso de matrícula
 */
const CosignerForm = ({
  studentRut,
  defaultValues,
  dataSustainer,
}: CosignerFormProps) => {
  const {
    prefix: translationPrefix,
    postulationDetailId,
    onError,
  } = useTuitionProcess();
  const prefix = `${translationPrefix}.cosigner`;
  const { t } = useTranslation();
  const methods = useForm<FieldsCosignerForm>({
    mode: 'onSubmit',
    defaultValues: defaultValues,
  });
  const {
    setValue,
    handleSubmit,
    formState: { isSubmitting },
    watch,
  } = methods;
  const { nextStep } = useStepState();
  const checkSustainer = watch('checkSustainer');

  const onChangeSustainerAsCosigner = useCallback(() => {
    if (checkSustainer) {
      setValue('rut', dataSustainer?.rut ?? '');
      setValue('names', dataSustainer?.names ?? '');
      setValue('paternalLastName', dataSustainer?.paternalLastName ?? '');
      setValue('maternalLastName', dataSustainer?.maternalLastName ?? '');
      setValue('addressStreet', dataSustainer?.addressStreet ?? '');
      setValue('addressNumber', dataSustainer?.addressNumber ?? '');
      setValue('addressExtra', dataSustainer?.addressExtra ?? '');
      if (dataSustainer?.region) {
        setValue('region', dataSustainer.region);
      }
      if (dataSustainer?.commune) {
        setValue('commune', dataSustainer.commune);
      }
      setValue('phone', dataSustainer?.phone ?? '');
      setValue('cellphone', dataSustainer?.cellphone ?? '');
      setValue('email', dataSustainer?.email ?? '');
    } else {
      setValue('rut', '');
      setValue('names', '');
      setValue('paternalLastName', '');
      setValue('maternalLastName', '');
      setValue('addressStreet', '');
      setValue('addressNumber', '');
      setValue('addressExtra', '');
      setValue('region', null);
      setValue('commune', null);
      setValue('phone', '');
      setValue('cellphone', '');
      setValue('email', '');
    }
  }, [checkSustainer, dataSustainer, setValue]);

  useEffect(() => {
    if (checkSustainer !== undefined) {
      onChangeSustainerAsCosigner();
    }
  }, [checkSustainer, onChangeSustainerAsCosigner]);

  const onSubmit = async (values: FieldsCosignerForm) => {
    let saveValues = values;
    if (values.checkSustainer && dataSustainer) {
      // Para guardar los valores del sustentador como aval del alumno,
      // se mezcla los values con los datos de dataSustainer
      // ya que al dejar los inputs disabled react hook forms retorna los valores como undefined
      saveValues = { ...values, ...dataSustainer };
    }
    if (saveValues.commune) {
      const { data, error } = await saveCosigner(postulationDetailId, {
        rut: clean(saveValues.rut),
        names: saveValues.names,
        paternalLastName: saveValues.paternalLastName,
        maternalLastName: saveValues.maternalLastName,
        addressStreet: saveValues.addressStreet,
        addressNumber: saveValues.addressNumber,
        addressExtra: saveValues.addressExtra ?? null,
        communeId: +saveValues.commune?.value,
        phone: saveValues.phone ?? null,
        cellPhone: saveValues.cellphone,
        email: saveValues.email,
      });

      if (data && error === false) {
        addToast({
          icon: 'success',
          color: 'success',
          text: t(`${prefix}.saveSuccess`),
        });
        nextStep();
      } else if (error) {
        onError(error.code, () => {
          addToast({
            icon: 'error',
            color: 'danger',
            text: t(`${prefix}.saveError`),
          });
        });
      }
    }
  };

  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <PersonalInformation
          studentRut={studentRut}
          disabledCheckSustainer={
            clean(dataSustainer?.rut ?? '') === studentRut
          }
        />
        <ContactSection
          prefix={`${prefix}.contact`}
          disabled={checkSustainer}
        />
        <Row className="pb-5 pt-5">
          <Col xs={12} lg={4} className="ml-auto">
            <Button
              type="submit"
              text={t(`common.actions.next`)}
              loading={isSubmitting}
              fullwidth
            />
          </Col>
        </Row>
      </Form>
    </FormProvider>
  );
};

export default CosignerForm;
