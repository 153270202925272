import request from '../request';
import { AUTH } from '../endpoints';
import { AuthResponse } from '../../types/Auth';

/**
 * Envia los datos del usuario para el logeo y recibe el token para la autorizacion
 * @param credentials
 * @returns
 */
export function logIn(credentials: { email: string; password: string }) {
  const url = AUTH.LOGIN;
  const method = 'POST';
  const data = {
    ...credentials,
    scope: 'maintainers',
  };
  return request<AuthResponse>(url, { method, data, authRequired: false });
}

/**
 * En caso de existir un usuario registrado con el email proporcionado, se enviara un correo con un enlace de activacion de cuenta
 * @param email
 * @returns
 */
export function requestActivationEmail(email: string) {
  const url = AUTH.REQUEST_ACTIVATION_EMAIL;
  const method = 'POST';
  const data = { email };
  return request<{}>(url, { method, data });
}

/**
 * En caso de existir un usuario registrado con el email proporcionado, se enviara un correo con un enlace de cambio de contraseña
 * @param email
 * @returns
 */
export function requestForgotPasswordEmail(email: string) {
  const url = AUTH.REQUEST_FORGOT_PASSWORD_EMAIL;
  const method = 'POST';
  const data = { email };
  return request<{}>(url, { method, data });
}

/**
 * Activa la cuenta de usuario colocandole una nueva contraseña
 * @param options: { email, token (recibido via url), password, confirmPassword}
 * @returns
 */
export function activateAccount({
  email,
  token,
  password,
  passwordConfirm,
}: {
  email: string;
  token: string;
  password: string;
  passwordConfirm: string;
}) {
  const url = AUTH.ACTIVATE_ACCOUNT;
  const method = 'POST';
  const data = { email, token, password, passwordConfirm };
  return request<any>(url, { method, data });
}

/**
 * Cambia la contraseña del usuario
 * @param options: { email, token (recibido via url), password, confirmPassword}
 * @returns
 */
export function changePassword({
  email,
  token,
  password,
  passwordConfirm,
}: {
  email: string;
  token: string;
  password: string;
  passwordConfirm: string;
}) {
  const url = AUTH.CHANGE_PASSWORD;
  const method = 'PUT';
  const data = { email, token, password, passwordConfirm };
  return request<any>(url, { method, data });
}

/**
 * Valida el token recibido via url para activar una cuenta o cambiar su contraseña
 * @param options: {type: si es para activar cuenta o cambiar contraseña, email, token (obtenido via url)}
 * @returns
 */
export function checkPasswordChangeToken({
  type,
  email,
  token,
}: {
  type: 'activate-account' | 'reset-password';
  email: string;
  token: string;
}) {
  const url = AUTH.CHECK_PASSWORD_CHANGE_TOKEN;
  const method = 'POST';
  const data = { type, email, token };
  return request<any>(url, { method, data });
}
