import { addToast, Button, IconPaths } from '@octano/global-ui';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Col, Row } from 'reactstrap';
import {
  getSignDocumentation,
  getSignedDocumentPreview,
  getSignedDocumentPreviewToken,
  verifySignDocumentation,
} from '../../../../api/requests/tuitionProcess';
import DisplayError from '../../../../components/info/DisplayError';
import Loading from '../../../../components/info/Loading';
import { useStepState } from '../../../../components/step/useStepState';
import { SectionTitle } from '../../../../components/text';
import { useLoadingState } from '../../../../hooks/useLoadingState';
import {
  DocumentTypes,
  IdentityTypes,
  SignDocumentationStepResponse,
} from '../../../../types/signDocumentationTypes';
import { useTuitionProcess } from '../../TuitionProcessContext';
import Document from './Document';
import IdentityCards from './IdentityCards';

type DocumentInfo = {
  id: DocumentTypes;
  name: string;
  icon: keyof typeof IconPaths;
  isSigned: boolean;
};

const formatAvailableDocuments = (
  data: SignDocumentationStepResponse,
): DocumentInfo[] => {
  const documentData: Record<string, any> = {
    'promissory-note': {
      name: 'promissoryNote',
      icon: 'promissory_note',
    },
    contract: {
      name: 'contract',
      icon: 'contract',
    },
    insurance: {
      name: 'insurance',
      icon: 'sustaining_contract',
    },
  };
  return data.documents.map((document) => {
    return {
      id: document.type,
      ...documentData[document.type],
      isSigned: false, // En matrícula presencial parten como `false`
    };
  });
};

type IdentityCardsInfo = {
  id: IdentityTypes;
  isUploaded: boolean;
};

const formatAvailableIdentityCards = (
  data: SignDocumentationStepResponse,
): IdentityCardsInfo[] => {
  return data.identityCards.map((identityCard) => {
    return {
      id: identityCard.type,
      isUploaded: false, // En matrícula presencial parten como `false`
    };
  });
};

function useSignErrors(
  documents: DocumentInfo[],
  identityCards: IdentityCardsInfo[],
) {
  const documentErrors = new Map<DocumentTypes, boolean>();
  const identityCardsErrors = new Map<IdentityTypes, boolean>();

  for (const document of documents) {
    if (!document.isSigned) {
      documentErrors.set(document.id, true);
    }
  }
  for (const identityCard of identityCards) {
    if (!identityCard.isUploaded) {
      identityCardsErrors.set(identityCard.id, true);
    }
  }

  return {
    documents: documentErrors,
    identityCards: identityCardsErrors,
  };
}

const Sign = () => {
  const {
    prefix: translationPrefix,
    postulationDetailId,
    onError,
  } = useTuitionProcess();
  const prefix = `${translationPrefix}.sign`;
  const { t } = useTranslation();
  const { loading, setLoading, errorLoading, setErrorLoading } =
    useLoadingState();
  const { nextStep } = useStepState();

  const [availableDocuments, setAvailableDocuments] = useState<DocumentInfo[]>(
    [],
  );

  const [availableIdentityCards, setAvailableIdentityCards] = useState<
    IdentityCardsInfo[]
  >([]);

  const [studentFullName, setStudentFullName] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [showErrors, setShowErrors] = useState(false);
  const errors = useSignErrors(availableDocuments, availableIdentityCards);

  const getStatus = useCallback(async () => {
    setShowErrors(false);
    const { data, error } = await getSignDocumentation(postulationDetailId);
    if (data) {
      setAvailableDocuments(formatAvailableDocuments(data));
      setAvailableIdentityCards(formatAvailableIdentityCards(data));
      setStudentFullName(data.studentFullName);
      setErrorLoading(undefined);
    } else if (error) {
      setErrorLoading(error.code);
      onError(error.code);
    }
    setLoading(false);
  }, [setErrorLoading, setLoading, onError, postulationDetailId]);

  useEffect(() => {
    getStatus();
  }, [getStatus]);

  const onSubmit = async () => {
    if (errors.documents.size || errors.identityCards.size) {
      setShowErrors(true);
      return;
    }
    const { error } = await verifySignDocumentation(postulationDetailId);
    if (!error) {
      nextStep();
    } else {
      onError(error.code, () =>
        addToast({
          icon: 'error',
          color: 'danger',
          text: t(`${prefix}.nextStepError`),
        }),
      );
    }
  };

  const getPDF = useCallback(
    async (documentType: string) => {
      const { data, error } = await getSignedDocumentPreviewToken(
        postulationDetailId,
      );
      if (data && !error) {
        const url = getSignedDocumentPreview(postulationDetailId, {
          token: data,
          documentType,
        });
        window.open(url);
      } else if (error) {
        onError(error.code);
      }
    },
    [postulationDetailId, onError],
  );

  if (errorLoading) {
    return (
      <DisplayError
        insideCard
        textBody={errorLoading}
        retryAction={() => setLoading(true)}
        loadingAction={loading}
      />
    );
  }
  if (loading) {
    return <Loading insideCard />;
  }
  return (
    <>
      <Card className="px-4 px-md-5 py-4">
        <Row className="pb-4 pb-md-0">
          <Col className="pb-3" xs={12} lg={7} md={8} xl={9}>
            <span className="fs-18 text-primary">{t(`${prefix}.student`)}</span>
            <br />
            <span className="fs-18 text-primary fw-600">{studentFullName}</span>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <SectionTitle text={t(`${prefix}.title`)} />
          </Col>
        </Row>
        <Row>
          {/* CONTAINERS CON DOCUMENTOS DISPONIBLES */}
          {availableDocuments.map((doc, i) => {
            return (
              //  TODO: Agregar acciones para ver y descargar
              <Document
                key={`${doc.name}_${i}`}
                name={t(`${prefix}.${doc.name}.name`)}
                nameIcon={doc.icon}
                isSigned={doc.isSigned}
                btnDownload={{
                  text: t(`${prefix}.${doc.name}.btnDownload`),
                  action: () => {
                    getPDF(doc.id);
                  },
                }}
                btnSign={{
                  text: t(
                    `${prefix}.${doc.name}.${
                      doc.isSigned ? 'btnSigned' : 'btnSign'
                    }`,
                  ),
                  action: () => {
                    setAvailableDocuments((a) =>
                      a.map((d) => ({
                        ...d,
                        isSigned: d.id === doc.id ? !d.isSigned : d.isSigned,
                      })),
                    );
                  },
                }}
                error={
                  showErrors && errors.documents.has(doc.id)
                    ? t(`${prefix}.errors.${doc.name}`)
                    : undefined
                }
              />
            );
          })}
          {Boolean(availableIdentityCards.length) && (
            <Col xs={12} lg={6} className="mb-3">
              <IdentityCards
                identityCards={availableIdentityCards}
                error={
                  showErrors && errors.identityCards.size > 0
                    ? t(`${prefix}.errors.identityCards`)
                    : undefined
                }
                onChange={(identityCardId) => {
                  setAvailableIdentityCards((a) =>
                    a.map((d) => ({
                      ...d,
                      isUploaded:
                        d.id === identityCardId ? !d.isUploaded : d.isUploaded,
                    })),
                  );
                }}
              />
            </Col>
          )}
        </Row>
        <Row className="pb-5">
          <Col xs={12} lg={4} className="ml-auto">
            <Button
              text={t(`${prefix}.btnNext`)}
              onClick={() => onSubmit()}
              fullwidth
            />
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default Sign;
