import {
  Button,
  OutlinedSelect,
  OutlinedSelectOptionType,
  SearchBox,
  useMobile,
} from '@octano/global-ui';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'reactstrap';

import { SearchParams } from '../../../api/requests/Filters';
import {
  getFiltersMantainerPortalSection,
  Period,
} from '../../../api/requests/MantainerPortal';
import { useValidations } from '../../../hooks/useValidations';

const dictPrefix = 'closingPeriod.searchControls';

const DEFAULT_VALUES = {
  schoolId: null,
  campusId: null,
  searchSection: '',
};

interface FormValues {
  periodId: OutlinedSelectOptionType | null;
  schoolId: OutlinedSelectOptionType | null;
  campusId: OutlinedSelectOptionType | null;
  searchSection: string;
}

type onSearch = {
  searchParams: SearchParams;
  page: number;
};

interface SearchControlsProps {
  periods: { id: number; name: string }[];
  currentPeriod: Period;
  onSearch?: ({ searchParams, page }: onSearch) => void;
  onClear?: () => void;
  setFilters?: (params: any) => void;
}

export function SearchControls({
  periods,
  currentPeriod,
  onSearch = () => null,
  onClear = () => null,
  setFilters = (params: any) => {},
}: SearchControlsProps) {
  const { t } = useTranslation();
  const isMobile = useMobile();
  const { validateMinLength } = useValidations();

  const [schools, setSchools] = useState<OutlinedSelectOptionType[]>([]);
  const [campus, setCampus] = useState<OutlinedSelectOptionType[]>([]);

  const periodsOptions = useMemo<OutlinedSelectOptionType[]>(() => {
    return periods.map((p) => {
      return {
        value: p.id,
        label: p.name,
      };
    });
  }, [periods]);

  const periodSelected = useMemo(() => {
    return periodsOptions.find(({ value }) => currentPeriod?.id === value);
  }, [periodsOptions, currentPeriod]);

  const { handleSubmit, control, reset } = useForm<FormValues>({
    defaultValues: { ...DEFAULT_VALUES, periodId: periodSelected },
  });

  const handleSearch = (values: FormValues) => {
    const searchParams = {
      searchSection:
        values.searchSection?.trim() !== '' ? values.searchSection : undefined,
      periodId: values.periodId?.value as number,
      schoolId: values.schoolId?.value as number,
      campusId: values.campusId?.value as number,
      hasFilters: Object.values(values).some((value) => value !== null),
    };

    setFilters({
      ...searchParams,
    });

    onSearch({ searchParams, page: 0 });
  };

  const handleClear = () => {
    reset({ ...DEFAULT_VALUES, periodId: periodSelected });
    onClear();
    onSearch({
      searchParams: { periodId: periodSelected?.value as number },
      page: 0,
    });
  };

  const getFilters = useCallback(async () => {
    const { data } = await getFiltersMantainerPortalSection();
    if (data) {
      setSchools(
        data.schools.map(({ id: value, name: label }) => ({
          label,
          value,
        })),
      );
      setCampus(
        data.campus.map(({ id: value, name: label }) => ({
          label,
          value,
        })),
      );
      reset();
    }
  }, [reset]);

  useEffect(() => {
    getFilters();
  }, [getFilters]);

  return (
    <Form onSubmit={handleSubmit(handleSearch)}>
      <Row className="pb-4 gutters-filters">
        <Col xs={12} md={6} lg={4}>
          <OutlinedSelect
            name="periodId"
            label={t(`${dictPrefix}.periodId`)}
            placeholder={t(`${dictPrefix}.periodIdPlaceholder`)}
            control={control}
            options={periodsOptions}
            isClearable={false}
          />
        </Col>

        <Col xs={12} md={6} lg={4}>
          <OutlinedSelect
            name="schoolId"
            label={t(`${dictPrefix}.schoolId`)}
            placeholder={t(`${dictPrefix}.schoolIdPlaceholder`)}
            control={control}
            options={schools}
          />
        </Col>

        <Col xs={12} md={6} lg={4}>
          <OutlinedSelect
            name="campusId"
            label={t(`${dictPrefix}.campusId`)}
            placeholder={t(`${dictPrefix}.campusIdPlaceholder`)}
            control={control}
            options={campus}
          />
        </Col>

        <Col xs={12} md={6} lg={4}>
          <SearchBox
            name="searchSection"
            label={t(`${dictPrefix}.searchSection`)}
            placeholder={t(`${dictPrefix}.searchSectionPlaceholder`)}
            control={control}
            rules={{
              validate: {
                minLength: validateMinLength(3),
              },
            }}
          />
        </Col>

        <Col
          xs={12}
          md={{ size: 3, offset: 6 }}
          lg={{ size: 2, offset: 4 }}
          style={{ marginTop: isMobile ? 0 : '1.5rem' }}
        >
          <Button
            type="submit"
            text={t(`common.actions.search`)}
            size="md"
            fullwidth
          />
        </Col>

        <Col
          xs={12}
          md={{ size: 3 }}
          lg={2}
          style={{ marginTop: isMobile ? 0 : '1.5rem' }}
        >
          <Button
            text={t(`common.actions.clean`)}
            size="md"
            fullwidth
            outlined
            onClick={handleClear}
          />
        </Col>
      </Row>
    </Form>
  );
}
