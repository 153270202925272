import { addToast, Button } from '@octano/global-ui';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Card, Col, Row } from 'reactstrap';

import {
  getPreviewContract,
  getSignDocumentation,
  getSignedDocumentPreviewToken,
  verifySignDocumentation,
} from '../../../../api/requests/tuitionProcess';
import DisplayError from '../../../../components/info/DisplayError';
import Loading from '../../../../components/info/Loading';
import { useStepState } from '../../../../components/step/useStepState';
import { SectionTitle } from '../../../../components/text';
import { useLoadingState } from '../../../../hooks/useLoadingState';
import { useTuitionProcess } from '../../../../hooks/useTuitionProcess';
import {
  DocumentTypes,
  DocumentTypesEnum,
  IdentityTypes,
} from '../../../../types/signDocumentationTypes';
import { STATUS_POSTULATION } from '../../../../types/tuitionProcessOnSite';
import HeaderStep from '../HeaderStep';
import DocumentCard from './DocumentCard';

interface Identification {
  type: IdentityTypes;
  isUploaded: boolean;
}
interface Document {
  type: DocumentTypes;
  isSigned: boolean;
}

function isDocumentMissing({
  type,
  isSigned,
}: {
  type: DocumentTypes;
  isSigned: boolean;
}) {
  const optionalDocuments: DocumentTypes[] = ['nem'];
  return !optionalDocuments.includes(type) && !isSigned;
}

/**
 * Componente que corresponde a la vista principal del paso de contrato y subida de archivos
 */
const DocumentationNoSua = () => {
  const prefix = 'tuitionProcessNoSua.documentation';

  const history = useHistory();
  const { t } = useTranslation();
  const { nextStep } = useStepState();
  const { postulationDetail, statusPostulation } = useTuitionProcess();
  const { loading, setLoading, errorLoading, setErrorLoading } =
    useLoadingState();

  const [documents, setDocuments] = useState<Document[]>([]);
  const [identifications, setIdentifications] = useState<Identification[]>([]);
  const [documentsOthers, setDocumentsOthers] = useState<Document[]>([]);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const [showErrors, setShowErrors] = useState<boolean>(false);
  const requiredMsgError = t(`${prefix}.errorRequired`);

  const findDocumentOthers = useCallback(
    (type: DocumentTypes) => documentsOthers.find((doc) => doc.type === type),
    [documentsOthers],
  );

  const getIndex = useCallback(
    (type: DocumentTypes) => documents.findIndex((doc) => doc.type === type),
    [documents],
  );

  const indexContract = useMemo(
    () => getIndex(DocumentTypesEnum.contract),
    [getIndex],
  );
  const indexIncome = useMemo(
    () => getIndex(DocumentTypesEnum['sustainer-income-one']),
    [getIndex],
  );
  const indexLEM = useMemo(() => getIndex(DocumentTypesEnum.lem), [getIndex]);
  const indexNEM = useMemo(() => getIndex(DocumentTypesEnum.nem), [getIndex]);

  const getStatus = useCallback(async () => {
    setLoading(true);
    const { data, error } = await getSignDocumentation(postulationDetail?.id);
    if (data?.documents) {
      setDocuments(data.documents);
      setIdentifications(data.identityCards);
      setDocumentsOthers(data.documentsOthers);
      setErrorLoading(undefined);
    }
    if (error) {
      setErrorLoading(t('common.displayError.errorUnexpected'));
    }
    setLoading(false);
  }, [setLoading, setErrorLoading, t, postulationDetail]);

  useEffect(() => window.scrollTo(0, 0));

  useEffect(() => {
    getStatus();
  }, [getStatus]);

  const onSubmit = useCallback(async () => {
    setIsSubmitting(true);
    if (
      documents.some((doc) => isDocumentMissing(doc)) ||
      identifications.some((identification) => !identification.isUploaded)
    ) {
      setShowErrors(true);
      setIsSubmitting(false);
    } else {
      setShowErrors(false);
      const { error } = await verifySignDocumentation(postulationDetail?.id);
      setIsSubmitting(false);
      if (!error) {
        nextStep();
      } else {
        addToast({
          icon: 'error',
          color: 'danger',
          text: t(`${prefix}.nextStepError`),
        });
      }
    }
  }, [documents, postulationDetail, identifications, nextStep, t]);

  const onUploadDoc = (indexDoc: number) => {
    setDocuments((prevDocs) => {
      let newDocs = [...prevDocs];
      if (newDocs[indexDoc]) {
        newDocs[indexDoc].isSigned = true;
      }
      return newDocs;
    });
  };

  const onUploadDocOtherType = (documentType: DocumentTypes) => {
    setDocumentsOthers((prevDocs) => {
      const prevDoc = prevDocs.find((d) => d.type === documentType);
      if (prevDoc) {
        prevDoc.isSigned = true;
      }
      return [...prevDocs];
    });
  };

  const onUploadIdentification = (indexId: number) => {
    setIdentifications((prevIds) => {
      let ids = [...prevIds];
      ids[indexId].isUploaded = true;
      return ids;
    });
  };

  const getErrorTextDoc = useCallback(
    (indexDoc: number) => {
      return showErrors && isDocumentMissing(documents[indexDoc])
        ? requiredMsgError
        : undefined;
    },
    [showErrors, documents, requiredMsgError],
  );

  const downloadContract = useCallback(async () => {
    const { data, error } = await getSignedDocumentPreviewToken(
      postulationDetail?.id,
    );
    if (data && !error) {
      const url = getPreviewContract(data, postulationDetail?.id);
      window.open(url);
    } else if (error) {
      addToast({
        icon: 'error',
        color: 'danger',
        text: t(`common.errors.download`),
      });
    }
  }, [t, postulationDetail]);

  if (errorLoading) {
    return (
      <DisplayError
        insideCard
        textBody={errorLoading}
        retryAction={getStatus}
        loadingAction={loading}
      />
    );
  }
  if (loading) {
    return <Loading insideCard />;
  }
  return (
    <>
      <Card className="px-4 px-md-5 py-4">
        <HeaderStep
          prefixStep={prefix}
          showBackBtn={
            statusPostulation !== STATUS_POSTULATION.STUDY_PLAN_CHANGE
          }
        />
        <Row>
          <Col xs={12} className="pb-3">
            <SectionTitle text={t(`${prefix}.title`)} />
          </Col>
        </Row>

        {/* Listado de documentos a adjuntar */}
        <Row className="mx-n2 align-items-end">
          {indexContract > -1 && (
            <Col xs={12} md={6} lg={4} className="mb-3 px-2">
              <DocumentCard
                size="md"
                docName={'contract'}
                isUploaded={documents[indexContract].isSigned}
                errorText={getErrorTextDoc(indexContract)}
                onDownload={downloadContract}
                onUploaded={() => onUploadDoc(indexContract)}
              />
            </Col>
          )}
          {indexLEM > -1 && (
            <Col xs={12} md={6} lg={2} className="mb-3 px-2">
              <DocumentCard
                docName={'lem'}
                isUploaded={documents[indexLEM].isSigned}
                errorText={getErrorTextDoc(indexLEM)}
                onUploaded={() => onUploadDoc(indexLEM)}
              />
            </Col>
          )}
          {indexNEM > -1 && (
            <Col xs={12} md={6} lg={2} className="mb-3 px-2">
              <DocumentCard
                docName={'nem'}
                isUploaded={documents[indexNEM].isSigned}
                errorText={getErrorTextDoc(indexNEM)}
                onUploaded={() => onUploadDoc(indexNEM)}
              />
            </Col>
          )}

          {/* ADJUNTAR DOCUMENTACIÓN DE CARNET Ó PASAPORTE */}
          {identifications.map((identification, key) => {
            return (
              <Col
                xs={12}
                sm={6}
                lg={2}
                className="mb-3 px-2"
                key={`identification_${key}`}
              >
                <DocumentCard
                  docName={identification.type}
                  isUploaded={identification.isUploaded}
                  errorText={
                    showErrors && !identification.isUploaded
                      ? requiredMsgError
                      : undefined
                  }
                  onUploaded={() => onUploadIdentification(key)}
                />
              </Col>
            );
          })}
          {indexIncome > -1 && (
            <>
              <Col xs={12} md={6} lg={2} className="mb-3 px-2">
                <DocumentCard
                  docName={'sustainer-income-one'}
                  isUploaded={documents[indexIncome].isSigned}
                  errorText={getErrorTextDoc(indexIncome)}
                  onUploaded={() => onUploadDoc(indexIncome)}
                />
              </Col>
              <Col xs={12} md={6} lg={2} className="mb-3 px-2">
                <DocumentCard
                  docName={'sustainer-income-others'}
                  isUploaded={
                    findDocumentOthers(
                      DocumentTypesEnum['sustainer-income-others'],
                    )?.isSigned ?? false
                  }
                  onUploaded={() =>
                    onUploadDocOtherType(
                      DocumentTypesEnum['sustainer-income-others'],
                    )
                  }
                />
              </Col>
            </>
          )}
        </Row>
        <Row className="py-5 justify-content-end">
          <Col xs={12} lg={4} className="pb-2 order-2 order-lg-1">
            <Button
              type="button"
              outlined
              onClick={() => history.push('/tuition-process')}
              text={t(`common.actions.cancel`)}
              disabled={isSubmitting}
              loading={isSubmitting}
              fullwidth
            />
          </Col>

          <Col xs={12} lg={4} className="pb-2 order-1 order-lg-2">
            <Button
              text={t(`common.actions.next`)}
              onClick={onSubmit}
              disabled={isSubmitting}
              loading={isSubmitting}
              fullwidth
            />
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default DocumentationNoSua;
