import request from '../request';
import { GRADES_ENTRY } from '../endpoints';
import {
  GradesEntryConfig,
  GradesEntryConfigUpdatableVariables,
} from '../../types/GradesEntry';

/**
 * Obtiene la lista de configuraciones de la escala de notas
 * @returns
 */
export function getGradesEntryConfig() {
  return request<GradesEntryConfig[]>(GRADES_ENTRY.CONFIG);
}

/**
 * Actualiza los valores de configuracion de la escala de notas.
 * Si el valor ya existia lo cambia, si no existia lo crea
 * @param data Objeto con los nuevos valores de config
 * @returns
 */
export function updateGradesEntryConfigValues(
  data: GradesEntryConfigUpdatableVariables,
) {
  return request(GRADES_ENTRY.CONFIG, {
    method: 'POST',
    data,
  });
}
