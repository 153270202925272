import { Dispatch } from 'react';
import { AdmissionType } from '../api/requests/tuitionProcess';

export enum TuitionProcessActionTypes {
  SET_POSTULANT = 'SET_POSTULANT',
  SET_STATUS = 'SET_STATUS',
  SET_POSTULATION_DETAIL = 'SET_POSTULATION_DETAIL',
  SET_NEW_POSTULATION_DETAIL = 'SET_NEW_POSTULATION_DETAIL',
}

export type TuitionProcessAction =
  | {
      type: TuitionProcessActionTypes.SET_POSTULANT;
      payload: PostulantInfoType;
    }
  | { type: TuitionProcessActionTypes.SET_STATUS; payload: string }
  | {
      type: TuitionProcessActionTypes.SET_POSTULATION_DETAIL;
      payload: PostulationDetailType | null;
    }
  | {
      type: TuitionProcessActionTypes.SET_NEW_POSTULATION_DETAIL;
      payload: AdmissionType | null;
    };

export type TuitionProcessState = {
  postulant: PostulantInfoType | null;
  statusPostulation: string;
  postulationDetail: PostulationDetailType | null;
  newPostulationDetail: AdmissionType | null;
};

export type PostulantInfoType = { id: number; fullName: string };

export type PostulationDetailType = {
  id: number;
  admissionId: number;
  campus: {
    id: number;
    name: string;
  };
  schedule: {
    id: number;
    name: string;
  };
  studyPlanName: string;
  admissionMethod: 'con-sua' | 'sin-sua';
};

export type TuitionProcessContextType = {
  state: TuitionProcessState;
  dispatch: Dispatch<TuitionProcessAction>;
};
