import { MAINTAINERS } from '../endpoints';
import request from '../request';

export interface PeriodType {
  id: string;
  name: string;
  namePlural: string;
  description?: string | null;
}

export interface PeriodsTypeListResponse {
  data: PeriodType[];
  total: number;
  total_pages: number;
}

/**
 * Obtiene todos los tipos de periodos
 */
export const getAllPeriodsTypeRequest = () => {
  const url = `${MAINTAINERS.PERIODS_TYPE}/all`;
  return request<PeriodType[]>(url);
};

/**
 * Obtiene el listado de tipos de periodos
 */
export const getPeriodsTypeRequest = (items: number = 10, page: number = 0) => {
  const url = `${MAINTAINERS.PERIODS_TYPE}?items_per_page=${items}&page=${page}`;
  return request<PeriodsTypeListResponse>(url);
};
