import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  getSchoolsRequest,
  SchoolsListItem,
  SchoolsListResponse,
} from '../../api/requests/schools';
import { useTranslation } from 'react-i18next';
import DisplayError from '../../components/info/DisplayError';
import { Table, Button, Icon, useMobile } from '@octano/global-ui';
import { TextEllipsis } from '../../components/text';
import { useLoadingState } from '../../hooks/useLoadingState';
import TableEmptyContent from '../../components/text/TableEmptyContent';
import Loading from '../../components/info/Loading';
import {
  CellFormatOptions,
  ColumnTable,
  PaginationType,
} from '@octano/global-ui/dist/components/Table/types/TableTypes';
import { useLayoutState } from '../../hooks/useLayoutState';
import SchoolModal from './SchoolModal';
import { PermissionName } from '../../types/Auth';
import useUserState from '../../hooks/useUserState';

const ITEMS_PER_PAGE_DEFAULT = 10;
const PAGE_INITIAL_DEFAULT = 1;

const SchoolsList = () => {
  const { isAuthorizedTo } = useUserState();
  const { showTabs } = useLayoutState();
  const isMobile = useMobile();
  const [showForm, setShowForm] = useState<boolean>(false);
  const [recordToEdit, setRecordToEdit] = useState(null);

  useEffect(() => {
    showTabs();
  }, [showTabs]);

  useEffect(() => {
    if (!showForm) {
      setRecordToEdit(null);
    }
  }, [showForm]);

  const { t } = useTranslation();

  const [schoolsData, setSchoolsData] = useState<SchoolsListResponse>({
    data: [],
    total_pages: 0,
    total: 0,
  });

  const [currentPage, setCurrentPage] = useState<number>(PAGE_INITIAL_DEFAULT);
  const [itemsPerPage] = useState<number>(ITEMS_PER_PAGE_DEFAULT);
  const { loading, setLoading, errorLoading, setErrorLoading } =
    useLoadingState();

  const getSchoolsList = useCallback(async () => {
    setShowForm(false);
    setRecordToEdit(null);
    const { error, data: response } = await getSchoolsRequest(
      itemsPerPage,
      currentPage - 1,
    );
    if (response) {
      setSchoolsData(response);
      setErrorLoading(undefined);
    } else if (error) {
      setErrorLoading(error.code);
      return;
    }
    setLoading(false);
  }, [itemsPerPage, currentPage, setErrorLoading, setLoading]);

  useEffect(() => {
    getSchoolsList();
  }, [getSchoolsList]);

  const handlerOnChangePage = useCallback(
    async (page: number) => setCurrentPage(page),
    [],
  );

  const pagination = useMemo<PaginationType | undefined>(() => {
    if (schoolsData.total > itemsPerPage) {
      return {
        totalItems: schoolsData.total,
        itemsPerPage: itemsPerPage,
        totalPages: schoolsData.total_pages,
        currentPage: currentPage,
        onChangePage: handlerOnChangePage,
      };
    }
  }, [schoolsData, itemsPerPage, currentPage, handlerOnChangePage]);

  const columns = useMemo<ColumnTable<SchoolsListItem>[]>(() => {
    const editColumn = {
      columnName: 'id',
      headerText: '',
      width: '0%',
      cellFormat: (options: CellFormatOptions) => (
        <span
          onClick={() => {
            if (isAuthorizedTo([PermissionName.EDIT_FACULTY])) {
              setRecordToEdit(options.row);
              setShowForm(true);
            }
          }}
          className="cursor-pointer"
        >
          <Icon
            name="edit"
            key="edit"
            color="secondary"
            className={
              !isAuthorizedTo([PermissionName.EDIT_FACULTY])
                ? 'icon-disabled'
                : ''
            }
          />
        </span>
      ),
    };
    return !isMobile
      ? [
          {
            columnName: 'code',
            headerText: t('common.forms.code'),
            bold: true,
            width: '20%',
          },
          {
            columnName: 'name',
            headerText: t('common.forms.name'),
            width: '20%',
          },
          {
            columnName: 'description',
            headerText: t('common.forms.description'),
            cellFormat: (options: CellFormatOptions) => {
              return (
                <TextEllipsis key={options.index} textWidth={350}>
                  {options.value}
                </TextEllipsis>
              );
            },
          },
          editColumn,
        ]
      : [
          {
            columnName: 'name',
            headerText: t('common.forms.name'),
            width: '100%',
            cellFormat: (options: CellFormatOptions) => {
              return <div className="text-left">{options.value}</div>;
            },
          },
          editColumn,
        ];
  }, [t, isMobile, isAuthorizedTo]);

  if (errorLoading) {
    return (
      <DisplayError
        insideCard
        textBody={errorLoading}
        retryAction={() => setLoading(true)}
        loadingAction={loading}
      />
    );
  }
  if (loading) {
    return <Loading insideCard />;
  }
  return (
    <div className="g-table-container">
      <Button
        text={t('maintainers.schoolsForm.addTitle')}
        type="button"
        size="sm"
        icon="plus"
        className="g-add-button mb-3 mt-3"
        onClick={() => setShowForm(true)}
        disabled={!isAuthorizedTo([PermissionName.CREATE_FACULTY])}
      />
      <Table
        columns={columns}
        data={schoolsData.data}
        pagination={pagination}
        noResultsText={
          <TableEmptyContent
            title={t('maintainers.schoolsList.schoolsTableEmptyTitle')}
            subtitle={t('maintainers.schoolsList.schoolsTableEmptySubtitle')}
          />
        }
      />
      <SchoolModal
        show={showForm}
        reloadSchoolsList={getSchoolsList}
        toggleModal={() => {
          setShowForm(!showForm);
          setRecordToEdit(null);
        }}
        recordToEdit={recordToEdit}
      />
    </div>
  );
};

export default SchoolsList;
