import { Fragment, ReactChild, useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { Form } from 'reactstrap';
import {
  COURSE_TYPES,
  CourseInfo,
  CurriculumValues,
  ERRORS_DEFAULT,
  LEGEND_DEFAULT,
  LegendInfo,
} from '../../types/CurriculumTypes';
import { transformData } from './CurriculumDegreeActivities/dataTransformation';
import { CurriculumErrorModal } from './CurriculumErrorModal';
import CurriculumFormContent from './CurriculumFormContent';
import { CurriculumPublishModal } from './CurriculumPublishModal';
import CurriculumSemester from './CurriculumSemester';
import { CurriculumSemesterDeleteModal } from './CurriculumSemesterDeleteModal';
import { CourseModal } from './course/CourseModal';
import { PeriodType } from '../../types/PeriodType';

// Always is undefined defaultValues props, because never is sending for parent-component
const getDefaultValues = (defaultValues?: CurriculumValues) =>
  defaultValues
    ? {
        mentionCode: defaultValues.mentionCode,
        mentionName: defaultValues.mentionName,
        totalCredits: defaultValues.totalCredits,
        requiredCredits: defaultValues.requiredCredits,
        optionalCredits: defaultValues.optionalCredits,
        otherCredits: defaultValues.otherCredits,
        activitiesNumber: defaultValues.activitiesNumber,
        periodType: defaultValues.periodType,
        curriculumActivities: defaultValues.activitiesType
          ? transformData(defaultValues.activitiesType)
          : [],
        hasActivitiesChecked: !defaultValues.activitiesType.length,
      }
    : {
        mentionCode: undefined,
        mentionName: undefined,
        totalCredits: undefined,
        requiredCredits: undefined,
        optionalCredits: undefined,
        otherCredits: undefined,
        activitiesNumber: undefined,
        periodType: undefined,
        curriculumActivities: [],
        hasActivitiesChecked: false,
      };

export interface CurriculumFormProps {
  curriculumData: any;
  periodType?: PeriodType;
  defaultValues?: CurriculumValues;
  setDisabledEditButton: (value: boolean) => void;
  children: (props: {
    isSubmitting: boolean;
    isDirty?: boolean;
    legend: any;
  }) => ReactChild;
  studyPlanName: string;
  readOnly: boolean;
  isMention: boolean;
}

const CurriculumForm = (props: CurriculumFormProps) => {
  const {
    defaultValues,
    children,
    studyPlanName,
    curriculumData,
    readOnly,
    setDisabledEditButton,
    isMention,
    periodType,
  } = props;

  const { versionId } = useParams<{ id?: string; versionId?: string }>();

  let optionsSelectSemesters = new Array(20).fill({});

  optionsSelectSemesters = optionsSelectSemesters.map((e: any, index: any) => ({
    label: index === 0 ? `${index + 1} Periodo` : `${index + 1} Periodos`,
    value: `SEM_${index + 1}`,
    key: index + 1,
  }));

  const [semesterCount, setSemesterCount] = useState<number>(1);
  const [currentSemester, setCurrentSemester] = useState<number>(0);
  const [showCourseModal, setShowCourseModal] = useState<boolean>(false);
  const [showCurriculumErrorModal, setShowCurriculumErrorModal] =
    useState<boolean>(false);
  const [showCurriculumPublishModal, setShowCurriculumPublishModal] =
    useState<boolean>(false);
  const [
    showCurriculumSemesterDeleteModal,
    setShowCurriculumSemesterDeleteModal,
  ] = useState<boolean>(false);
  const [coursesList, setCoursesList] = useState<Array<CourseInfo>>([]);
  const [curriculumFormattedData, setCurriculumFormattedData] = useState<any>(
    {},
  );
  const [errors, setErrors] = useState<any>(ERRORS_DEFAULT);
  const [legend, setLegend] = useState<LegendInfo>(LEGEND_DEFAULT);

  const methods = useForm<CurriculumValues>({
    mode: 'onSubmit',
    defaultValues: getDefaultValues(defaultValues),
  });

  const {
    handleSubmit,
    formState: { isSubmitting, isDirty },
    watch,
    setValue,
  } = methods;

  const [
    mentionCodeValue,
    mentionNameValue,
    semestersValue,
    totalCreditsValue,
    requiredCreditsValue,
    optionalCreditsValue,
    otherCreditsValue,
    activitiesNumberValue,
  ] = watch([
    'mentionCode',
    'mentionName',
    'semestersAmount',
    'totalCredits',
    'requiredCredits',
    'optionalCredits',
    'otherCredits',
    'activitiesNumber',
  ]);

  const addCourse = useCallback(
    (course: CourseInfo): boolean => {
      if (course.type === COURSE_TYPES.OBLIGATORIO.alias) {
        const search = coursesList.find((row) => row.code === course.code);
        if (!search) {
          setLegend({
            ...legend,
            total: legend.total + parseInt(course.credits.toString()),
            totalNeeded: totalCreditsValue,
            required: legend.required + parseInt(course.credits.toString()),
            requiredNeeded: requiredCreditsValue,
          });
          setCoursesList([...coursesList, course]);
          return true;
        } else {
          return false;
        }
      } else {
        if (course.type.includes('Optativo'))
          setLegend({
            ...legend,
            total: legend.total + parseInt(course.credits.toString()),
            totalNeeded: totalCreditsValue,
            optional: legend.optional + parseInt(course.credits.toString()),
            optionalNeeded: optionalCreditsValue,
          });
        if (course.type === COURSE_TYPES.OTROS.alias)
          setLegend({
            ...legend,
            total: legend.total + parseInt(course.credits.toString()),
            totalNeeded: totalCreditsValue,
            other: legend.other + parseInt(course.credits.toString()),
            otherNeeded: otherCreditsValue,
          });
        if (course.type === COURSE_TYPES.ACTIVIDAD.alias)
          setLegend({
            ...legend,
            activities: legend.activities + 1,
            activitiesNeeded: activitiesNumberValue,
          });
        setCoursesList([...coursesList, course]);
        return true;
      }
    },
    [
      legend,
      setLegend,
      coursesList,
      setCoursesList,
      totalCreditsValue,
      requiredCreditsValue,
      optionalCreditsValue,
      otherCreditsValue,
      activitiesNumberValue,
    ],
  );

  const validateCourses = useCallback(
    (formValues) => {
      const semestersArray = new Array(semesterCount).fill(0);
      const foundedErrors: any = {};
      coursesList.forEach((course) => {
        semestersArray[course.semester - 1] += 1;
      });

      if (legend.total !== parseInt(formValues.totalCredits.toString())) {
        foundedErrors.has = true;
        foundedErrors.total = true;
      }
      if (legend.required !== parseInt(formValues.requiredCredits.toString())) {
        foundedErrors.has = true;
        foundedErrors.required = true;
      }
      if (legend.optional !== parseInt(formValues.optionalCredits.toString())) {
        foundedErrors.has = true;
        foundedErrors.optional = true;
      }
      if (legend.other !== parseInt(formValues.otherCredits.toString())) {
        foundedErrors.has = true;
        foundedErrors.other = true;
      }
      if (
        legend.activities !== parseInt(formValues.activitiesNumber.toString())
      ) {
        foundedErrors.has = true;
        foundedErrors.activities = true;
      }
      semestersArray.forEach((semester) => {
        if (semester === 0) {
          foundedErrors.has = true;
          foundedErrors.semester = true;
        }
      });
      if (
        !formValues.hasActivitiesChecked &&
        formValues.curriculumActivities.length === 0
      ) {
        foundedErrors.has = true;
        foundedErrors.activitiesChecked = true;
      }
      setErrors({
        ...errors,
        ...foundedErrors,
      });

      return {
        ...errors,
        ...foundedErrors,
      };
    },
    [errors, setErrors, coursesList, legend, semesterCount],
  );

  const printSemesters = (count: number) => {
    let semesters = [];
    for (let i = 0; i < count; i++) {
      semesters.push(
        <CurriculumSemester
          key={i}
          id={i + 1}
          periodTypeName={periodType?.name}
          setCurrentSemester={setCurrentSemester}
          setShowCourseModal={setShowCourseModal}
          semesterCoursesList={coursesList.filter(
            (course) => course.semester === i + 1,
          )}
          setCoursesList={setCoursesList}
          coursesList={coursesList}
          readOnly={readOnly}
          legend={legend}
          setLegend={setLegend}
          setDisabledEditButton={setDisabledEditButton}
        />,
      );
    }
    return semesters;
  };

  const formatSemesters = useCallback(() => {
    const semestersObject: any = {};
    coursesList.forEach((course) => {
      if (!semestersObject[course.semester]) {
        semestersObject[course.semester] = {};
        semestersObject[course.semester].semesterId = course.semesterId;
        semestersObject[course.semester].semesterList = [];
      }
      let auxCourse: any = {};
      if (course.semesterCourseId) auxCourse.id = course.semesterCourseId;
      if (course.type === COURSE_TYPES.OBLIGATORIO.alias)
        auxCourse = {
          ...auxCourse,
          courseType: {
            ...COURSE_TYPES.OBLIGATORIO,
          },
          course: {
            id: course.id,
            name: course.name,
            code: course.code,
            shortening: course.shortening,
            credits: parseInt(course.credits.toString()),
          },
        };
      if (course.type === COURSE_TYPES.OPTATIVO_RELIGIOSO.alias)
        auxCourse = {
          ...auxCourse,
          courseType: {
            ...COURSE_TYPES.OPTATIVO_RELIGIOSO,
          },
        };
      if (course.type === COURSE_TYPES.OPTATIVO_DEPORTIVO.alias)
        auxCourse = {
          ...auxCourse,
          courseType: {
            ...COURSE_TYPES.OPTATIVO_DEPORTIVO,
          },
        };
      if (course.type === COURSE_TYPES.OPTATIVO_OTRO.alias)
        auxCourse = {
          ...auxCourse,
          courseType: {
            ...COURSE_TYPES.OPTATIVO_OTRO,
          },
        };
      if (course.type === COURSE_TYPES.OTROS.alias)
        auxCourse = {
          ...auxCourse,
          courseType: {
            ...COURSE_TYPES.OTROS,
          },
        };
      if (course.type === COURSE_TYPES.ACTIVIDAD.alias)
        auxCourse = {
          ...auxCourse,
          courseType: {
            ...COURSE_TYPES.ACTIVIDAD,
          },
          course: {
            id: course.id,
            name: course.name,
            code: course.code,
            shortening: course.shortening,
            credits: 0,
          },
        };
      if (
        course.type !== COURSE_TYPES.ACTIVIDAD.alias &&
        course.type !== COURSE_TYPES.OBLIGATORIO.alias
      )
        auxCourse.optionalSlot = {
          id: course.id,
          name: course.type,
          credits: parseInt(course.credits.toString()),
        };
      semestersObject[course.semester].semesterList.push(auxCourse);
    });

    const semestersArray: Array<any> = [];
    for (let index = 1; index <= semesterCount; index++) {
      let auxCurrentSemester: any = {};
      if (semestersObject[index].semesterId !== undefined)
        auxCurrentSemester.id = semestersObject[index].semesterId;
      semestersArray.push({
        ...auxCurrentSemester,
        name: `Periodo ${index}`,
        semesterIndex: index,
        semesterCourses: semestersObject[index].semesterList,
      });
    }

    return semestersArray;
  }, [coursesList, semesterCount]);

  const onSubmit = useCallback(
    async (formValues: CurriculumValues) => {
      let {
        mentionCode,
        mentionName,
        totalCredits,
        requiredCredits,
        optionalCredits,
        otherCredits,
        activitiesNumber,
        curriculumActivities,
      } = formValues;
      if (
        parseInt(totalCredits.toString()) !==
        parseInt(requiredCredits.toString()) +
          parseInt(optionalCredits.toString()) +
          parseInt(otherCredits.toString())
      ) {
        setShowCurriculumErrorModal(true);
      } else {
        const currentErrors = validateCourses(formValues);
        if (currentErrors.has) {
          setShowCurriculumErrorModal(true);
        } else {
          let mentionData = {};
          if (isMention) {
            mentionData = {
              name: mentionName,
              code: mentionCode,
            };
          } else if (
            curriculumData &&
            curriculumData.name &&
            curriculumData.code
          ) {
            mentionData = {
              name: curriculumData.name,
              code: curriculumData.code,
            };
          }
          const data = {
            ...mentionData,
            status: 'borrador',
            isPrimary: !isMention,
            duration: parseInt(semesterCount.toString()),
            totalCredits: parseInt(totalCredits.toString()),
            obligatoryCredits: parseInt(requiredCredits.toString()),
            optionalCredits: parseInt(optionalCredits.toString()),
            otherCredits: parseInt(otherCredits.toString()),
            activityNumber: parseInt(activitiesNumber.toString()),
            studyPlanVersion: {
              id: versionId,
            },
            semesters: formatSemesters(),
            curriculumActivities: curriculumActivities,
          };
          setCurriculumFormattedData(data);
          setShowCurriculumPublishModal(true);
        }
      }
    },
    [
      formatSemesters,
      isMention,
      semesterCount,
      versionId,
      validateCourses,
      curriculumData,
    ],
  );

  const setPeriodTypeInputToValue = useCallback(
    (type?: PeriodType) => {
      if (type) {
        setValue('periodType', {
          label: type?.name,
          value: type?.id,
        });
      }
    },
    [setValue],
  );

  const setSemesterInputToValue = useCallback(
    (index: number) => {
      setValue(
        'semestersAmount',
        optionsSelectSemesters.find((item: any) => item.key === index) || null,
      );
    },
    [setValue, optionsSelectSemesters],
  );

  useEffect(() => {
    setValue('mentionCode', curriculumData?.code || '');
    setValue('mentionName', curriculumData?.name || '');
    setSemesterInputToValue(curriculumData?.duration);
    setValue('totalCredits', curriculumData?.totalCredits || '0');
    setValue('requiredCredits', curriculumData?.obligatoryCredits || '0');
    setValue('optionalCredits', curriculumData?.optionalCredits || '0');
    setValue('otherCredits', curriculumData?.otherCredits || '0');
    setValue('activitiesNumber', curriculumData?.activityNumber || '0');
    setSemesterCount(curriculumData?.duration);
    setPeriodTypeInputToValue(periodType);
    const auxCourses: CourseInfo[] = [];
    let auxLegend: any = {
      ...LEGEND_DEFAULT,
    };
    curriculumData?.semesters.forEach((semester: any) => {
      semester.semesterCourses.forEach((courseData: any) => {
        let auxType = '';
        for (const [, courseType] of Object.entries(COURSE_TYPES)) {
          if (courseData.courseType.id === courseType.id)
            auxType = courseType.alias;
        }
        auxCourses.push({
          id: courseData.course?.id || courseData.optionalSlot?.id || undefined,
          type: auxType,
          name: courseData.course?.name || courseData.optionalSlot?.name || '',
          code: courseData.course?.code || '',
          credits:
            courseData.course?.credits || courseData.optionalSlot?.credits || 0,
          shortening: courseData.course?.shortening || '',
          semester: semester.semesterIndex,
          semesterId: semester.id,
          semesterCourseId: courseData.id,
        });

        if (auxType === COURSE_TYPES.OBLIGATORIO.alias) {
          auxLegend = {
            ...auxLegend,
            total: auxLegend.total + parseInt(courseData.course?.credits),
            totalNeeded: totalCreditsValue,
            required: auxLegend.required + parseInt(courseData.course?.credits),
            requiredNeeded: requiredCreditsValue,
          };
        } else if (auxType.includes('Optativo')) {
          auxLegend = {
            ...auxLegend,
            total:
              auxLegend.total + parseInt(courseData.optionalSlot?.credits || 0),
            totalNeeded: totalCreditsValue,
            optional:
              auxLegend.optional +
              parseInt(courseData.optionalSlot?.credits || 0),
            optionalNeeded: optionalCreditsValue,
          };
        } else if (auxType === COURSE_TYPES.OTROS.alias) {
          auxLegend = {
            ...auxLegend,
            total:
              auxLegend.total + parseInt(courseData.optionalSlot?.credits || 0),
            totalNeeded: totalCreditsValue,
            other:
              auxLegend.other + parseInt(courseData.optionalSlot?.credits || 0),
            otherNeeded: otherCreditsValue,
          };
        } else if (auxType === COURSE_TYPES.ACTIVIDAD.alias) {
          auxLegend = {
            ...auxLegend,
            activities: auxLegend.activities + 1,
            activitiesNeeded: activitiesNumberValue,
          };
        }
      });
    });
    setCoursesList(auxCourses);
    setLegend(auxLegend);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curriculumData, periodType]);

  useEffect(() => {
    if (
      semestersValue &&
      semestersValue.key < semesterCount &&
      coursesList.findIndex(
        (course) => course.semester > semestersValue.key,
      ) !== -1
    ) {
      setShowCurriculumSemesterDeleteModal(true);
    } else {
      setSemesterCount(semestersValue?.key || 1);
    }
  }, [semestersValue, coursesList, semesterCount]);

  useEffect(() => {
    setLegend({
      ...legend,
      totalNeeded: totalCreditsValue || legend.totalNeeded,
      requiredNeeded: requiredCreditsValue || legend.requiredNeeded,
      optionalNeeded: optionalCreditsValue || legend.optionalNeeded,
      otherNeeded: otherCreditsValue || legend.otherNeeded,
      activitiesNeeded: activitiesNumberValue || legend.activitiesNeeded,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    totalCreditsValue,
    requiredCreditsValue,
    optionalCreditsValue,
    otherCreditsValue,
    activitiesNumberValue,
  ]);

  useEffect(() => {
    if (
      (curriculumData && curriculumData.code !== mentionCodeValue) ||
      (curriculumData && curriculumData.name !== mentionNameValue) ||
      (curriculumData &&
        totalCreditsValue &&
        curriculumData.totalCredits !==
          parseInt(totalCreditsValue.toString())) ||
      (curriculumData &&
        requiredCreditsValue &&
        curriculumData.obligatoryCredits !==
          parseInt(requiredCreditsValue.toString())) ||
      (curriculumData &&
        optionalCreditsValue &&
        curriculumData.optionalCredits !==
          parseInt(optionalCreditsValue.toString())) ||
      (curriculumData &&
        otherCreditsValue &&
        curriculumData.otherCredits !==
          parseInt(otherCreditsValue.toString())) ||
      (curriculumData &&
        activitiesNumberValue &&
        curriculumData.activityNumber !==
          parseInt(activitiesNumberValue.toString())) ||
      (curriculumData && curriculumData.duration !== semesterCount)
    ) {
      setDisabledEditButton(false);
    }
  }, [
    mentionCodeValue,
    mentionNameValue,
    totalCreditsValue,
    requiredCreditsValue,
    optionalCreditsValue,
    otherCreditsValue,
    activitiesNumberValue,
    semesterCount,
    curriculumData,
    setDisabledEditButton,
  ]);

  return (
    <>
      <Fragment>
        <FormProvider {...methods}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <CurriculumFormContent
              hasPeriodType={!!periodType}
              isMention={isMention}
              readOnly={readOnly}
              printSemesters={printSemesters}
              optionsSelectSemesters={optionsSelectSemesters}
              periodTypes={[]}
              semesterCount={semesterCount}
            />
            {children({ isSubmitting, isDirty, legend })}
          </Form>
        </FormProvider>
        <CourseModal
          show={showCourseModal}
          addCourse={addCourse}
          semester={currentSemester}
          onClose={useCallback(() => {
            setShowCourseModal(false);
          }, [setShowCourseModal])}
          studyPlanName={studyPlanName}
          setDisabledEditButton={setDisabledEditButton}
        />
        <CurriculumErrorModal
          show={showCurriculumErrorModal}
          semesterError={errors.has || false}
          errors={errors}
          legend={legend}
          onClose={useCallback(() => {
            setShowCurriculumErrorModal(false);
            setErrors(ERRORS_DEFAULT);
          }, [setShowCurriculumErrorModal, setErrors])}
        />
        <CurriculumPublishModal
          show={showCurriculumPublishModal}
          curriculumData={curriculumFormattedData}
          onClose={useCallback(() => {
            setShowCurriculumPublishModal(false);
            setErrors(ERRORS_DEFAULT);
          }, [setShowCurriculumPublishModal, setErrors])}
        />
        <CurriculumSemesterDeleteModal
          show={showCurriculumSemesterDeleteModal}
          semesterIndex={semestersValue?.key}
          setCoursesList={setCoursesList}
          coursesList={coursesList}
          semesterCount={semesterCount}
          setSemesterCount={setSemesterCount}
          setSemesterInputToValue={setSemesterInputToValue}
          legend={legend}
          setLegend={setLegend}
          onClose={useCallback(() => {
            setShowCurriculumSemesterDeleteModal(false);
          }, [setShowCurriculumSemesterDeleteModal])}
        />
      </Fragment>
    </>
  );
};

export default CurriculumForm;
