import { addToast, Button, Select, SelectOptionType } from '@octano/global-ui';
import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Card, Col, Form, Row } from 'reactstrap';

import { getServerTimezone } from '../../../../api/requests/settings';
import {
  getTariffInfo,
  saveTariffInfo,
} from '../../../../api/requests/tuitionProcess';
import DisplayError from '../../../../components/info/DisplayError';
import Loading from '../../../../components/info/Loading';
import { useStepState } from '../../../../components/step/useStepState';
import DiscountPostulation from '../../../../discount/postulation';
import { useLoadingState } from '../../../../hooks/useLoadingState';
import { useTuitionProcess } from '../../../../hooks/useTuitionProcess';
import { useValidations } from '../../../../hooks/useValidations';
import { QuotaItem, TariffData } from '../../../../types/tariffTypes';
import { STATUS_POSTULATION } from '../../../../types/tuitionProcessOnSite';
import { moneyFormatter } from '../../../../utils/currency';
import HeaderStep from '../HeaderStep';
import TariffDisplayData from './TariffDisplayData';
import TariffTable from './TariffTable';
import { generateDateRangeString } from './utils';

export default function Tariff() {
  const prefix = 'tuitionProcessNoSua.tariffQuotas';

  const methods = useForm();
  const history = useHistory();
  const { t } = useTranslation();
  const { nextStep } = useStepState();
  const { msgValidations } = useValidations();
  const { postulationDetail, statusPostulation, postulant } =
    useTuitionProcess();
  const { loading, setLoading, errorLoading, setErrorLoading } =
    useLoadingState();

  const [currentDate, setCurrentDate] = useState<dayjs.Dayjs>(dayjs());
  const [tariffData, setTariffData] = useState<TariffData>();

  const { handleSubmit, formState, control, watch } = methods;

  const quota: SelectOptionType = watch('quota');

  /* Función que se llama cuando se monta el componente. Trae la info utilizada en la vista */
  const getTariffQuotaInfo = useCallback(async () => {
    setLoading(true);

    const [tariffResponse, timeResponse] = await Promise.all([
      getTariffInfo(postulationDetail?.id),
      getServerTimezone(),
    ]);

    if (tariffResponse.data && timeResponse.data) {
      setTariffData(tariffResponse.data.data);
      const utcDate = dayjs
        .utc(timeResponse.data.dateLocalOffset)
        .hour(0)
        .minute(0)
        .second(0);

      const condition =
        utcDate.month() > 3 || (utcDate.month() === 3 && utcDate.date() >= 23);
      if (condition) setCurrentDate(utcDate.year(dayjs().year() + 1).month(2));
      else setCurrentDate(utcDate.month(2));

      setErrorLoading(undefined);
    }
    if (tariffResponse.error) {
      setErrorLoading(tariffResponse.error.code);
    }
    if (timeResponse.error) {
      setErrorLoading(timeResponse.error.code);
    }
    setLoading(false);
  }, [postulationDetail, setErrorLoading, setLoading]);

  const onSubmit = useCallback(
    async (values) => {
      const currentQuota = tariffData?.quotas.find(
        (q) => q.quota === values.quota.value,
      );

      const { data, error } = await saveTariffInfo(
        postulationDetail!.id,
        currentQuota!.id,
      );
      if (data) {
        addToast({
          icon: 'check',
          color: 'success',
          text: t(`${prefix}.successOnSaveTariff`),
        });
        nextStep();
      }
      if (error) {
        addToast({
          icon: 'error',
          color: 'danger',
          text: t(`${prefix}.errorOnSaveTariff`),
        });
      }
    },
    [postulationDetail, t, tariffData?.quotas, nextStep],
  );

  /* Un hook useMemo que se usa para calcular las cuotas que son mostradas en las tablas */
  const quotas: QuotaItem[] = useMemo(() => {
    const arrayQuotas = Array.from({
      length: quota?.value as number,
    }).map((_, index) => {
      return {
        quota: `cuota ${index + 1}`,
        expirationDate: generateDateRangeString(
          index,
          tariffData!.payday.value,
          currentDate,
        ),
        amount: moneyFormatter().format(
          Math.ceil(tariffData!.studyPlan.tariff.value / Number(quota.value)),
        ),
      };
    });
    if (arrayQuotas.length > 0)
      return [
        ...arrayQuotas,
        { total: moneyFormatter().format(tariffData!.studyPlan.tariff.value) },
      ];
    return arrayQuotas;
  }, [currentDate, tariffData, quota]);

  useEffect(() => {
    getTariffQuotaInfo();
  }, [getTariffQuotaInfo]);

  const options = useMemo(() => {
    let arrayQuotas: SelectOptionType[] = [];

    if (tariffData?.quotas) {
      arrayQuotas = tariffData?.quotas
        .map<SelectOptionType>(({ quota: value, description: label }) => ({
          label,
          value,
        }))
        .sort(
          (a: SelectOptionType, b: SelectOptionType): number =>
            Number(a.value) - Number(b.value),
        );
    }
    return arrayQuotas;
  }, [tariffData]);

  if (errorLoading) {
    return (
      <DisplayError
        insideCard
        textBody={errorLoading}
        retryAction={() => getTariffQuotaInfo()}
        loadingAction={loading}
      />
    );
  }

  if (loading) {
    return <Loading insideCard />;
  }

  return (
    <Card className="px-4 px-lg-5 py-4">
      <HeaderStep
        prefixStep={prefix}
        showBackBtn={statusPostulation !== STATUS_POSTULATION.STUDY_PLAN_CHANGE}
      />
      <Row>
        <Col xs={12} className="pt-lg-4 pb-lg-5">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <TariffDisplayData tariffData={tariffData!}>
              <>
                <Select
                  name="quota"
                  label={t(`${prefix}.displayData.quotas`)}
                  isClearable={false}
                  options={options}
                  control={control}
                  rules={{ required: msgValidations.required }}
                />
                <DiscountPostulation
                  postulantId={postulant?.id}
                  postulationDetail={postulationDetail}
                  reloadComponent={getTariffQuotaInfo}
                />
              </>
            </TariffDisplayData>

            <TariffTable quotas={quotas} />
            <Row className="pt-5 mt-5 justify-content-end">
              <Col xs={12} lg={4} className="pb-2 order-2 order-lg-1">
                <Button
                  type="button"
                  outlined
                  onClick={() => history.push('/tuition-process')}
                  text={t(`common.actions.cancel`)}
                  loading={formState.isSubmitting}
                  fullwidth
                />
              </Col>

              <Col xs={12} lg={4} className="pb-2 order-1 order-lg-2">
                <Button
                  type="submit"
                  text={t(`common.actions.next`)}
                  loading={formState.isSubmitting}
                  fullwidth
                />
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Card>
  );
}
