import { Button, CheckInput, addToast } from '@octano/global-ui';
import { Suspense, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

import { useFormContext } from 'react-hook-form';
import { ModalConfirmation } from '../../../components/modals/Confirmation';
import { SectionTitle } from '../../../components/text';
import { isNumber } from '../../../utils/math';
import { transformData, transformDataActivity } from './dataTransformation';
import { CreateActivityModal } from './parts/CreateActivity/CreateActivityModal';
import TableCurriculumActivity from './parts/TableCurriculumActivity';
import {
  getDegreeActivityByCurriculum,
  postCreateDegreeActivity,
} from './requests';
import { DeleteActivityParams, TableCurriculumDegreeActivity } from './types';

export const prefix = 'studyPlans.curriculum.degreeActivities';
const ITEMS_PER_PAGE = 10;

interface CurriculumDegreeActivitiesListProps {
  readOnly: boolean;
  curriculumId?: string;
}

export const CurriculumDegreeActivitiesList = ({
  readOnly,
  curriculumId,
}: CurriculumDegreeActivitiesListProps) => {
  const { t } = useTranslation();
  const [curriculumDegreeActivityCode, setCurriculumDegreeActivityCode] =
    useState<number | string>('');

  const [showModal, setShowModal] = useState<boolean>(false);
  const [showModalDelete, setShowModalDelete] = useState<boolean>(false);
  const [isLoadingTable, setIsTableLoading] = useState<boolean>(false);

  const { setValue, watch } = useFormContext();
  const [curriculumActivities, hasActivitiesChecked]: [
    curriculumActivities: TableCurriculumDegreeActivity[],
    hasActivitiesChecked: boolean,
  ] = watch(['curriculumActivities', 'hasActivitiesChecked']);

  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalCurriculumDegreeActivities, setTotalCurriculumDegreeActivities] =
    useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);

  const handleGetData = useCallback(
    async ({
      curriculumId,
      page = 0,
    }: {
      curriculumId: string;
      page: number;
    }) => {
      setIsTableLoading(true);
      const { data, error } = await getDegreeActivityByCurriculum(
        curriculumId,
        {
          items: ITEMS_PER_PAGE,
          page: page,
        },
      );

      if (error) {
        addToast({
          text: t(`${prefix}.errorOnSearch`),
          color: 'danger',
          icon: 'error',
        });
      }

      if (data) {
        const newDataTable = data?.data ? transformData(data?.data) : [];
        setValue('hasActivitiesChecked', !newDataTable.length);
        setValue('curriculumActivities', newDataTable);
        setTotalPages(data?.total_pages || 0);
        setTotalCurriculumDegreeActivities(data?.total || 0);
      }
      setIsTableLoading(false);
    },
    [t, setValue],
  );

  const handleSaveActivity = useCallback(
    async ({ searchParams }: any) => {
      const existCurriculumDegreeActivity = curriculumActivities.some(
        (data) => data.code === searchParams.code,
      );
      if (existCurriculumDegreeActivity) {
        addToast({
          text: t(`${prefix}.errorOnCreateRepeatActivity`),
          color: 'danger',
          icon: 'error',
        });
        return;
      }

      if (!searchParams.id) {
        const { data, error } = await postCreateDegreeActivity(searchParams);

        if (error) {
          addToast({
            text: t(`${prefix}.errorOnCreate`),
            color: 'danger',
            icon: 'error',
          });
          return;
        }

        if (data) {
          addToast({
            icon: 'success',
            color: 'success',
            text: t(`${prefix}.successfullyOnCreate`),
          });
          setShowModal(false);
          setValue('curriculumActivities', [
            ...curriculumActivities,
            transformDataActivity(data),
          ]);
          return;
        }
      }

      if (searchParams.id && searchParams) {
        addToast({
          icon: 'success',
          color: 'success',
          text: t(`${prefix}.successfullyOnCreate`),
        });
        setShowModal(false);
        setValue('curriculumActivities', [
          ...curriculumActivities,
          transformDataActivity(searchParams),
        ]);
        return;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, curriculumActivities],
  );

  const handleRemoveActivity = useCallback(
    async ({ curriculumDegreeActivityCode }: DeleteActivityParams) => {
      if (curriculumDegreeActivityCode) {
        addToast({
          icon: 'success',
          color: 'success',
          text: t(`${prefix}.successfullyOnDelete`),
        });
        setShowModal(false);
        const newData = curriculumActivities.filter(
          (data) => data.code !== curriculumDegreeActivityCode,
        );
        setValue('curriculumActivities', newData);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, curriculumActivities],
  );

  useEffect(() => {
    if (!isNumber(Number(curriculumId))) {
      setValue('hasActivitiesChecked', false);
      setValue('curriculumActivities', []);
      return;
    }
    handleGetData({ curriculumId: curriculumId as string, page: currentPage });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curriculumId, currentPage]);

  return (
    <Suspense fallback={'loading...'}>
      <div className="legend-table pb-5">
        <Row>
          <Col xs={12}>
            <SectionTitle text={t(`${prefix}.title`)} />
          </Col>
        </Row>
        <Row className="pb-3">
          <Col xs={12}>
            <span className="fs-18">{t(`${prefix}.subtitle`)}</span>
          </Col>
        </Row>

        {!readOnly && (
          <Row className="pb-0">
            <Col xs={{ size: 12 }} className="d-flex justify-content-end">
              <Col xs={12} md={4} className="mb-4">
                <Button
                  className="ml-auto btn-block"
                  size="md"
                  text={t(`${prefix}.btn.insertActivity`)}
                  icon="plus"
                  onClick={() => setShowModal(!showModal)}
                  disabled={hasActivitiesChecked}
                />
              </Col>
              <CreateActivityModal
                show={showModal}
                toggleModal={() => {
                  setShowModal(!showModal);
                }}
                onSave={(searchParams: any) =>
                  handleSaveActivity({ searchParams })
                }
              />
            </Col>
          </Row>
        )}

        {!readOnly && curriculumActivities.length === 0 && (
          <Row>
            <Col xs={{ size: 12 }} className="d-flex justify-content-end">
              <Col
                xs="auto"
                className="align-self-center d-flex justify-content-end mb-4"
              >
                <div className="">
                  <CheckInput
                    name="hasActivitiesChecked"
                    value={hasActivitiesChecked}
                    label={t(`${prefix}.btn.checkedHasActivities`)}
                    onChange={() => {
                      setValue('hasActivitiesChecked', !hasActivitiesChecked);
                    }}
                  />
                </div>
              </Col>
            </Col>
          </Row>
        )}

        {!readOnly && (
          <ModalConfirmation
            open={showModalDelete}
            title={t(`${prefix}.modalTrash.title`)}
            subtitle={t(`${prefix}.modalTrash.subtitle`)}
            actionText={t(`${prefix}.modalTrash.btn.unLink`)}
            onClose={() => {
              setShowModalDelete(!showModalDelete);
            }}
            onConfirm={() => {
              handleRemoveActivity({ curriculumDegreeActivityCode });
            }}
          />
        )}

        <Row>
          <Col xs={12} md={12}>
            <TableCurriculumActivity
              readOnly={readOnly}
              isLoadingResults={isLoadingTable}
              data={curriculumActivities}
              totalPages={totalPages}
              totalItems={totalCurriculumDegreeActivities}
              currentPage={currentPage}
              onChangePage={(page) => {
                setCurrentPage(page);
              }}
              onDeleteCurriculumActivity={(code: string | number) => {
                setCurriculumDegreeActivityCode(code);
                setShowModalDelete(true);
              }}
            />
          </Col>
        </Row>
      </div>
    </Suspense>
  );
};
