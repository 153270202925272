import { SelectOptionType } from '@octano/global-ui';
import { useCallback, useEffect, useState } from 'react';
import { DefaultValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Card, Col, Row } from 'reactstrap';
import { getCosigner } from '../../../../api/requests/tuitionProcess';
import DisplayError from '../../../../components/info/DisplayError';
import Loading from '../../../../components/info/Loading';
import { useLoadingState } from '../../../../hooks/useLoadingState';
import { useStepState } from '../../../../components/step/useStepState';
import CosignerForm, { FieldsCosignerForm } from './CosignerForm';
import { getSelectedOption } from '../../../../utils/selectFormat';
import { format } from 'rut.js';
import { CosignerStepResponse } from '../../../../types/cosignerTypes';
import { useTuitionProcess } from '../../TuitionProcessContext';
import { useParameters } from '../../../../hooks/useParameters';
import GoBackButton from '../../../../components/buttons/GoBackButton';

const formatDefaultValues = (
  data: CosignerStepResponse,
  communeOptions: SelectOptionType[],
  regionOptions: SelectOptionType[],
): DefaultValues<FieldsCosignerForm> => {
  const { cosigner } = data;
  const selectedCommune = getSelectedOption(
    cosigner?.communeId,
    communeOptions,
  );

  return {
    names: cosigner?.names,
    paternalLastName: cosigner?.paternalLastName,
    maternalLastName: cosigner?.maternalLastName,
    rut: cosigner?.rut ? format(cosigner.rut) : '',
    addressStreet: cosigner?.addressStreet,
    addressNumber: cosigner?.addressNumber,
    addressExtra: cosigner?.addressExtra ?? '',
    commune: selectedCommune,
    region: getSelectedOption(selectedCommune?.regionId, regionOptions),
    phone: cosigner?.phone ?? '',
    cellphone: cosigner?.cellPhone,
    email: cosigner?.email,
    checkSustainer: cosigner?.rut === data.sustainer.rut ? true : undefined,
  };
};

const formatDataSustainerAsCosigner = (
  data: CosignerStepResponse,
  communeOptions: SelectOptionType[],
  regionOptions: SelectOptionType[],
): FieldsCosignerForm => {
  const { sustainer } = data;
  const selectedCommune = getSelectedOption(
    sustainer?.communeId,
    communeOptions,
  );
  return {
    names: sustainer.names,
    paternalLastName: sustainer.paternalLastName,
    maternalLastName: sustainer.maternalLastName,
    rut: format(sustainer.rut),
    addressStreet: sustainer.addressStreet,
    addressNumber: sustainer.addressNumber,
    addressExtra: sustainer.addressExtra ?? '',
    commune: selectedCommune ?? null,
    region: getSelectedOption(selectedCommune?.regionId, regionOptions) ?? null,
    phone: sustainer.phone ?? '',
    cellphone: sustainer.cellPhone,
    email: sustainer.email,
    checkSustainer: true,
  };
};

/**
 * Paso "Datos Aval" del proceso de matrícula
 */
const Cosigner = () => {
  const {
    prefix: translationPrefix,
    postulationDetailId,
    onError,
  } = useTuitionProcess();
  const prefix = `${translationPrefix}.cosigner`;
  const { t } = useTranslation();
  const { prevStep } = useStepState();
  const { loading, setLoading, errorLoading, setErrorLoading } =
    useLoadingState();
  const [defaultValues, setDefaultValues] =
    useState<DefaultValues<FieldsCosignerForm>>();
  const [studentFullName, setStudentFullName] = useState('');
  const [studentRut, setStudentRut] = useState('');
  const [dataSustainer, setDataSustainer] = useState<FieldsCosignerForm>();
  const { communeOptions, regionOptions } = useParameters();

  const getCosignerStepInfo = useCallback(async () => {
    const { data, error } = await getCosigner(postulationDetailId);
    if (data) {
      setStudentFullName(data.studentFullName);
      setStudentRut(data.studentRut);
      setDefaultValues(
        formatDefaultValues(data, communeOptions, regionOptions),
      );
      setDataSustainer(
        formatDataSustainerAsCosigner(data, communeOptions, regionOptions),
      );
      setErrorLoading(undefined);
    } else if (error) {
      setErrorLoading(error.code);
      onError(error.code);
    }
    setLoading(false);
  }, [
    setErrorLoading,
    setLoading,
    onError,
    postulationDetailId,
    communeOptions,
    regionOptions,
  ]);

  useEffect(() => {
    if (loading) {
      getCosignerStepInfo();
    }
  }, [loading, getCosignerStepInfo]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (errorLoading) {
    return (
      <DisplayError
        insideCard
        textBody={errorLoading}
        retryAction={() => setLoading(true)}
        loadingAction={loading}
      />
    );
  }
  if (loading) {
    return <Loading insideCard />;
  }
  return (
    <Card className="px-4 px-md-5 py-4">
      <Row className="pb-4 pb-md-0">
        <Col xs={12}>
          <GoBackButton
            onClick={() => prevStep()}
            text={t(`common.btn.goBackStep`)}
          />
        </Col>
        <Col className="py-3" xs={12} lg={7} md={8} xl={9}>
          <span className="fs-18 text-primary">{t(`${prefix}.student`)}</span>
          <br />
          <span className="fs-18 text-primary fw-600">{studentFullName}</span>
        </Col>
      </Row>
      <CosignerForm
        studentRut={studentRut}
        defaultValues={defaultValues}
        dataSustainer={dataSustainer}
      />
    </Card>
  );
};

export default Cosigner;
