import { addToast } from '@octano/global-ui';
export const showToast = (error: boolean, t: Function) => {
  addToast(
    error
      ? {
          icon: 'error',
          color: 'danger',
          text: t('common.errors.save'),
        }
      : {
          icon: 'success',
          color: 'success',
          text: t('common.messages.editSuccess'),
        },
  );
};
