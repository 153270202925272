import { TextInput } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { SectionTitle } from '../../components/text';

interface CurriculumLegendProps {
  legend: any;
}

export const CurriculumLegend = ({ legend }: CurriculumLegendProps) => {
  const { t } = useTranslation();
  const prefix = 'studyPlans.curriculum.legend';

  return (
    <div className="legend-table pb-5">
      <Row>
        <Col xs={12}>
          <SectionTitle text={t(`${prefix}.credits`)} />
        </Col>
      </Row>
      <Row className="pb-5">
        <Col xs={12}>
          <span className="fs-18">{t(`${prefix}.creditsDesc`)}</span>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={3}>
          <TextInput
            name="legendRequired"
            label={t(`${prefix}.required`)}
            value={legend.required || '0'}
            disabled
          />
        </Col>
        <Col xs={12} md={3}>
          <TextInput
            name="legendOptional"
            label={t(`${prefix}.optional`)}
            value={legend.optional || '0'}
            disabled
          />
        </Col>
        <Col xs={12} md={3}>
          <TextInput
            name="legendOthers"
            label={t(`${prefix}.others`)}
            value={legend.other || '0'}
            disabled
          />
        </Col>
        <Col xs={12} md={3}>
          <TextInput
            name="legendActivities"
            label={t(`${prefix}.activity`)}
            value={legend.activities || '0'}
            disabled
          />
        </Col>
      </Row>
    </div>
  );
};
