import { CellFormatOptions } from '@octano/global-ui/dist/components/Table/types/TableTypes';
import { AdmissionsStudent } from './types';

export function getAdmissionColumnsStudent(t: (t: string) => string) {
  return [
    {
      columnName: 'rut',
      headerText: t(`common.forms.rut`),
      width: '10%',
      cellFormat: (options: CellFormatOptions<AdmissionsStudent>) => {
        return <span>{options.row.rut}</span>;
      },
    },
    {
      columnName: 'names',
      headerText: t(`common.forms.names`),
      width: '10%',
      cellFormat: (options: CellFormatOptions<AdmissionsStudent>) => {
        return <span>{options.row.names}</span>;
      },
    },
    {
      columnName: 'lastnames',
      headerText: t(`common.forms.lastName`),
      width: '10%',
      cellFormat: (options: CellFormatOptions<AdmissionsStudent>) => {
        return <span>{options.row.lastnames}</span>;
      },
    },
    {
      columnName: 'phone',
      headerText: t(`common.forms.phone`),
      width: '10%',
      cellFormat: (options: CellFormatOptions<AdmissionsStudent>) => {
        return <span>{options.row.phone}</span>;
      },
    },
    {
      columnName: 'email',
      headerText: t(`common.forms.email`),
      width: '10%',
      cellFormat: (options: CellFormatOptions<AdmissionsStudent>) => {
        return <span>{options.row.email}</span>;
      },
    },
  ];
}
