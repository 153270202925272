import { TARIFF_TUITION } from '../endpoints';
import request from '../request';
import { PaginationRequestType } from '../../types/paginationRequestType';

export type DiscountItem = {
  id: number;
  code: string;
  name: string;
  description: string | null;
  percentage: number;
};

export type DiscountItemBody = {
  id?: number;
  code: string;
  name: string;
  description: string | null;
  percentage: number;
};

export type DiscountsListResponse = PaginationRequestType<DiscountItem>;

/**
 *Obtiene el listado de todos los descuentos
 */
export const getAllDiscounts = () => {
  const url = `${TARIFF_TUITION.ALL_DISCOUNTS}`;
  return request<DiscountItem[]>(url);
};

/**
 * Obtiene el listado de descuentos con paginación
 */
export const getDiscountsRequest = (items: number = 10, page: number = 0) => {
  const url = `${TARIFF_TUITION.DISCOUNTS}`;
  return request<DiscountsListResponse>(url, {
    params: { items_per_page: items, page },
  });
};

/**
 * Crear/editar un descuento
 */
export const saveDiscountRequest = (data: DiscountItemBody) => {
  const editing = !!data.id;
  const url = `${TARIFF_TUITION.DISCOUNTS}/${editing ? data.id : ''}`;
  return request<DiscountItem>(url, {
    method: editing ? 'put' : 'post',
    data,
  });
};

/**
 * Eliminar un descuento
 */

export const deleteDiscountRequest = (id: number) => {
  const url = `${TARIFF_TUITION.DISCOUNTS}/${id}`;
  return request<DiscountItem>(url, {
    method: 'delete',
  });
};
