import { addToast } from '@octano/global-ui';
import { DefaultValues, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Form } from 'reactstrap';
import { saveStudentFile } from '../../../../api/requests/tuitionProcess';
import { useStepState } from '../../../../components/step/useStepState';
import { useTuitionProcess } from '../../../../hooks/useTuitionProcess';

import { FieldsStudentNoSuaFile } from '../../../../types/studentFileNoSuaTypes';
import {
  BackgroundSectionType,
  EducationalBackgroundType,
} from '../../../../types/studentFileSectionTypes';
import { formatStudenFileNoSuaData } from './utils';
import StudentFileNoSuaFormComponents from './StudentFileNoSuaFormComponents';

type StudentFileNoSuaFormProps = {
  backgroundData?: BackgroundSectionType;
  educationalBackground?: EducationalBackgroundType;
  defaultValues?: DefaultValues<FieldsStudentNoSuaFile>;
};

const StudentFileNoSuaForm = ({
  backgroundData,
  educationalBackground,
  defaultValues,
}: StudentFileNoSuaFormProps) => {
  const { nextStep } = useStepState();
  const prefix = 'tuitionProcess.onSite.studentFile';
  const { t } = useTranslation();
  const history = useHistory();
  const methods = useForm<FieldsStudentNoSuaFile>({
    mode: 'onSubmit',
    defaultValues: defaultValues,
  });
  const { postulationDetail } = useTuitionProcess();

  const onSubmit = async (values: FieldsStudentNoSuaFile) => {
    if (postulationDetail?.id) {
      const formatData = formatStudenFileNoSuaData(
        values,
        postulationDetail.admissionMethod,
      );

      const { data, error } = await saveStudentFile(
        postulationDetail?.id,
        formatData,
      );

      if (data === '') {
        addToast({
          icon: 'success',
          color: 'success',
          text: t(`${prefix}.saveSuccess`),
        });
        nextStep();
      } else if (error) {
        addToast({
          icon: 'error',
          color: 'danger',
          text: t(`${prefix}.saveError`),
        });
      }
    }
  };

  return (
    <FormProvider {...methods}>
      <Form onSubmit={methods.handleSubmit(onSubmit)}>
        <StudentFileNoSuaFormComponents
          backgroundData={backgroundData}
          educationalBackground={educationalBackground}
          prefix={prefix}
          isSubmitting={methods.formState.isSubmitting}
          onPressCancel={() => history.push('/tuition-process')}
        />
      </Form>
    </FormProvider>
  );
};

export default StudentFileNoSuaForm;
