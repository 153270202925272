import { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useLayoutState } from '../hooks/useLayoutState';
import TuitionProcessInfoProvider from '../providers/TuitionProcessInfoProvider';
import TuitionProcess from '../views/TuitionProcess/TuitionProcess';
import TuitionProcessProvider, {
  TuitionProcessContextState,
} from '../views/TuitionProcess/TuitionProcessContext';
import TuitionProcessNoSua from '../views/TuitionProcessNoSua/TuitionProcessNoSua';

function TuitionProcessOnSite() {
  const history = useHistory();
  const { showErrorModal } = useLayoutState();
  const { postulationDetailId, admissionType } =
    useParams<{ postulationDetailId: string; admissionType: string }>();

  const onError = useCallback<TuitionProcessContextState['onError']>(
    (code, onUnknown) => {
      if (code === 'CONNECTION') {
        showErrorModal();
      } else {
        onUnknown && onUnknown();
      }
    },
    [showErrorModal],
  );
  const onCancelFirstStep = useCallback(() => {
    history.replace('/tuition-process');
  }, [history]);

  return (
    <TuitionProcessProvider
      prefix="tuitionProcess.onSite"
      postulationDetailId={postulationDetailId}
      onError={onError}
      onCancelFirstStep={onCancelFirstStep}
    >
      <TuitionProcessInfoProvider>
        <div className="mx-3">
          {admissionType === 'SIN_SUA' ? (
            <TuitionProcessNoSua />
          ) : (
            <TuitionProcess />
          )}
        </div>
      </TuitionProcessInfoProvider>
    </TuitionProcessProvider>
  );
}

export default TuitionProcessOnSite;
