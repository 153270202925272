import {
  LayoutState,
  LayoutAction,
  LayoutActionType,
} from '../types/LayoutTypes';

export const initialState: LayoutState = {
  showMaintainerTabs: false,
  showConnectionErrorModal: false,
};

const layoutReducer = (
  state: LayoutState,
  action: LayoutAction,
): LayoutState => {
  switch (action.type) {
    case LayoutActionType.SHOW_MAINTAINER_TABS:
      return {
        ...state,
        showMaintainerTabs: action.payload,
      };
    case LayoutActionType.SHOW_CONNECTION_ERROR_MODAL:
      return {
        ...state,
        showConnectionErrorModal: action.payload,
      };
    default:
      return state;
  }
};

export default layoutReducer;
