import request from '../request';
import { MAINTAINERS } from '../endpoints';
import {
  CurriculumListResponse,
  CurriculumsResponse,
} from '../../types/CurriculumTypes';

export const getCurriculumMentionsByStudyPlanRequest = (
  studyPlanId: number,
  items: number = 10,
  page: number = 0,
) => {
  const url = `${MAINTAINERS.CURRICULUM}/${studyPlanId}/mentions?items_per_page=${items}&page=${page}`;
  return request<CurriculumListResponse>(url);
};

export const getCurriculumsByVersionRequest = (
  versionId: number,
  items: number = 10,
  page: number = 0,
) => {
  const url = `${MAINTAINERS.CURRICULUM}/studyPlanVersion/${versionId}?items_per_page=${items}&page=${page}`;
  return request<{ data: CurriculumsResponse }>(url);
};

export const createCurriculumRequest = (data: any) => {
  const url = `${MAINTAINERS.CURRICULUM}`;
  return request<any>(url, {
    method: 'post',
    data,
  });
};

export const getCurriculumRequest = (curriculumId: string) => {
  const url = `${MAINTAINERS.CURRICULUM}/${curriculumId}`;
  return request<any>(url);
};

export const editCurriculumRequest = (data: any, curriculumId: string = '') => {
  const url = `${MAINTAINERS.CURRICULUM}/${curriculumId}`;
  return request<any>(url, {
    method: 'put',
    data,
  });
};

export const editCurriculumMentionStatus = (
  curriculumId: number,
  status: string,
) => {
  const url = `${MAINTAINERS.CURRICULUM}/change-mention-status`;
  return request<any>(url, {
    method: 'put',
    data: {
      curriculumId,
      status,
    },
  });
};
