import { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import { SearchBox, Button } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';

interface SearchProps {
  searching: boolean;
  value?: string;
  onChange: (value: string) => void;
  label?: string;
  placeholder?: string;
}

function SearchRut({
  searching,
  value,
  onChange,
  label: labelProp,
  placeholder: placeholderProp,
}: SearchProps) {
  const { t } = useTranslation();

  const label = labelProp ?? t('common.searchId.label');
  const placeholder = placeholderProp ?? t('common.searchId.placeholder');

  const [search, setSearch] = useState<string>('');
  const [disabled, setDisabled] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const onChangeSearchText = (e: React.ChangeEvent<HTMLInputElement>) => {
    let text = e.target.value;
    setSearch(text);
  };

  useEffect(() => {
    setSearch(value ?? '');
  }, [value]);

  useEffect(() => {
    let tmp = search.replace(' ', '').trim();
    if (tmp.length > 2) {
      if (!tmp.includes('.') && !tmp.includes('-')) {
        setDisabled(false);
        setErrorMessage('');
      } else {
        if (tmp !== '') {
          setDisabled(true);
          setErrorMessage(`${t('common.validations.noDots')}`);
        }
      }
    } else {
      if (search.length === 0) {
        setErrorMessage('');
      } else if (search.length < 3) {
        setErrorMessage(`${t('common.validations.minLength', { length: 3 })}`);
      } else {
        setErrorMessage('');
      }
      setDisabled(true);
    }
  }, [search, t]);

  return (
    <Row>
      <Col md={6}>
        <Row>
          <Col md={12}>
            <label className="text-dark">{label}</label>
          </Col>
          <Col className="form-group">
            <SearchBox
              name="search"
              onChange={onChangeSearchText}
              value={search}
              placeholder={placeholder}
              errorText={errorMessage}
              clear={() => onChange('')}
            />
          </Col>
          <Col md="auto" sm="auto" xs="auto" className="pl-0">
            <Button
              text={t(`common.actions.search`)}
              type="submit"
              size="md"
              loading={searching}
              disabled={disabled}
              onClick={() => onChange(search)}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default SearchRut;
