import { Button } from '@octano/global-ui';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmationModal from '../../../components/modals/ConfirmationModal';
import { upload } from '../requests';
import UploadModal from './UploadModal';

enum ModalType {
  NONE,
  LOADING,
  UPLOAD_SECTIONS_FILE,
  UPLOAD_SUCCESS,
  UNEXPECTED_ERROR,
  FILE_DATA_ERROR,
  INVALID_FILE_STRUCTURE_ERROR,
  CUSTOM_ERROR,
}

interface UploadProps {
  beforeUpload?: () => void;
  afterUpload?: () => void;
  disabled?: boolean;
  loadAgain?: boolean;
}

const Upload = ({
  beforeUpload,
  afterUpload,
  disabled,
  loadAgain,
}: UploadProps): JSX.Element => {
  const { t } = useTranslation();
  const prefix = 'academicRecord.upload';
  const texts = useMemo(
    () => ({
      successModal: {
        title: t(`${prefix}.successModal.title`),
        description: t(`${prefix}.successModal.body`),
        confirm: t(`common.actions.understood`),
      },
      errorModal: {
        title: t(`${prefix}.errorModal.title`),
        description: t(`${prefix}.errorModal.body`),
        confirm: t(`${prefix}.errorModal.action`),
      },
      loadAgain: t(`${prefix}.loadAgain`),
      action: t(`${prefix}.action`),
    }),
    [t],
  );

  const [openedModal, setOpenedModal] = useState<ModalType>();

  const handleFileUpload = useCallback(
    async (file: File) => {
      if (beforeUpload) {
        beforeUpload();
      }

      const response = await upload(file);
      if (!response.error) {
        setOpenedModal(ModalType.UPLOAD_SUCCESS);
      } else {
        setOpenedModal(ModalType.UNEXPECTED_ERROR);
      }

      if (afterUpload) {
        afterUpload();
      }
    },
    [beforeUpload, afterUpload],
  );

  return (
    <>
      {/* MODAL DE SUBIDA DE ARCHIVO */}
      <UploadModal
        isOpen={openedModal === ModalType.UPLOAD_SECTIONS_FILE}
        onCancel={() => setOpenedModal(ModalType.NONE)}
        onConfirm={handleFileUpload}
      />

      {/* MODAL DE SUCCESS */}
      <ConfirmationModal
        iconName="success"
        isOpen={openedModal === ModalType.UPLOAD_SUCCESS}
        toggle={() => setOpenedModal(ModalType.NONE)}
        title={texts.successModal.title}
        body={texts.successModal.description}
        primaryBtn={{
          text: texts.successModal.confirm,
          action: () => {
            setOpenedModal(ModalType.NONE);
          },
        }}
      />

      {/* MODAL DE ERROR INESPERADO */}
      <ConfirmationModal
        iconName="error"
        iconColor="danger"
        isOpen={openedModal === ModalType.UNEXPECTED_ERROR}
        toggle={() => setOpenedModal(ModalType.NONE)}
        title={texts.errorModal.title}
        body={texts.errorModal.description}
        primaryBtn={{
          text: texts.errorModal.confirm,
          action: () => setOpenedModal(ModalType.UPLOAD_SECTIONS_FILE),
        }}
      />

      <Button
        size="md"
        icon="upload"
        color="primary"
        text={loadAgain ? texts.loadAgain : texts.action}
        onClick={() => setOpenedModal(ModalType.UPLOAD_SECTIONS_FILE)}
        disabled={disabled}
      />
    </>
  );
};

export default Upload;
