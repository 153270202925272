import { AxiosError, AxiosRequestConfig, Method } from 'axios';
import { URLSearchParams } from 'url';
import { UserSessionEvent } from '../types/Auth';
import { getJWT } from '../utils/auth';
import { logger } from '../utils/logger';
import API from './api';

export type RequestOptionsType = {
  method?: Method;
  data?: any;
  authRequired?: boolean;
  retry?: boolean;
  params?: Record<string, any> | URLSearchParams;
  responseType?: AxiosRequestConfig['responseType'];
};

const getHeaders = (authRequired: boolean) => {
  const headers: any = {};
  if (authRequired) {
    const token = getJWT();
    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }
  }
  return headers;
};

type RequireOne<T, K extends keyof T> = Omit<T, K> & Required<Pick<T, K>>;

export type AxiosErrorWithResponse<T = any> = RequireOne<
  AxiosError<T>,
  'response'
>;

export type AxiosResultErrorCustomHttp<C extends string = string, T = any> = {
  code: C;
  error: AxiosErrorWithResponse;
  status: number;
  data?: T;
};

type AxiosResultErrorHttp<T = any> = {
  code: 'HTTP_ERROR';
  error: AxiosErrorWithResponse;
  status: number;
  data?: T;
};

type AxiosResultErrorConnection = {
  code: 'CONNECTION';
  error: AxiosError;
  status?: number;
  data?: null;
};

export type AxiosResultDefaultError =
  | AxiosResultErrorHttp
  | AxiosResultErrorConnection;

export type AxiosResult<
  T,
  E extends { code: string; data?: any } = AxiosResultDefaultError,
> =
  | { data: T; error?: false; status?: number }
  | { data?: null; error: E; status?: null };

const request = async <T = any>(
  url: string,
  options: RequestOptionsType = {},
): Promise<AxiosResult<T>> => {
  const {
    method = 'get',
    data = {},
    authRequired = true,
    params,
    responseType,
  } = options;
  try {
    const response = await API.request<T>({
      url: url,
      params,
      method: method,
      data: data,
      headers: getHeaders(authRequired),
      responseType,
    });
    return { data: response.data, error: false, status: response.status };
  } catch (error: any) {
    logger(error);

    if (error.response) {
      // Token invalido
      if (error.response.status === 401 && authRequired) {
        const sessionExpiredEvent = new CustomEvent(UserSessionEvent.EXPIRED);
        window.dispatchEvent(sessionExpiredEvent);
      }

      return {
        error: {
          code: 'HTTP_ERROR',
          status: error.response.status,
          data: error.response.data,
          error,
        },
        status: error.response.status,
      };
    }
    return {
      error: {
        code: 'CONNECTION',
        error,
      },
    };
  }
};

export async function mockRequest<T>(data: T): Promise<AxiosResult<T>> {
  return {
    data,
    error: false,
  };
}

export function mockRequestError<E extends { code: string; data?: any }>(
  error: E,
): AxiosResult<any, E> {
  return {
    data: null,
    error: false,
  };
}

export default request;
