import { useCallback, useEffect, useState } from 'react';
import { DefaultValues } from 'react-hook-form';
import { Card, Col, Row } from 'reactstrap';

import { getStudentFile } from '../../../../api/requests/tuitionProcess';
import DisplayError from '../../../../components/info/DisplayError';
import Loading from '../../../../components/info/Loading';
import { useLoadingState } from '../../../../hooks/useLoadingState';
import { useParameters } from '../../../../hooks/useParameters';
import { useTuitionProcess } from '../../../../hooks/useTuitionProcess';
import { FieldsStudentNoSuaFile } from '../../../../types/studentFileNoSuaTypes';
import {
  BackgroundSectionType,
  EducationalBackgroundType,
} from '../../../../types/studentFileSectionTypes';
import { formatDefaultValuesStudentFileNoSua } from '../../../../utils/tuitionProcess';
import HeaderStep from '../HeaderStep';
import StudentFileNoSuaForm from './StudentFileNoSuaForm';

/**
 * Paso "Ficha Alumno" del Formulario de proceso de matrícula
 */
const StudentFileNoSua = () => {
  const prefix = 'tuitionProcess.onSite.studentFile';
  const { loading, setLoading, errorLoading, setErrorLoading } =
    useLoadingState();
  useState<{ id: string; name: string }>();
  const [backgroundData, setBackgroundData] = useState<BackgroundSectionType>();
  const [educationalBackground, setEducationalBackground] =
    useState<EducationalBackgroundType>();
  const [defaultValues, setDefaultValues] =
    useState<DefaultValues<FieldsStudentNoSuaFile>>();
  const {
    countryOptions,
    communeOptions,
    regionOptions,
    institutionTypeOptions,
  } = useParameters();
  const { postulationDetail } = useTuitionProcess();

  const getStudentFileInfo = useCallback(async () => {
    setLoading(true);
    if (postulationDetail?.id) {
      const { data, error } = await getStudentFile(postulationDetail.id);
      if (data) {
        setDefaultValues(
          formatDefaultValuesStudentFileNoSua(
            data,
            communeOptions,
            regionOptions,
            countryOptions,
            institutionTypeOptions,
          ),
        );
        setBackgroundData({
          student: data.student,
          studyPlan: data.studyPlan,
          postulation: data.postulation,
        });
        setEducationalBackground(data.educationalBackground);
        setErrorLoading(undefined);
      } else if (error) {
        setErrorLoading(error.code);
      }
    }
    setLoading(false);
  }, [
    setErrorLoading,
    setLoading,
    countryOptions,
    communeOptions,
    regionOptions,
    institutionTypeOptions,
    postulationDetail,
  ]);

  useEffect(() => {
    if (
      loading &&
      countryOptions.length > 0 &&
      regionOptions.length > 0 &&
      communeOptions.length > 0 &&
      institutionTypeOptions.length > 0
    ) {
      getStudentFileInfo();
    }
  }, [
    loading,
    getStudentFileInfo,
    countryOptions,
    regionOptions,
    communeOptions,
    institutionTypeOptions,
  ]);

  if (errorLoading) {
    return (
      <DisplayError
        insideCard
        textBody={errorLoading}
        retryAction={() => setLoading(true)}
        loadingAction={loading}
      />
    );
  }
  if (loading) {
    return <Loading insideCard />;
  }
  return (
    <Card className="px-4 px-md-5 py-4">
      <Row className="pb-4 pb-md-0">
        <Col xs={12}>
          <HeaderStep prefixStep={prefix} showBackBtn={true} />
        </Col>
      </Row>
      <StudentFileNoSuaForm
        backgroundData={backgroundData}
        educationalBackground={educationalBackground}
        defaultValues={defaultValues}
      />
    </Card>
  );
};

export default StudentFileNoSua;
