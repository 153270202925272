import { PaginationType, Table } from '@octano/global-ui';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Col, Row } from 'reactstrap';
import { CourseRegistrationList } from '../../types/courseRegistrationTypes';
import { useColumnsCourseRegistrationList } from './parts/useColumnsCourseRegistrationList';
import { requestCourseRegistrationList } from '../../api/requests/courseRegistration';
import TableEmptyContent from '../../components/text/TableEmptyContent';
import { useLoadingState } from '../../hooks/useLoadingState';
import DisplayError from '../../components/info/DisplayError';
import SearchFilters, { Filters } from './parts/SearchFilters';

const ITEMS_PER_PAGE = 10;

const CourseRegistration = () => {
  const prefix = 'courseRegistrationList';
  const { t } = useTranslation();
  const [dataList, setDataList] = useState<CourseRegistrationList[]>([]);
  const [pagination, setPagination] = useState<PaginationType>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [lastFilters, setLastFilters] = useState<Filters>();
  const [isFiltering, setIsFiltering] = useState<boolean>(false);

  const columns = useColumnsCourseRegistrationList();
  const { loading, setLoading, errorLoading, setErrorLoading } =
    useLoadingState();

  const getData = useCallback(
    async (page: number, filters?: Filters) => {
      setLoading(true);

      const requestFilters = filters || lastFilters;

      const { data, error } = await requestCourseRegistrationList({
        items_per_page: ITEMS_PER_PAGE,
        page: page - 1,
        ...requestFilters,
      });

      if (data) {
        setDataList(data.data);
        setPagination({
          totalItems: data.total,
          itemsPerPage: ITEMS_PER_PAGE,
          totalPages: data.total_pages,
          currentPage: page,
          onChangePage: (pageNumber: number) => setCurrentPage(pageNumber),
        });
      }
      if (error) {
        setErrorLoading(error.code);
      }
      setLoading(false);
    },
    [setLoading, setErrorLoading, lastFilters],
  );

  useEffect(() => {
    getData(currentPage);
  }, [currentPage, getData]);

  useEffect(() => {
    if (!lastFilters) {
      setIsFiltering(false);
    } else if (
      lastFilters.rutOrPassport ||
      lastFilters.searchText ||
      lastFilters.studyPlanId ||
      lastFilters.campusId
    ) {
      setIsFiltering(true);
    } else {
      setIsFiltering(false);
    }
  }, [lastFilters]);

  const getTableEmptyTexts = useCallback(
    () =>
      isFiltering
        ? {
            title: t(`${prefix}.noSearchTitle`),
            subtitle: t(`${prefix}.noSearchBody`),
          }
        : {
            title: t(`${prefix}.noResultsTitle`),
            subtitle: t(`${prefix}.noResultsBody`),
          },
    [isFiltering, t],
  );

  if (errorLoading) {
    return (
      <div className="mx-3">
        <DisplayError
          insideCard
          textBody={errorLoading}
          retryAction={() => {
            getData(currentPage);
            setErrorLoading(undefined);
          }}
          loadingAction={loading}
        />
      </div>
    );
  }
  return (
    <Card className="p-4 mt-2 mx-3">
      <Row className="pt-4">
        <Col xs={12}>
          <SearchFilters
            onFiltersChange={(filters) => {
              getData(1, filters);
              setCurrentPage(1);
              setLastFilters(filters);
            }}
          />
          <Table<CourseRegistrationList>
            columns={columns}
            data={dataList}
            pagination={pagination}
            isLoadingResults={loading}
            noResultsText={<TableEmptyContent {...getTableEmptyTexts()} />}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default CourseRegistration;
