import { MAINTAINERS } from '../endpoints';
import request from '../request';
import { PaginationRequestType } from '../../types/paginationRequestType';

export type SchoolsListItem = {
  id: number;
  name: string;
  code: string;
  description?: string | null;
  quota?: string | null;
  enrolled?: string | null;
  duplicatedQuota?: string | null;
};

export type SchoolItemBody = {
  id?: number;
  name: string;
  code: string;
  description?: string | null;
};

export type SchoolsListResponse = PaginationRequestType<SchoolsListItem>;

/**
 * Obtiene el listado de facultades
 */
export const getSchoolsRequest = (items: number = 10, page: number = 0) => {
  const url = `${MAINTAINERS.SCHOOLS}`;
  return request<SchoolsListResponse>(url, {
    params: { items_per_page: items, page },
  });
};

/**
 *  Crear o editar una facultad
 */
export const saveSchoolRequest = (data: SchoolItemBody) => {
  const isEdit = !!data.id;

  const url = `${MAINTAINERS.SCHOOLS}/${isEdit ? data.id : ''}`;
  return request<SchoolsListItem>(url, {
    method: isEdit ? 'put' : 'post',
    data,
  });
};
